import { getToken } from 'utils/api';
import { GRAPHQL_ENDPOINT } from 'utils/constants';

const query = `
mutation DeletePaymentMethod($id: Int!) {
  deletePaymentMethod(paymentMethodId: $id)
}`;

interface GraphQLError {
  extensions?: {
    responseBody?: {
      message?: string;
    };
  };
  message: string;
}

interface DeletePaymentMethodResponse {
  errors?: Array<GraphQLError>;
}

export const deletePaymentMethod = async (id: number) => {
  const { token } = (await getToken()) ?? {};
  const response = await fetch(GRAPHQL_ENDPOINT, {
    body: JSON.stringify({
      operationName: 'DeletePaymentMethod',
      query,
      variables: { id: id },
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  return (await response.json()) as DeletePaymentMethodResponse;
};
