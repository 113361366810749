import { Stripe, StripeElements } from '@stripe/stripe-js';
import { CreatePaymentMethodUsBankAccountData } from '@stripe/stripe-js/types/stripe-js/payment-intents';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

import { AddPaymentMethodMutation, AddPaymentMethodMutationVariables } from '@/graphql/paymentMethods';
import { GetSetupIntentQuery } from '@/graphql/setupIntent';

import { getToken } from './getToken';
import { createV2Ach } from './v2/createV2Ach';
import { getError } from './validator';

type GetSecret = (options?: {
  cancelRefetch?: boolean;
  throwOnError?: boolean;
}) => Promise<UseQueryResult<GetSetupIntentQuery>>;

export async function addPaymentMethod(
  mutation: UseMutationResult<AddPaymentMethodMutation, unknown, AddPaymentMethodMutationVariables>,
  getSecret: GetSecret,
  stripe: Stripe | null,
  elements: StripeElements | null,
  bank?: CreatePaymentMethodUsBankAccountData,
  language?: string
) {
  if (bank) {
    return await v2ACH(stripe, bank, language);
  }
  const paymentToken = stripe && (await stripeToken(getSecret, stripe, elements, bank));
  if (!paymentToken) {
    throw new Error('No payment token was returned.');
  }

  return await mutation.mutateAsync({
    from: '', // From is used for guest giving. Should not be required, but typescript was complaining.
    paymentMethod: {
      paymentToken,
    },
  });
}

async function stripeToken(
  getSecret: GetSecret,
  stripe: Stripe,
  elements: StripeElements | null,
  bank?: CreatePaymentMethodUsBankAccountData
) {
  const { setupIntent, error } = (await getSetupIntent(getSecret, stripe, elements, bank)) ?? {};
  if (setupIntent) {
    return setupIntent.id;
  }
  if (error) {
    throw error;
  }
}

export async function getSetupIntent(
  getSecret: GetSecret,
  stripe: Stripe,
  elements?: StripeElements | null,
  bank?: CreatePaymentMethodUsBankAccountData
) {
  const { data, error } = await getSecret({ throwOnError: true });
  const secret = data?.setupIntent?.clientSecret;
  if (!secret) {
    error && console.error(error);
    throw new Error('Could not retrieve secret for setup intent.');
  }
  if (bank) {
    return await stripe?.confirmUsBankAccountSetup(secret, { payment_method: bank });
  }
  const element = elements?.getElement('card');
  if (!element) {
    throw new Error('Stripe Element was not found.');
  }
  return await stripe.confirmCardSetup(secret, {
    payment_method: {
      card: element,
    },
  });
}

async function v2ACH(stripe: Stripe | null, bank: CreatePaymentMethodUsBankAccountData, language: string = 'en-US') {
  if (!stripe) {
    throw new Error('Stripe could not be found.');
  }
  const authToken = await getToken();
  if (!authToken) {
    throw new Error('Unable to retrieve auth token.');
  }
  const { error, token } = await stripe.createToken('bank_account', {
    account_holder_name: bank.billing_details.name,
    account_holder_type: bank.us_bank_account.account_holder_type,
    account_number: bank.us_bank_account.account_number,
    country: 'US',
    currency: 'USD', // Only USD supported for US banks
    routing_number: bank.us_bank_account.routing_number,
  });
  if (!token || error) {
    throw error;
  }
  const pmResponse = await createV2Ach({
    language,
    payment_token: token.id,
    token: authToken.token,
  });
  const pmResponseData = await pmResponse.json();
  if (!pmResponseData || typeof pmResponseData !== 'object' || !pmResponseData.data) {
    throw new Error(getError(pmResponseData.response).message);
  }
  const paymentMethod = pmResponseData.data.attributes;
  return {
    __typename: 'Mutation',
    paymentMethod: {
      __typename: 'PaymentMethod',
      default: false,
      displayLabel: paymentMethod.display_label,
      expirationLabel: paymentMethod.expiration_label,
      gateway: paymentMethod.gateway,
      id: pmResponseData.id,
      last4: paymentMethod.last4,
      paymentMethodType: paymentMethod.payment_method_type,
      paymentType: paymentMethod.payment_type,
    },
  } as AddPaymentMethodMutation;
}
