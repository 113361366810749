export interface ApiResponse {
  $data: {
    attributes: {
      $value: {
        transaction: {
          id: string;
        };
      };
    };
  };
  code?: string;
  data?: {
    errors?: Array<{ error: string }>;
  };
}
export interface DonationsCollectionPostResponse extends ApiResponse {}

/**
 * Validate the specified response object.
 *
 * @param response - Response object.
 * @returns Boolean - If true, response is valid.
 *
 * @example
 *
 * ```typescript
 * validateApiResponse()
 * ```
 */
interface ValidateApiResponse {
  $data?: unknown;
}
export function validateApiResponse(response?: ValidateApiResponse): boolean {
  return !(!response || typeof response !== 'object' || !response.$data);
}

type GetErrorResponse = DonationsCollectionPostResponse;
/**
 * Get the Error object for the specified response object.
 *
 * @param response - Response object.
 * @returns Object Error object with `status` and `message` attributes.
 *
 * @example
 *
 * ```typescript
 * getError(apiResponse)
 * ```
 */
export function getError(response?: GetErrorResponse) {
  if (response?.data?.errors?.length) {
    return {
      message: response.data.errors[0].error ? response.data.errors[0].error : 'An error occurred.',
      status: response.code ? response.code : '',
    };
  }
  return {
    message: 'An error occurred.',
    status: '',
  };
}
