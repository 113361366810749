import { getToken } from 'src/utils/api';
import { GRAPHQL_ENDPOINT } from 'src/utils/constants';

import { MakePaymentMutation } from '@/graphql/paymentMethods';
import { PaymentPostInput as GqlPaymentPostInput } from '@/graphql/types';

export interface PaymentPostInput extends GqlPaymentPostInput {
  transactionSource?: TransactionSource;
}

export enum TransactionSource {
  WEB_ANDROID = 'WEB_ANDROID',
  WEB_IOS = 'WEB_IOS',
  WEB_WEB = 'WEB_WEB',
}

const query = `
mutation MakePayment($payment: PaymentPostInput) {
  makePayment(paymentPostInput: $payment) {
    amount
    currencyId
    frequencyId
    fundId
    message
    nextPaymentDate
    title
  }
}`;

interface MakePaymentMutationResponse {
  data?: MakePaymentMutation;
  errors?: Array<{
    message: string;
  }>;
}

export const makePayment = async ({ payment }: { payment: PaymentPostInput }) => {
  const { token } = (await getToken()) ?? {};
  const response = await fetch(GRAPHQL_ENDPOINT, {
    body: JSON.stringify({
      operationName: 'MakePayment',
      query,
      variables: {
        payment,
      },
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  return (await response.json()) as MakePaymentMutationResponse;
};
