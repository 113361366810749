import { useInitialize } from '@youversion/auth';
import { useRouter } from 'next/router';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

/**
 * Wrapper component that redirects to /give if user is not signed in.
 *
 * @param children - Components that will be wrapped around authentication.
 *
 * @example
 *
 * ```typescriptreact
 * import { AuthenticatePages } from 'components/auth'
 *
 * <AuthenticatePages>
 *   <Component {...pageProps} />
 * </AuthenticatePages>
 * ```
 */

export function InitializeAuth({ children }: Props) {
  const router = useRouter();

  // If the current page is `/authenticate`, do not initialize.
  // The `/authenticate` page uses the `initializeFromRedirect` handler.
  // If both initializers are run it causes a race condition.
  const isAuthenticatePage = Boolean(router.pathname === '/authenticate');

  useInitialize({ disabled: isAuthenticatePage });

  return <>{children}</>;
}
