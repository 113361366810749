import * as YVAuth from '@youversion/auth';
import { oauthCredentials } from 'utils/constants';

/**
 * Wraps the getToken() function from \@youversion/auth to include params.
 *
 * @example
 * ```typescript
 * import { getToken } from 'utils/api';
 *
 * const token = await getToken()
 * ```
 */
export async function getToken(): Promise<YVAuth.AuthToken | null> {
  const { clientId, clientSecret, isStaging } = oauthCredentials;

  return await YVAuth.getToken({ clientId, clientSecret, isStaging });
}
