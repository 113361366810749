import { createContext, useCallback, useContext, useState } from 'react';

export interface Alert {
  id: string;
  message: string;
  type: 'error';
}

interface AlertContextInterface {
  alerts: Array<Alert>;
  handleDismissAlert: (index: number) => void;
  throwAlert: (alert: Alert) => void;
}

type AlertContextType = AlertContextInterface | null;

const AlertContext = createContext<AlertContextType>(null);

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [alerts, setAlerts] = useState<Array<Alert>>([]);

  const handleDismissAlert = (index: number) => {
    setAlerts(prevState => {
      const splicedAlerts = [...prevState];
      splicedAlerts.splice(index, 1);
      return splicedAlerts;
    });
  };

  const throwAlert = useCallback(({ id, message, type }: Alert) => {
    setAlerts(prevState => {
      const alertIds = prevState.map(alert => alert.id);
      if (alertIds.includes(id)) {
        return prevState;
      }
      return [...prevState, { id, message, type }];
    });
  }, []);

  const value: AlertContextType = { alerts, handleDismissAlert, throwAlert };
  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
}

export function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
}
