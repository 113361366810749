import { Box } from '@youversion/design-system-components';
import { useTheme } from 'hooks/useTheme';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props) {
  useTheme();
  return <Box className='m-auto max-w-xl'>{children}</Box>;
}
