export const captchaConfig = {
  siteKey: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || '',
  siteVerifyUrl: process.env.NEXT_PUBLIC_YV_CAPTCHA_SITE_VERIFY_URL || '',
  types: {
    hcaptcha: 'hcaptcha',
    reCaptcha: 'recaptcha_v2',
  },
};

export const keyCodes = {
  ENTER: 'Enter',
};

export const localStorageKeys = {
  EDIT_SCHEDULED_GIFT: 'YouVersion:Giving:EditScheduledGift',
  EDIT_SCHEDULED_GIFT_PAYMENT_METHOD: 'YouVersion:Giving:EditScheduledGiftPaymentMethod',
  GIFT_AMOUNT: 'YouVersion:Giving:GiftAmount',
  GIFT_CURRENCY: 'YouVersion:Giving:GiftCurrency',
  GIFT_FREQUENCY: 'YouVersion:Giving:GiftFrequency',
  GIFT_FUND: 'YouVersion:Giving:GiftFund',
  GIFT_PAYMENT_DATE: 'YouVersion:Giving:GiftPaymentDate',
  GIFT_PAYMENT_METHOD: 'YouVersion:Giving:GiftPaymentMethod',
  LAST_GIFT_PAYMENT_METHOD: 'YouVersion:Giving:LastGiftPaymentMethod',
  SCHEDULE_ONE_TIME: 'YouVersion:Giving:ScheduleOneTime',
};

export const Modals = {
  paymentMethod: 'paymentMethod',
  pmAdd: 'pmAdd',
  pmAddBank: 'pmAddBank',
  pmAddCard: 'pmAddCard',
} as const;

export type ModalsType = keyof typeof Modals;

export const donationValidation = {
  minimumAmount: 1.0,
};

export const DEFAULT_LOCALE = 'en-US';

type APIEnvs = 'development' | 'staging' | 'production';
export const apiEnv = (process.env.NEXT_PUBLIC_API_ENV as APIEnvs) || 'staging';

export const IS_STAGING = apiEnv === 'staging';

export const oauthCredentials = {
  clientId: process.env.NEXT_PUBLIC_YV_AUTH_CLIENT_ID || '',
  clientSecret: process.env.NEXT_PUBLIC_YV_AUTH_CLIENT_SECRET || '',
  isStaging: IS_STAGING,
};

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const GRAPHQL_ENDPOINT = `https://presentation.youversionapi${IS_STAGING ? 'staging' : ''}.com/graphql`;

export const GIVING_V2_ENDPOINT =
  process.env.NEXT_PUBLIC_YV_GIVING_V2_API_URL ?? 'https://giving.youversionapistaging.com/v2';

export const COMMIT_SHA = process.env.NEXT_PUBLIC_COMMIT_SHA ?? 'unknown';
export const DATAMAN_URL = process.env.NEXT_PUBLIC_DATAMAN_URL ?? 'https://dataman.youversionapistaging.com/4.0/events';
export const LOGIN_URL = process.env.NEXT_PUBLIC_LOGIN_URL ?? 'unknown';
