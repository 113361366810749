import 'styles/global.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@youversion/auth';
import { Dataman, generatePageIds } from '@youversion/dataman';
import { DatamanProvider } from '@youversion/dataman-react';
import { InitializeAuth } from 'components/app/initialize_auth';
import { Layout } from 'components/ui/layout';
import { AlertProvider } from 'context/alert';
import { LazyMotion } from 'framer-motion';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { setCookie } from 'nookies';
import React, { useEffect, useMemo, useState } from 'react';
import { sourceFromUserAgent } from 'src/utils/helpers/sourceFromUserAgent';
import { apiEnv, COMMIT_SHA, DATAMAN_URL, oauthCredentials } from 'utils/constants';
import { setDateFnsLocale } from 'utils/helpers/dateLocale';

import { TransactionSource } from '@/graphql/user';

const loadFeatures = () => import('src/components/app/framer_features').then(res => res.default);

interface NextAppProps extends Pick<AppProps, 'pageProps' | 'Component'> {}

const App = ({ Component, pageProps }: NextAppProps) => {
  const { i18n } = useTranslation('common');
  const { language, dir } = i18n;
  const router = useRouter();
  const { pathname } = router;

  const [datamanClient] = useState(
    () =>
      new Dataman({
        applicationId: 'com.bible.give',
        appVersion: COMMIT_SHA,
        clientId: oauthCredentials.clientId,
        debug: false,
        // @ts-ignore
        endpoint: DATAMAN_URL,
        environment: apiEnv,
        initialLanguage: language ?? null,
      })
  );

  const datamanEvent = useMemo(() => datamanClient.buildEvent(pathname), [datamanClient, pathname]);

  useEffect(() => {
    if (language && dir) {
      setDateFnsLocale(language);
      document.dir = dir();
    }
  }, [dir, language]);

  useEffect(() => {
    if (language) {
      setCookie(null, 'locale', language);
    }
  }, [language]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    const elm = document.getElementsByTagName('html')[0];
    elm.style.removeProperty('background-color');
  }, []);

  useEffect(() => {
    const onPageLoad = () => {
      if (window.FlutterOnPageLoad) {
        window.FlutterOnPageLoad.postMessage('pageLoaded');
      }
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const transactionSource = useMemo(() => sourceFromUserAgent(), []);

  useEffect(() => {
    if (transactionSource === TransactionSource.WEB_WEB) {
      datamanClient.logImpression({
        contentIds: generatePageIds({ contentType: 'page', pathname }),
        event: datamanEvent,
        elementId: pathname,
        pathname,
      });
    }
  }, [datamanClient, datamanEvent, pathname, transactionSource]);

  return (
    <React.StrictMode>
      <Head>
        <title>YouVersion Giving</title>
        <meta content='minimum-scale=1, maximum-scale=5, initial-scale=1, width=device-width' name='viewport' />
      </Head>
      <DatamanProvider value={datamanClient}>
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <AuthProvider config={oauthCredentials}>
              <InitializeAuth>
                <Layout>
                  <LazyMotion features={loadFeatures} strict={true}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...pageProps} />
                  </LazyMotion>
                </Layout>
              </InitializeAuth>
            </AuthProvider>
          </AlertProvider>
        </QueryClientProvider>
      </DatamanProvider>
    </React.StrictMode>
  );
};

export default appWithTranslation(App);
