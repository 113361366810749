import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export type Theme = 'dark' | 'light';

export function useTheme() {
  const router = useRouter();

  // Default to system's theme setting.
  const defaultTheme: Theme =
    typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const mediaChangeTheme = useCallback(
    (m: MediaQueryListEvent | MediaQueryList) => {
      let themeFromQuery = normalizeQuery(router.query.theme);

      // Query param take precedence over local setting.
      if (['dark', 'light'].includes(themeFromQuery)) {
        return;
      }
      setTheme(m.matches ? 'dark' : 'light');
    },
    [router.query.theme]
  );

  useEffect(() => {
    const media = window.matchMedia('(prefers-color-scheme: dark)');
    media.addEventListener('change', mediaChangeTheme);

    return () => media.removeEventListener('change', mediaChangeTheme);
  }, [mediaChangeTheme]);

  // If there's a theme specified in the URL, override system setting.
  useEffect(() => {
    const themeFromQuery = normalizeQuery(router.query.theme);
    if (themeFromQuery === 'dark' || themeFromQuery === 'light') {
      setTheme(themeFromQuery);
    }
  }, [router.query]);

  // Add the theme as a class to the <html> element.
  useEffect(() => {
    document.documentElement.className = theme === 'dark' ? 'dark' : '';
    setCookie('theme', theme, { expires: new Date('Fri, 31 Dec 9999 23:59:59 GMT') });
  }, [theme]);

  return theme;
}

function normalizeQuery(query: string | Array<string> | unknown) {
  return (Array.isArray(query) ? query[0] : query) ?? '';
}
