import { getToken } from '@youversion/auth';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
// @matt.day: Build is failing when this uses 'utils' instead of the full path. Best guess is babel is not resolving
//  the custom module paths defined in tsconfig. Might be resolved when we switch back to SWC.
// noinspection ES6PreferShortImport
import { GRAPHQL_ENDPOINT, oauthCredentials } from 'src/utils/constants/index';

export function fetcher<TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']) {
  return async (): Promise<TData> => {
    const isBrowserRequest = typeof window !== 'undefined';
    const defaultFetchOptions: globalThis.RequestInit = {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      referrerPolicy: 'no-referrer-when-downgrade',
    };
    let res: Response;

    if (isBrowserRequest) {
      const token = await getToken(oauthCredentials);
      const { ssrYva } = parseCookies();
      // Update server-side token
      if (token?.token && token.token !== ssrYva && !options) {
        destroyCookie(null, 'ssrYva');
        setCookie(null, 'ssrYva', token.token, { path: '/' });
      }

      const headers = options ?? {
        ...(token?.token ? { Authorization: `Bearer ${token?.token}` } : {}),
        'content-type': 'application/json',
        'X-YouVersion-App-Platform': 'web',
        'X-YouVersion-Client': 'giving-web',
      };

      res = await fetch(GRAPHQL_ENDPOINT, {
        ...defaultFetchOptions,
        headers: headers as globalThis.RequestInit['headers'],
      });
    } else {
      // Server-side request
      res = await fetch(GRAPHQL_ENDPOINT, {
        ...defaultFetchOptions,
        headers: options as globalThis.RequestInit['headers'],
      });
    }

    let json = await res.json();

    // for testing partial data response with errors
    if (json.data?.errors && json.data?.data) {
      json = json.data;
    }

    if (json.errors) {
      const { message } = json.errors[0];

      // if (SENTRY_DSN) {
      //   for (const error of json.errors) {
      //     // See https://blog.sentry.io/2020/07/22/handling-graphql-errors-using-sentry
      //     Sentry.withScope(scope => {
      //       // Grab the custom query name.
      //       scope.setTag('kind', query.trim().split(' ', 2)[1].split('(', 1)[0]);
      //
      //       if (error.extensions.statusCode) {
      //         scope.setTag('rest_api_code', error.extensions.statusCode);
      //       }
      //
      //       scope.setExtra('query', query);
      //       scope.setExtra('variables', variables);
      //       scope.setExtra('error', error);
      //       const { message, ...rest } = error;
      //       Sentry.captureException(new GraphQLError(message, rest as GraphQLErrorArgs));
      //     });
      //   }
      // }

      const isEmptyArray = Array.isArray(json.data) && json.data.length < 1;
      const isEmptyObject = typeof json.data === 'object' && Object.values(json.data).length < 1;
      const isObjectWithOnlyNullValue =
        typeof json.data === 'object' && Object.values(json.data).length === 1 && Object.values(json.data)[0] === null;

      if (!json.data || isEmptyArray || isEmptyObject || isObjectWithOnlyNullValue) {
        throw new Error(message);
      }
    }

    return json.data;
  };
}
