import * as Types from './types';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../src/api/fetcher';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type Achievements = {
  __typename?: 'Achievements';
  /** Array of achievement objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<DataListItem>>>;
};

export type Achievements2ListItem = {
  __typename?: 'Achievements2ListItem';
  /** array of strings related to the achievement earned */
  achString: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** description of the achievement */
  description: Maybe<Scalars['String']['output']>;
  /** the achievement id */
  id: Maybe<Scalars['String']['output']>;
  /** The id of the selected avatar. */
  levelsComplete: Maybe<Scalars['Int']['output']>;
};

export type ActivitiesActivities = {
  __typename?: 'ActivitiesActivities';
  data: Maybe<Array<Maybe<ActivitiesActivity>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type ActivitiesActivity = {
  __typename?: 'ActivitiesActivity';
  activityId: Maybe<Scalars['Float']['output']>;
  content: Maybe<Scalars['String']['output']>;
  createdDt: Maybe<Scalars['String']['output']>;
  day: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  kind: Maybe<Schema>;
  likes: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  togetherId: Maybe<Scalars['Int']['output']>;
  updatedDt: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type ActivitiesActivityCreateInput = {
  content: Scalars['String']['input'];
  createdDt?: InputMaybe<Scalars['String']['input']>;
  day: Scalars['Int']['input'];
  kind: Schema;
};

export type ActivitiesActivityUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  updatedDt: Scalars['String']['input'];
};

export type Admin41Suggestions = {
  __typename?: 'Admin41Suggestions';
  /** A list of Suggestion resource objects for admin */
  data: Maybe<Array<Maybe<AdminSuggestion>>>;
};

export type AdminAccountsAccount = {
  __typename?: 'AdminAccountsAccount';
  /** The id of the account */
  id: Maybe<Scalars['String']['output']>;
  /** The email the invite is going too. */
  inviteEmail: Maybe<Scalars['String']['output']>;
  /** This is the date when the invite will expire */
  inviteExpireDt: Maybe<Scalars['String']['output']>;
  /** The base64/url encoded email or user id AuthZ principal string */
  principal: Maybe<Scalars['String']['output']>;
  roleBindings: Maybe<Array<Maybe<RoleBinding>>>;
  /** An enumerator to determine the current state of an Account */
  status: Maybe<Status>;
  user: Maybe<AdminUsersUser>;
  /** The id of the user */
  userId: Maybe<Scalars['Int']['output']>;
};

export type AdminAccountsAccounts = {
  __typename?: 'AdminAccountsAccounts';
  data: Maybe<Array<Maybe<AdminAccountsAccount>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminAccountsInviteInput = {
  /** array of roles to assign the invited account */
  authzRoles?: InputMaybe<Array<InputMaybe<AuthzRolesListItemInput>>>;
  /** This is the email to the account that wants to be invited */
  email: Scalars['String']['input'];
};

export type AdminAccountsInviteVerifyInput = {
  verify: Scalars['String']['input'];
};

export type AdminAddress = {
  __typename?: 'AdminAddress';
  administrativeAreaLevel1: Maybe<AdministrativeAreaLevel1>;
  country: Country;
  /** The human-readable address of the organization. */
  formattedAddress: Maybe<Scalars['String']['output']>;
  /** The angular distance of a location north or south of the earths equator. */
  latitude: Maybe<Scalars['Float']['output']>;
  locality: Maybe<Locality>;
  /** The angular distance of a location east or west of the meridian. */
  longitude: Maybe<Scalars['Float']['output']>;
  /** The textual identifier that uniquely identifies a place using google places api id. */
  placeId: Maybe<Scalars['String']['output']>;
};

export type AdminAddressInput = {
  administrativeAreaLevel1?: InputMaybe<AdministrativeAreaLevel1Input>;
  country: CountryInput;
  /** The human-readable address of the organization. */
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  /** The angular distance of a location north or south of the earths equator. */
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locality?: InputMaybe<LocalityInput>;
  /** The angular distance of a location east or west of the meridian. */
  longitude?: InputMaybe<Scalars['Float']['input']>;
  /** The textual identifier that uniquely identifies a place using google places api id. */
  placeId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminBibleVersion = {
  dblId: Scalars['String']['output'];
  deletedDt: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  languageTag: Maybe<Scalars['String']['output']>;
  releaseDt: Maybe<Scalars['String']['output']>;
  replacedById: Maybe<Scalars['Int']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: VersionType;
};

export type AdminChurchesChurchMutation = {
  __typename?: 'AdminChurchesChurchMutation';
  address: Maybe<AdminAddress>;
  /** This is the hex color code for the background color of an organization. */
  backgroundColor: Maybe<Scalars['String']['output']>;
  /** The email of the organization. */
  email: Maybe<Scalars['String']['output']>;
  /** the different descriptions and language_tags associated with an organization's international identity */
  i18ns: Maybe<Array<Maybe<I18ns4ListItem>>>;
  id: Maybe<Scalars['String']['output']>;
  logoImageId: Maybe<Scalars['String']['output']>;
  /** The name of the organization. */
  name: Maybe<Scalars['String']['output']>;
  pastor: Maybe<AdminOrganizationsPastorPatchMutation>;
  /** The phone number of the organization. */
  phone: Maybe<Scalars['String']['output']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current approval state of a Profile */
  status: Maybe<Status2>;
  supportAccountId: Maybe<Scalars['String']['output']>;
  /** The id of the last user to update the organization */
  supportUserId: Maybe<Scalars['Int']['output']>;
  /** The timezone of the organization. */
  timezone: Maybe<Scalars['String']['output']>;
  /** The date time when the church got verified. */
  verifiedDt: Maybe<Scalars['String']['output']>;
  /** The website_url or the organization. */
  websiteUrl: Maybe<Scalars['String']['output']>;
  /** This is the weekly attendance of the organization. */
  weeklyAttendance: Maybe<Scalars['Int']['output']>;
};

export type AdminChurchesChurchMutationInput = {
  address?: InputMaybe<AdminAddressInput>;
  /** This is the hex color code for the background color of an organization. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** The email of the organization. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** the different descriptions and language_tags associated with an organization's international identity */
  i18ns?: InputMaybe<Array<InputMaybe<I18ns4ListItemInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  logoImageId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the organization. */
  name?: InputMaybe<Scalars['String']['input']>;
  pastor?: InputMaybe<AdminOrganizationsPastorPatchMutationInput>;
  /** The phone number of the organization. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  /** An enumerator to determine the current approval state of a Profile */
  status?: InputMaybe<Status2>;
  supportAccountId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the last user to update the organization */
  supportUserId?: InputMaybe<Scalars['Int']['input']>;
  /** The timezone of the organization. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** The date time when the church got verified. */
  verifiedDt?: InputMaybe<Scalars['String']['input']>;
  /** The website_url or the organization. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  /** This is the weekly attendance of the organization. */
  weeklyAttendance?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminChurchesChurchPostMutation = {
  __typename?: 'AdminChurchesChurchPostMutation';
  address: Maybe<AdminAddress>;
  /** This is the default hex color code for the background color of an organization if none is supplied the hex color for Regent Grey will be used as the default. */
  backgroundColor: Maybe<Scalars['String']['output']>;
  /** The email of the organization. */
  email: Maybe<Scalars['String']['output']>;
  /** The organization id. */
  id: Maybe<Scalars['String']['output']>;
  /** The name of the organization. */
  name: Scalars['String']['output'];
  /** The phone number of the organization. */
  phone: Maybe<Scalars['String']['output']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Scalars['String']['output'];
  /** Who or what referred the organization to sign up for yv4c */
  signupReferrer: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current approval state of a Profile */
  status: Maybe<Status2>;
  /** The timezone of the organization. */
  timezone: Scalars['String']['output'];
  /** The website_url of the organization. */
  websiteUrl: Scalars['String']['output'];
  /** The weekly attendance of the organization. */
  weeklyAttendance: Maybe<Scalars['Int']['output']>;
};

export type AdminChurchesChurchPostMutationInput = {
  address?: InputMaybe<AdminAddressInput>;
  /** This is the default hex color code for the background color of an organization if none is supplied the hex color for Regent Grey will be used as the default. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** The email of the organization. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The organization id. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The name of the organization. */
  name: Scalars['String']['input'];
  /** The phone number of the organization. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Scalars['String']['input'];
  /** Who or what referred the organization to sign up for yv4c */
  signupReferrer?: InputMaybe<Scalars['String']['input']>;
  /** An enumerator to determine the current approval state of a Profile */
  status?: InputMaybe<Status2>;
  /** The timezone of the organization. */
  timezone: Scalars['String']['input'];
  /** The website_url of the organization. */
  websiteUrl: Scalars['String']['input'];
  /** The weekly attendance of the organization. */
  weeklyAttendance?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminChurchesSchedule = {
  __typename?: 'AdminChurchesSchedule';
  serviceTimes: Array<Maybe<AdminOrganizationsOrganizationServiceTime>>;
  /** This is day of the week that the organization starts on. Integer due to turning days into integers. */
  weekStartsOn: Scalars['Int']['output'];
};

export type AdminChurchesScheduleInput = {
  serviceTimes: Array<InputMaybe<AdminOrganizationsOrganizationServiceTimeInput>>;
  /** This is day of the week that the organization starts on. Integer due to turning days into integers. */
  weekStartsOn: Scalars['Int']['input'];
};

export type AdminContentContentCollection = {
  __typename?: 'AdminContentContentCollection';
  data: Maybe<Array<Maybe<AdminContentContentResource>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminContentContentCreateInput = {
  contentLanguages: Array<InputMaybe<Scalars['String']['input']>>;
  /** An enumerator that describes the different types of devotional content can be. */
  devotionalType: DevotionalType;
  externalId: Scalars['Int']['input'];
  /** An enumerator of the different formats or types of media */
  mediaType: Schema;
};

export type AdminContentContentResource = {
  __typename?: 'AdminContentContentResource';
  contentLanguages: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** An enumerator that describes the different types of devotional content can be. */
  devotionalType: Maybe<DevotionalType>;
  externalId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  mediaType: Maybe<Schema>;
  plans: Maybe<Array<Maybe<Plan>>>;
  relationships: Maybe<Array<Maybe<AdminContentRelationship>>>;
  video: Maybe<Video>;
};

export type AdminContentContentUpdateInput = {
  contentLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An enumerator that describes the different types of devotional content can be. */
  devotionalType?: InputMaybe<DevotionalType>;
};

export type AdminContentContentUpserted = {
  __typename?: 'AdminContentContentUpserted';
  contentLanguages: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The time that the relationship was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** An enumerator that describes the different types of devotional content can be. */
  devotionalType: Maybe<DevotionalType>;
  externalId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  mediaType: Maybe<Schema>;
  /** The time that the relationship was last updated. */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type AdminContentRelationship = {
  __typename?: 'AdminContentRelationship';
  id: Maybe<Scalars['Int']['output']>;
  organization: Maybe<AdminOrganizationsOrganization>;
  /** The id of the organization. */
  organizationId: Scalars['String']['output'];
  /** An enumerator that describes the different types of relationships an organization can have. */
  relationshipType: RelationshipType;
};

export type AdminContentRelationshipInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the organization. */
  organizationId: Scalars['String']['input'];
  /** An enumerator that describes the different types of relationships an organization can have. */
  relationshipType: RelationshipType;
};

export type AdminContentRelationshipPatchInput = {
  /** An enumerator that describes the different types of relationships an organization can have. */
  relationshipType: RelationshipType;
};

export type AdminContentRelationshipUpserted = {
  __typename?: 'AdminContentRelationshipUpserted';
  contentId: Maybe<Scalars['Int']['output']>;
  /** The time that the relationship was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  /** The id of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** An enumerator that describes the different types of relationships an organization can have. */
  relationshipType: Maybe<RelationshipType>;
  /** The time that the relationship was last updated. */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type AdminContentRelationships = {
  __typename?: 'AdminContentRelationships';
  contentId: Maybe<Scalars['Int']['output']>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  relationships: Maybe<Array<Maybe<AdminContentRelationship>>>;
};

export type AdminContentTypeGrantsOrganizations = {
  __typename?: 'AdminContentTypeGrantsOrganizations';
  data: Maybe<Array<Maybe<Data12ListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminEventsAdminEvent = {
  __typename?: 'AdminEventsAdminEvent';
  content: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  description: Maybe<Scalars['String']['output']>;
  /** This is the ID of the event. */
  id: Maybe<Scalars['Float']['output']>;
  image: Maybe<Image>;
  /** This is the ID of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current state of an Event */
  status: Maybe<Status>;
  times: Maybe<Array<Maybe<TimesListItem>>>;
  /** This is the title of the event. */
  title: Maybe<Scalars['String']['output']>;
};

export type AdminEventsAdminEventContent = {
  __typename?: 'AdminEventsAdminEventContent';
  content: Array<Maybe<Scalars['JSON']['output']>>;
};

export type AdminEventsAdminEventContentInput = {
  content: Array<InputMaybe<Scalars['JSON']['input']>>;
};

export type AdminEventsAdminEventCreateInput = {
  /** This is the description of the new event. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the image tied to the new event. */
  imageId?: InputMaybe<Scalars['Float']['input']>;
  /** This is the ID of the organization creating the event. */
  organizationId: Scalars['String']['input'];
  /** This is the title of the new event. */
  title: Scalars['String']['input'];
};

export type AdminEventsAdminEventCreated = {
  __typename?: 'AdminEventsAdminEventCreated';
  description: Maybe<Scalars['String']['output']>;
  /** This is the ID of the event. */
  id: Maybe<Scalars['Float']['output']>;
  image: Maybe<Image>;
  /** This is the ID of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current state of an Event */
  status: Maybe<Status>;
  /** This is the title of the event. */
  title: Maybe<Scalars['String']['output']>;
};

export type AdminEventsAdminEventDuplicateInput = {
  /** Id of the image tied to the new event. */
  imageId?: InputMaybe<Scalars['Float']['input']>;
  shiftDates: ShiftDatesInput;
  /** New title for the new event. */
  title: Scalars['String']['input'];
};

export type AdminEventsAdminEventTimes = {
  __typename?: 'AdminEventsAdminEventTimes';
  times: Maybe<Array<Maybe<Times3ListItem>>>;
};

export type AdminEventsAdminEventTimesInput = {
  times?: InputMaybe<Array<InputMaybe<Times3ListItemInput>>>;
};

export type AdminEventsAdminEventTransitionInput = {
  /** An enumerator to determine the current state of an Event */
  status: Status;
};

export type AdminEventsAdminEventUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /** The image id of the image that was set. */
  imageId?: InputMaybe<Scalars['Float']['input']>;
  times?: InputMaybe<Array<InputMaybe<EventTimeInput>>>;
  /** This is the title of the event. */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminEventsAdminEvents = {
  __typename?: 'AdminEventsAdminEvents';
  data: Maybe<Array<Maybe<AdminEventsAdminEvent>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminExports = {
  __typename?: 'AdminExports';
  data: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsAccountsAccount = {
  __typename?: 'AdminOrganizationsAccountsAccount';
  /** The id for the account. */
  id: Maybe<Scalars['String']['output']>;
  /** This is the email the invite will be sent to */
  inviteEmail: Maybe<Scalars['String']['output']>;
  /** This is the date when the invite will expire */
  inviteExpireDt: Maybe<Scalars['String']['output']>;
  organization: Maybe<AdminOrganizationsOrganization>;
  /** The organization id. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** invite_email base64/url encoded. */
  principal: Maybe<Scalars['String']['output']>;
  roleBindings: Maybe<Array<Maybe<RoleBinding>>>;
  /** An enumerator to determine the current state of an Account */
  status: Maybe<Status>;
  user: Maybe<AdminUsersUser>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsAccountsAccounts = {
  __typename?: 'AdminOrganizationsAccountsAccounts';
  data: Maybe<Array<Maybe<AdminOrganizationsAccountsAccount>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsAccountsInviteRequestInput = {
  /** This is the email you are sending an invite too. */
  email: Scalars['String']['input'];
  /** This is the date when the invite will expire. If null is provided then the invite will never expire. */
  inviteExpireDt?: InputMaybe<Scalars['String']['input']>;
  /** list of roles to assign the invited account */
  roles?: InputMaybe<Array<InputMaybe<RolesListItemInput>>>;
  /** Controls whether or not the API sends the invite url directly. */
  sendInvite?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminOrganizationsAccountsInviteResendInput = {
  /** This is the date when the invite will expire. If null is provided then the invite will never expire. */
  inviteExpireDt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsAccountsInviteResponse = {
  __typename?: 'AdminOrganizationsAccountsInviteResponse';
  /** The id for the account. */
  id: Maybe<Scalars['String']['output']>;
  /** This is the email the invite will be sent to */
  inviteEmail: Maybe<Scalars['String']['output']>;
  /** This is the date when the invite will expire */
  inviteExpireDt: Maybe<Scalars['String']['output']>;
  /** A URL to the account invite. */
  inviteLink: Maybe<Scalars['String']['output']>;
  /** The organization id. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** invite_email base64/url encoded. */
  principal: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current state of an Account */
  status: Maybe<Status>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsAccountsInviteVerifyInput = {
  verify: Scalars['String']['input'];
};

export type AdminOrganizationsBibleVersionGrant = {
  __typename?: 'AdminOrganizationsBibleVersionGrant';
  /** The time that this bible version grant was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** The created method. */
  createdMethod: CreatedMethod2;
  /** The id of the bible version grant. */
  id: Maybe<Scalars['String']['output']>;
  /** The time that this bible version grant was created */
  updatedDt: Maybe<Scalars['String']['output']>;
  version: Maybe<AdminBibleVersion>;
  /** The version id. */
  versionId: Scalars['Int']['output'];
  /** The type of version. */
  versionType: VersionType;
};

export type AdminOrganizationsBibleVersionGrantInput = {
  /** The time that this bible version grant was created. */
  createdDt?: InputMaybe<Scalars['String']['input']>;
  /** The created method. */
  createdMethod: CreatedMethod2;
  /** The id of the bible version grant. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The time that this bible version grant was created */
  updatedDt?: InputMaybe<Scalars['String']['input']>;
  /** The version id. */
  versionId: Scalars['Int']['input'];
  /** The type of version. */
  versionType: VersionType;
};

export type AdminOrganizationsBibleVersionGrants = {
  __typename?: 'AdminOrganizationsBibleVersionGrants';
  data: Maybe<Array<Maybe<AdminOrganizationsBibleVersionGrant>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsContentGrant = {
  __typename?: 'AdminOrganizationsContentGrant';
  /** The type of content. */
  contentType: Schema;
  /** The time that this content grant was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** The created method. */
  createdMethod: CreatedMethod;
  /** The external id that relates to the content. */
  externalId: Scalars['Int']['output'];
  /** The id of the content grant. */
  id: Maybe<Scalars['String']['output']>;
  plan: Maybe<Plan>;
  /** The time that this content grant was created */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsContentGrantInput = {
  /** The type of content. */
  contentType: Schema;
  /** The time that this content grant was created. */
  createdDt?: InputMaybe<Scalars['String']['input']>;
  /** The created method. */
  createdMethod: CreatedMethod;
  /** The external id that relates to the content. */
  externalId: Scalars['Int']['input'];
  /** The id of the content grant. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The time that this content grant was created */
  updatedDt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsContentGrants = {
  __typename?: 'AdminOrganizationsContentGrants';
  data: Maybe<Array<Maybe<AdminOrganizationsContentGrant>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsContentPartner = {
  __typename?: 'AdminOrganizationsContentPartner';
  email: Maybe<Scalars['String']['output']>;
  externalIds: Maybe<Array<Maybe<Items2>>>;
  /** The organization id. */
  id: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current state of a License. */
  licenseStatus: Maybe<LicenseStatus>;
  /** An enumerator to determine the type of License Agreement. */
  licenseType: Maybe<LicenseType>;
  /** The user_id of the user that updated the license agreement. */
  licenseUpdatedBy: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  primaryLanguage: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsContentPartnerPatchInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalIds?: InputMaybe<Array<InputMaybe<Items2Input>>>;
  /** An enumerator to determine what type of partner hubspot has classified a profile as */
  hubspotPartnerType?: InputMaybe<HubspotPartnerType>;
  /** An enumerator to determine the current state of a License. */
  licenseStatus?: InputMaybe<LicenseStatus>;
  /** An enumerator to determine the type of License Agreement. */
  licenseType?: InputMaybe<LicenseType>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsContentPartnerPostInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalIds: Array<InputMaybe<ExternalIds2ListItemInput>>;
  /** An enumerator to determine what type of partner hubspot has classified a profile as */
  hubspotPartnerType?: InputMaybe<HubspotPartnerType>;
  name: Scalars['String']['input'];
  primaryLanguage: Scalars['String']['input'];
};

export type AdminOrganizationsContentPartners = {
  __typename?: 'AdminOrganizationsContentPartners';
  data: Maybe<Array<Maybe<AdminOrganizationsContentPartner>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsConvertChurchRequestInput = {
  /** What the client will call that will determine to take the organization to single or multi site. */
  goal: Scalars['String']['input'];
};

export type AdminOrganizationsConvertChurchResponse = {
  __typename?: 'AdminOrganizationsConvertChurchResponse';
  /** If performed, convert will carry out the conversion to single or multi site */
  performed: Maybe<Scalars['Boolean']['output']>;
  targetOrganization: Maybe<AdminOrganizationsOrganization>;
};

export type AdminOrganizationsFeaturedPlan = {
  __typename?: 'AdminOrganizationsFeaturedPlan';
  /** This is the date the featured plan ended, or null if still active. */
  endDt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** The id of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  plan: Maybe<Plan>;
  /** The plan id. */
  planId: Maybe<Scalars['Int']['output']>;
  /** This is the date the featured plan started */
  startDt: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsFeaturedPlanCreateInput = {
  /** The plan id of the plan. */
  planId: Scalars['Int']['input'];
};

export type AdminOrganizationsFeaturedPlans = {
  __typename?: 'AdminOrganizationsFeaturedPlans';
  data: Maybe<Array<Maybe<AdminOrganizationsFeaturedPlan>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsLocationPostMutation = {
  __typename?: 'AdminOrganizationsLocationPostMutation';
  address: Maybe<AdminAddress>;
  /** This is the default hex color code for the background color of an organization if none is supplied the hex color for Regent Grey will be used as the default. */
  backgroundColor: Maybe<Scalars['String']['output']>;
  /** This is the email of the location. */
  email: Maybe<Scalars['String']['output']>;
  /** the different descriptions and urls associated with an organization's international identity */
  i18ns: Maybe<Array<Maybe<Items>>>;
  id: Maybe<Scalars['String']['output']>;
  /** This is the id of the logo image. */
  logoImageId: Maybe<Scalars['String']['output']>;
  /** This is the name of the location. */
  name: Scalars['String']['output'];
  pastor: Maybe<AdminOrganizationsPastorPostMutation>;
  /** This is the phone number of the location. */
  phone: Maybe<Scalars['String']['output']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Scalars['String']['output'];
  /** An enumerator to determine the current approval state of a Profile */
  status: Maybe<Status2>;
  /** This is the timezone of the location. */
  timezone: Scalars['String']['output'];
  /** This is the website_url of the location. */
  websiteUrl: Maybe<Scalars['String']['output']>;
  /** This is the weekly attendance of the church profile. */
  weeklyAttendance: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsLocationPostMutationInput = {
  address?: InputMaybe<AdminAddressInput>;
  /** This is the default hex color code for the background color of an organization if none is supplied the hex color for Regent Grey will be used as the default. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** This is the email of the location. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** the different descriptions and urls associated with an organization's international identity */
  i18ns?: InputMaybe<Array<InputMaybe<ItemsInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** This is the id of the logo image. */
  logoImageId?: InputMaybe<Scalars['String']['input']>;
  /** This is the name of the location. */
  name: Scalars['String']['input'];
  pastor?: InputMaybe<AdminOrganizationsPastorPostMutationInput>;
  /** This is the phone number of the location. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Scalars['String']['input'];
  /** An enumerator to determine the current approval state of a Profile */
  status?: InputMaybe<Status2>;
  /** This is the timezone of the location. */
  timezone: Scalars['String']['input'];
  /** This is the website_url of the location. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  /** This is the weekly attendance of the church profile. */
  weeklyAttendance?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminOrganizationsOrganization = {
  __typename?: 'AdminOrganizationsOrganization';
  accounts: Maybe<AdminOrganizationsAccountsAccounts>;
  activeFeaturedPlans: Maybe<OrganizationsFeaturedPlans>;
  /** The hex color code for the background color of an organization. */
  backgroundColor: Maybe<Scalars['String']['output']>;
  bibleVersionGrants: Maybe<AdminOrganizationsBibleVersionGrants>;
  /** The created date time of the organization. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** This is the email of the organization. */
  email: Maybe<Scalars['String']['output']>;
  featuredPlans: Maybe<AdminOrganizationsFeaturedPlans>;
  /** The fully qualified resource name of an organization */
  fqrn: Maybe<Scalars['String']['output']>;
  i18ns: Maybe<Array<Maybe<Items>>>;
  /** Church's organization id */
  id: Maybe<Scalars['String']['output']>;
  locations: Maybe<AdminOrganizationsOrganizations>;
  logo: Maybe<ImagesOrganizationImage>;
  members: Maybe<MembersCount>;
  /** This is the name of the organization. */
  name: Maybe<Scalars['String']['output']>;
  parentOrganization: Maybe<ParentOrganization>;
  /** This is the phone number of the organization. */
  phone: Maybe<Scalars['String']['output']>;
  posts: Maybe<AdminOrganizationsOrganizationPostsPosts>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Maybe<Scalars['String']['output']>;
  profiles: Maybe<OrganizationsOrganizationProfiles>;
  roleBindings: Maybe<RoleBindings>;
  roles: Maybe<Roles>;
  serviceTimes: Maybe<AdminOrganizationsServiceTimes>;
  /** Who or what referred the organization to sign up for yv4c */
  signupReferrer: Maybe<Scalars['String']['output']>;
  /** This timezone the organization is set in. */
  timezone: Maybe<Scalars['String']['output']>;
  /** This is the website_url of the organization. */
  websiteUrl: Maybe<Scalars['String']['output']>;
};


export type AdminOrganizationsOrganizationAccountsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminOrganizationsOrganizationBibleVersionGrantsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminOrganizationsOrganizationFeaturedPlansArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminOrganizationsOrganizationPostsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminOrganizationsOrganizationProfilesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type AdminOrganizationsOrganizationRolesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminOrganizationsOrganizationServiceTimesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsOrganizationCreateInput = {
  /** The hex color code for the background color of an organization. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** The created date time of the organization. */
  createdDt?: InputMaybe<Scalars['String']['input']>;
  /** This is the email of the organization. */
  email?: InputMaybe<Scalars['String']['input']>;
  i18ns?: InputMaybe<Array<InputMaybe<ItemsInput>>>;
  /** This is the ID of the organization. */
  id?: InputMaybe<Scalars['String']['input']>;
  logoImageId?: InputMaybe<Scalars['String']['input']>;
  /** This is the name of the organization. */
  name: Scalars['String']['input'];
  /** This is the phone number of the organization. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Scalars['String']['input'];
  /** Who or what referred the organization to sign up for yv4c */
  signupReferrer?: InputMaybe<Scalars['String']['input']>;
  /** This timezone the organization is set in. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** This is the website_url of the organization. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsOrganizationFollowersCollection = {
  __typename?: 'AdminOrganizationsOrganizationFollowersCollection';
  data: Maybe<Array<Maybe<AdminOrganizationsOrganizationFollowersResource>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsOrganizationFollowersResource = {
  __typename?: 'AdminOrganizationsOrganizationFollowersResource';
  /** This is the id of a user following an organization */
  userId: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsOrganizationPostsPost = {
  __typename?: 'AdminOrganizationsOrganizationPostsPost';
  /** The body of the post containing html tags only bold, italics, links, headings, numbered lists, and bulleted lists. */
  body: Maybe<Scalars['String']['output']>;
  /** The time that this post was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** The id of the post. */
  id: Maybe<Scalars['String']['output']>;
  /** Indicates if the parent's post should be pulled or inherited by the child when querying a child organizations posts. This only applies to parent organizations. */
  inherit: Maybe<Scalars['Boolean']['output']>;
  /** The organization id of the organization that this post belongs to. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** Indicates if currently active posts of any children organizations should be set to active=false to allow the new parent entities inherit settings to take affect. This only applies to parent organizations. */
  override: Maybe<Scalars['Boolean']['output']>;
  /** The title of the post. */
  title: Maybe<Scalars['String']['output']>;
  /** The time that this post was updated. */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsOrganizationPostsPostPatchInput = {
  /** The body of the post containing html tags only bold, italics, links, headings, numbered lists, and bulleted lists. */
  body?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the parent's post should be pulled or inherited by the child when querying a child organizations posts. This only applies to parent organizations. */
  inherit?: InputMaybe<Scalars['Boolean']['input']>;
  /** The organization id of the organization to create a post for. */
  organizationId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if currently active posts of any children organizations should be set to active=false to allow the new parent entities inherit settings to take affect. This only applies to parent organizations. */
  override?: InputMaybe<Scalars['Boolean']['input']>;
  /** The title of the post. */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsOrganizationPostsPostRequestInput = {
  /** The body of the post containing html tags only bold, italics, links, headings, numbered lists, and bulleted lists. */
  body: Scalars['String']['input'];
  /** Indicates if the parent's post should be pulled or inherited by the child when querying a child organizations posts. This only applies to parent organizations. */
  inherit: Scalars['Boolean']['input'];
  /** The organization id of the organization to create a post for. */
  organizationId: Scalars['String']['input'];
  /** Indicates if currently active posts of any children organizations should be set to active=false to allow the new parent entities inherit settings to take affect. This only applies to parent organizations. */
  override: Scalars['Boolean']['input'];
  /** The title of the post. */
  title: Scalars['String']['input'];
};

export type AdminOrganizationsOrganizationPostsPosts = {
  __typename?: 'AdminOrganizationsOrganizationPostsPosts';
  data: Maybe<Array<Maybe<AdminOrganizationsOrganizationPostsPost>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsOrganizationProfilesAcceptProfileLicenseInput = {
  version?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsOrganizationProfilesPatchProfileLicenseInput = {
  /** An enumerator to determine the current state of a License. */
  licenseStatus?: InputMaybe<LicenseStatus>;
  /** An enumerator to determine the type of License Agreement. */
  licenseType?: InputMaybe<LicenseType>;
};

export type AdminOrganizationsOrganizationProfilesProfileLicense = {
  __typename?: 'AdminOrganizationsOrganizationProfilesProfileLicense';
  /** The date and time that the license agreement was accepted. */
  acceptedDt: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  /** An enumerator to determine the current state of a License. */
  licenseStatus: Maybe<LicenseStatus>;
  /** An enumerator to determine the type of License Agreement. */
  licenseType: Maybe<LicenseType>;
  /** The organization_id that the license agreement is associated with. */
  organizationId: Scalars['String']['output'];
  /** An enumerator to determine the profile type. */
  profileType: Maybe<ProfileType>;
  /** The date and time that the license agreement was rejected. */
  rejectedDt: Maybe<Scalars['String']['output']>;
  /** The user_id of the user that updated the license agreement. */
  updatedBy: Maybe<Scalars['Float']['output']>;
  /** The stringified version of the date the license agreement was last updated. */
  version: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsOrganizationRelationship = {
  __typename?: 'AdminOrganizationsOrganizationRelationship';
  /** The child id. */
  childId: Scalars['String']['output'];
  /** The parent id. */
  parentId: Maybe<Scalars['String']['output']>;
  /** The type of relationship between two organizations. */
  type: Type;
};

export type AdminOrganizationsOrganizationRelationshipInput = {
  /** The child id. */
  childId: Scalars['String']['input'];
  /** The parent id. */
  parentId?: InputMaybe<Scalars['String']['input']>;
  /** The type of relationship between two organizations. */
  type: Type;
};

export type AdminOrganizationsOrganizationServiceTime = {
  __typename?: 'AdminOrganizationsOrganizationServiceTime';
  /** This is the day of the week services happen on. This is an integer because start of week takes days into integers. */
  dayOfWeek: Scalars['Int']['output'];
  i18ns: Maybe<Array<Maybe<I18ns3ListItem>>>;
  id: Maybe<Scalars['String']['output']>;
  /** This is the time of day the organization has a service. */
  timeOfDay: Scalars['String']['output'];
};

export type AdminOrganizationsOrganizationServiceTimeInput = {
  /** This is the day of the week services happen on. This is an integer because start of week takes days into integers. */
  dayOfWeek: Scalars['Int']['input'];
  i18ns?: InputMaybe<Array<InputMaybe<I18ns3ListItemInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  /** This is the time of day the organization has a service. */
  timeOfDay: Scalars['String']['input'];
};

export type AdminOrganizationsOrganizationUpdateInput = {
  /** The hex color code for the background color of an organization. */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** This is the email of the organization. */
  email?: InputMaybe<Scalars['String']['input']>;
  i18ns?: InputMaybe<Array<InputMaybe<ItemsInput>>>;
  logoImageId?: InputMaybe<Scalars['String']['input']>;
  /** This is the name of the organization. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** This is the phone number of the organization. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
  /** Who or what referred the organization to sign up for yv4c */
  signupReferrer?: InputMaybe<Scalars['String']['input']>;
  /** This timezone the organization is set in. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** This is the website_url of the organization. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsOrganizations = {
  __typename?: 'AdminOrganizationsOrganizations';
  data: Maybe<Array<Maybe<AdminOrganizationsOrganization>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminOrganizationsPastorPatchMutation = {
  __typename?: 'AdminOrganizationsPastorPatchMutation';
  /** The image id of the image that was set. */
  imageId: Maybe<Scalars['String']['output']>;
  /** The name of the pastor. */
  name: Maybe<Scalars['String']['output']>;
  /** The title of the pastor. */
  title: Maybe<Scalars['String']['output']>;
};

export type AdminOrganizationsPastorPatchMutationInput = {
  /** The image id of the image that was set. */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the pastor. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The title of the pastor. */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AdminOrganizationsPastorPostMutation = {
  __typename?: 'AdminOrganizationsPastorPostMutation';
  /** This is the image id of the pastor. */
  imageId: Maybe<Scalars['String']['output']>;
  /** This is the name of the pastor. */
  name: Scalars['String']['output'];
  /** This is the title of the pastor. */
  title: Scalars['String']['output'];
};

export type AdminOrganizationsPastorPostMutationInput = {
  /** This is the image id of the pastor. */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** This is the name of the pastor. */
  name: Scalars['String']['input'];
  /** This is the title of the pastor. */
  title: Scalars['String']['input'];
};

export type AdminOrganizationsServiceTimes = {
  __typename?: 'AdminOrganizationsServiceTimes';
  data: Maybe<Array<Maybe<AdminOrganizationsOrganizationServiceTime>>>;
};

export type AdminPlaylistOrder = {
  __typename?: 'AdminPlaylistOrder';
  /** Content ids in the playlist */
  playlistIds: Array<Maybe<Scalars['Int']['output']>>;
};

export type AdminPlaylistOrderInput = {
  /** Content ids in the playlist */
  playlistIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type AdminPlaylistsPlaylist = {
  __typename?: 'AdminPlaylistsPlaylist';
  audioBibles: Maybe<Array<AudioBibleData>>;
  /** Content ids in the playlist */
  contentIds: Array<Maybe<Scalars['Int']['output']>>;
  /** The playlist id from an external service */
  externalPlaylistId: Maybe<Scalars['Int']['output']>;
  i18ns: Maybe<Array<Maybe<I18nsListItem>>>;
  id: Scalars['Int']['output'];
  /** The languages this playlist has content in. */
  languagesAvailable: Array<Maybe<Scalars['String']['output']>>;
  plans: Maybe<Array<Plan>>;
  /** An enumerator of the different formats or types of media */
  playlistType: Maybe<Schema2>;
  /** The playlist's sort order. */
  sort: Maybe<Scalars['Int']['output']>;
  textBibles: Maybe<Array<TextVersion>>;
  videos: Maybe<Array<Video>>;
};

export type AdminPlaylistsPlaylistContentInput = {
  /** Content id(s) to add to the playlist */
  contentIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type AdminPlaylistsPlaylistCreateInput = {
  /** Content id(s) to add to the playlist */
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** The external service playlist id, i.e. the collection id from Videos. Currently only supports video collections */
  externalPlaylistId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the organization. */
  organizationId: Scalars['String']['input'];
};

export type AdminPlaylistsPlaylistUpdateInput = {
  externalPlaylistId: Scalars['Int']['input'];
};

export type AdminPlaylistsPlaylists = {
  __typename?: 'AdminPlaylistsPlaylists';
  data: Maybe<Array<Maybe<AdminPlaylistsPlaylist>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type AdminSuggestion = {
  __typename?: 'AdminSuggestion';
  /** ISO 639 date & time resource was last updated */
  deletedDt: Maybe<Scalars['String']['output']>;
  /** How common the suggestion is */
  frequency: Scalars['Int']['output'];
  /** Identifier used to update/delete a suggestion after it has been made */
  id: Maybe<Scalars['Int']['output']>;
  /** Which language the suggestion is in */
  languageTag: Scalars['String']['output'];
  /** Query suggestion text */
  query: Scalars['String']['output'];
};

export type AdminSuggestionInput = {
  /** ISO 639 date & time resource was last updated */
  deletedDt?: InputMaybe<Scalars['String']['input']>;
  /** How common the suggestion is */
  frequency: Scalars['Int']['input'];
  /** Identifier used to update/delete a suggestion after it has been made */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Which language the suggestion is in */
  languageTag: Scalars['String']['input'];
  /** Query suggestion text */
  query: Scalars['String']['input'];
};

export type AdminTrending = {
  __typename?: 'AdminTrending';
  /** Id's for trending items */
  id: Maybe<Scalars['Int']['output']>;
  /** Language of the search text */
  languageTag: Scalars['String']['output'];
  /** Trending search text */
  text: Scalars['String']['output'];
};

export type AdminTrendings = {
  __typename?: 'AdminTrendings';
  /** A list of trending resource objects for admin */
  data: Maybe<Array<Maybe<AdminTrending>>>;
};

export type AdminUsersSearchUsers = {
  __typename?: 'AdminUsersSearchUsers';
  /** List of user_ids. */
  data: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type AdminUsersUser = {
  __typename?: 'AdminUsersUser';
  /** The id for the users avatar. */
  avatarImageId: Maybe<Scalars['String']['output']>;
  /** The url of a users avatar. */
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** The bio of a given user. */
  bio: Maybe<Scalars['String']['output']>;
  /** The birthday of a given user. */
  birthdate: Maybe<Scalars['String']['output']>;
  /** The current country a user is using in the app in. */
  country: Maybe<Scalars['String']['output']>;
  /** The user's email address */
  email: Maybe<Scalars['String']['output']>;
  /** The first name of a given user. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The given user's gender. */
  gender: Maybe<Scalars['Float']['output']>;
  /** Unique identifier (user_id) */
  id: Maybe<Scalars['Float']['output']>;
  /** The current language a user is using in the app. */
  languageTag: Maybe<Scalars['String']['output']>;
  /** The last name of a given user. */
  lastName: Maybe<Scalars['String']['output']>;
  /** The location of a given user. */
  location: Maybe<Scalars['String']['output']>;
  /** The current timezone of a user. */
  timezone: Maybe<Scalars['String']['output']>;
  /** The first name of a given user. */
  username: Maybe<Scalars['String']['output']>;
  /** Indicates whether or not the user has verified their email. */
  verified: Maybe<Scalars['Boolean']['output']>;
};

export type AdminUsersUserSearchInput = {
  /** Query to search first_name, last_name, or email */
  query?: InputMaybe<Scalars['String']['input']>;
  /** A subset list of user_ids to query */
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AdministrativeAreaLevel1 = {
  __typename?: 'AdministrativeAreaLevel1';
  /** The long name of the administrative area, e.g. "Oklahoma" for the state of Oklahoma. */
  longName: Maybe<Scalars['String']['output']>;
  /** The short name of the administrative area, e.g. "OK" for Oklahoma. */
  shortName: Maybe<Scalars['String']['output']>;
};

export type AdministrativeAreaLevel1Input = {
  /** The long name of the administrative area, e.g. "Oklahoma" for the state of Oklahoma. */
  longName?: InputMaybe<Scalars['String']['input']>;
  /** The short name of the administrative area, e.g. "OK" for Oklahoma. */
  shortName?: InputMaybe<Scalars['String']['input']>;
};

export type AdministrativeAreaLevel12 = {
  __typename?: 'AdministrativeAreaLevel12';
  /** The long name of the administrative area, e.g. "Oklahoma" for the state of Oklahoma. */
  longName: Maybe<Scalars['String']['output']>;
  /** The short name of the administrative area, e.g. "OK" for Oklahoma. */
  shortName: Maybe<Scalars['String']['output']>;
};

export enum AgeGroup {
  ADULT = 'ADULT',
  TWEEN = 'TWEEN'
}

export enum AgeGroup2 {
  ADULT = 'ADULT',
  TWEEN = 'TWEEN'
}

export type ApiTag = {
  __typename?: 'ApiTag';
  /** API language tag */
  tag: Maybe<Scalars['String']['output']>;
};

export type AudioBibleAudioBible = {
  __typename?: 'AudioBibleAudioBible';
  response: Maybe<Response>;
};

export type AudioBibleData = {
  __typename?: 'AudioBibleData';
  copyrightLong: Maybe<CopyrightLong>;
  copyrightShort: Maybe<CopyrightShort>;
  /** This is the audio version ID of this Bible version. */
  id: Maybe<Scalars['Int']['output']>;
  publisher: Maybe<Publisher>;
  /** A link to the publishers page. */
  publisherLink: Maybe<Scalars['String']['output']>;
  /** The title of an Audio Bible. */
  title: Maybe<Scalars['String']['output']>;
  /** This is the text version ID that is associated with this audio version. */
  versionId: Maybe<Scalars['Int']['output']>;
};

export type AudioBibleVersion = AdminBibleVersion & {
  __typename?: 'AudioBibleVersion';
  dblId: Scalars['String']['output'];
  deletedDt: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  languageName: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  localLanguageName: Maybe<Scalars['String']['output']>;
  releaseDt: Maybe<Scalars['String']['output']>;
  replacedById: Maybe<Scalars['Int']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  textId: Maybe<Scalars['Int']['output']>;
  textVersion: Maybe<TextBibleVersion>;
  title: Maybe<Scalars['String']['output']>;
  type: VersionType;
};

export type AudioListItem = {
  __typename?: 'AudioListItem';
  default: Maybe<Scalars['Boolean']['output']>;
  downloadUrls: Maybe<DownloadUrls>;
  dramatized: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  timingAvailable: Maybe<Scalars['Boolean']['output']>;
  title: Maybe<Scalars['String']['output']>;
  versionId: Maybe<Scalars['Int']['output']>;
};

export type AudioUrlsListItem = {
  __typename?: 'AudioUrlsListItem';
  /** Audio bitrate in bps */
  bitrate: Maybe<Scalars['Int']['output']>;
  /** file format */
  format: Maybe<Scalars['String']['output']>;
  /** Audio sample rate in Hz */
  sampleRate: Maybe<Scalars['Int']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type AuthzRolesListItemInput = {
  /** The fully qualified resource name */
  fqrn?: InputMaybe<Scalars['String']['input']>;
  /** The identifier of an Authz Role */
  roleId: Scalars['String']['input'];
};

export type BibleChapter = {
  __typename?: 'BibleChapter';
  response: Maybe<Response2>;
};

export type BibleConfiguration = {
  __typename?: 'BibleConfiguration';
  response: Maybe<Response>;
};

export type BibleLocaleHeader = {
  __typename?: 'BibleLocaleHeader';
  languageTag: Maybe<Scalars['String']['output']>;
  localName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type BibleLocaleHeaders = {
  __typename?: 'BibleLocaleHeaders';
  /** List of bible locale headers */
  locales: Maybe<Array<Maybe<BibleLocaleHeader>>>;
};

export type BibleVerse = {
  __typename?: 'BibleVerse';
  response: Maybe<Response6>;
};

export type BibleVerses = {
  __typename?: 'BibleVerses';
  response: Maybe<Response3>;
};

export type BibleVersion = {
  __typename?: 'BibleVersion';
  response: Maybe<Response5>;
};

export type BibleVersions = {
  __typename?: 'BibleVersions';
  response: Maybe<Response4>;
};

export type Bindings2ListItem = {
  __typename?: 'Bindings2ListItem';
  condition: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  members: Array<Maybe<Scalars['String']['output']>>;
  role: Role3;
};

export type BooksListItem = {
  __typename?: 'BooksListItem';
  abbreviation: Maybe<Scalars['String']['output']>;
  audio: Maybe<Scalars['Boolean']['output']>;
  canon: Maybe<Scalars['String']['output']>;
  chapters: Maybe<Array<Maybe<ChaptersListItem>>>;
  /** Number of search hits for given book. */
  count: Maybe<Scalars['Int']['output']>;
  human: Maybe<Scalars['String']['output']>;
  humanLong: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['Boolean']['output']>;
  usfm: Maybe<Scalars['String']['output']>;
};

export type Build = {
  __typename?: 'Build';
  max: Maybe<Scalars['Int']['output']>;
  min: Maybe<Scalars['Int']['output']>;
};

export type Build2 = {
  __typename?: 'Build2';
  max: Maybe<Scalars['Int']['output']>;
  min: Maybe<Scalars['Int']['output']>;
};

export enum Canon {
  ALL = 'ALL',
  AP = 'AP',
  NT = 'NT',
  OT = 'OT'
}

export type CanonsListItem = {
  __typename?: 'CanonsListItem';
  /** Number of search hits for given canon section. */
  count: Maybe<Scalars['Int']['output']>;
  /** Abbreviation of the canon section represented. Either, AP, NT, or OT. */
  section: Maybe<Section>;
};

export type Challenges = {
  __typename?: 'Challenges';
  /** Array of challenge objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<Data2ListItem>>>;
};

export type Chapters = {
  __typename?: 'Chapters';
  chapters: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ChaptersListItem = {
  __typename?: 'ChaptersListItem';
  canonical: Maybe<Scalars['Boolean']['output']>;
  human: Maybe<Scalars['String']['output']>;
  toc: Maybe<Scalars['Boolean']['output']>;
  usfm: Maybe<Scalars['String']['output']>;
};

export type Check = {
  __typename?: 'Check';
  check: Scalars['Boolean']['output'];
};

export type CheckRequestInput = {
  action: Scalars['String']['input'];
  principal: Scalars['String']['input'];
  resource: Scalars['String']['input'];
  service: Scalars['String']['input'];
};

export type ChildrenListItem = {
  __typename?: 'ChildrenListItem';
  /** Number of awards shown */
  achieveDisplayLimit: Maybe<Scalars['Int']['output']>;
  /** localized text for the award header */
  achieveTitle: Maybe<Scalars['String']['output']>;
  /** Array of children objects sorted by time that the caller can access */
  achievements: Maybe<Array<Maybe<Achievements2ListItem>>>;
  /** The id of the selected avatar. */
  avatarId: Maybe<Scalars['Int']['output']>;
  /** id of selected avatar color */
  colorId: Maybe<Scalars['Int']['output']>;
  /** Short description of the highlighted story */
  highlightStoryDescription: Maybe<Scalars['String']['output']>;
  /** How many times a given story was completed during the week. */
  highlightStoryStatus: Maybe<Scalars['String']['output']>;
  /** tells users if there are more awards to earn */
  moreAchievements: Maybe<Scalars['String']['output']>;
  /** remaining stories to earn an achievement */
  moreStories: Maybe<Scalars['String']['output']>;
  /** inserts name of kid into title header */
  nameTitle: Maybe<Scalars['String']['output']>;
  /** Array of children objects sorted by time that the caller can access */
  stories: Maybe<Array<Maybe<Stories2>>>;
  /** Activity associated with a given story */
  storyActivity: Maybe<Scalars['String']['output']>;
  /** link to the BAFK story */
  storyBafkLink: Maybe<Scalars['String']['output']>;
  /** link to the given bible story */
  storyBibleLink: Maybe<Scalars['String']['output']>;
  storyDiscussion: Maybe<StoryDiscussion>;
  /** Number of stories shown */
  storyDisplayLimit: Maybe<Scalars['Int']['output']>;
  /** localized text for the story highlight */
  storyHighlight: Maybe<Scalars['String']['output']>;
  /** localized text for story highlight */
  storyHighlightTitle: Maybe<Scalars['String']['output']>;
  /** the stories id */
  storyId: Maybe<Scalars['String']['output']>;
  /** Array of children objects sorted by time that the caller can access */
  storyQuestions: Maybe<Array<Maybe<StoryQuestionsListItem>>>;
  /** if story is completed or not */
  storySection: Maybe<Scalars['String']['output']>;
  /** The localized string for the story title */
  storyTitle: Maybe<Scalars['String']['output']>;
};

export type Churches = {
  __typename?: 'Churches';
  /** A list of Church resource objects */
  data: Maybe<Array<Maybe<AdminOrganizationsOrganization>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent>;
};

export type Churches2 = {
  __typename?: 'Churches2';
  /** A list of Church resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<AdminOrganizationsOrganization>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export enum Cohort {
  ADULT = 'ADULT',
  TWEEN = 'TWEEN',
  TWEEN_INDIA = 'TWEEN_INDIA',
  UNKNOWN = 'UNKNOWN',
  _ = '_'
}

export enum CohortsListItem {
  ADULT = 'ADULT',
  TWEEN = 'TWEEN',
  TWEEN_INDIA = 'TWEEN_INDIA'
}

export type Collectibles = {
  __typename?: 'Collectibles';
  /** Array of collectibles objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<Data3ListItem>>>;
};

export type Collection2Input = {
  /** description text */
  description?: InputMaybe<Scalars['String']['input']>;
  /** end */
  end?: InputMaybe<Scalars['Int']['input']>;
  /** collection id */
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  /** start */
  start?: InputMaybe<Scalars['Int']['input']>;
  /** title text */
  title?: InputMaybe<Scalars['String']['input']>;
  videos?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CollectionDeleteRequestInput = {
  language: Scalars['String']['input'];
};

export type CollectionPost = {
  __typename?: 'CollectionPost';
  createdDt: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  end: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  start: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type CollectionPostInput = {
  createdDt: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  language: Scalars['String']['input'];
  start?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type CollectionPresignResponse = {
  __typename?: 'CollectionPresignResponse';
  /** id */
  id: Maybe<Scalars['Int']['output']>;
  /** presign parameters */
  params: Maybe<Params>;
  /** Thumbnail upload URL */
  uploadUrl: Maybe<Scalars['String']['output']>;
};

export type CollectionReserveResponse = {
  __typename?: 'CollectionReserveResponse';
  id: Maybe<Scalars['Int']['output']>;
};

export type Collections = {
  __typename?: 'Collections';
  data: Maybe<Array<Maybe<VideoCollection>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  sizes: Maybe<Sizes>;
  url: Maybe<Scalars['String']['output']>;
};

export type ColorsColor = {
  __typename?: 'ColorsColor';
  cohorts: Maybe<Array<Maybe<CohortsListItem>>>;
  hex: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
};

export type ColorsColors = {
  __typename?: 'ColorsColors';
  data: Maybe<Array<Maybe<ColorsColor>>>;
};

export type Comments2ListItem = {
  __typename?: 'Comments2ListItem';
  comment: Scalars['String']['output'];
  contentId: Scalars['Float']['output'];
};

export type CommentsLastPerDay = {
  __typename?: 'CommentsLastPerDay';
  data: Maybe<Array<Maybe<Data2ListItem>>>;
};

export type CommentsListItemInput = {
  comment: Scalars['String']['input'];
  contentId: Scalars['Float']['input'];
};

export type CompletedReadingPlansPlan = {
  __typename?: 'CompletedReadingPlansPlan';
  response: Maybe<CompletedReadingPlansPlanResponse>;
};

export type CompletedReadingPlansPlanArray = {
  __typename?: 'CompletedReadingPlansPlanArray';
  nextPage: Maybe<Scalars['Float']['output']>;
  readingPlans: Maybe<Array<Maybe<Plan>>>;
  total: Maybe<Scalars['Float']['output']>;
};

export type CompletedReadingPlansPlanResponse = {
  __typename?: 'CompletedReadingPlansPlanResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<CompletedReadingPlansPlanArray>;
};

export type ConfigurationConfiguration = {
  __typename?: 'ConfigurationConfiguration';
  additionalContentAudioUrl: Maybe<Scalars['String']['output']>;
  content: Maybe<Content>;
  images: Maybe<Images>;
  languageTags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxCommentLength: Maybe<Scalars['Int']['output']>;
  maxPageSize: Maybe<Scalars['Int']['output']>;
  maxParticipants: Maybe<Scalars['Int']['output']>;
  updateStartDtTimer: Maybe<Scalars['Int']['output']>;
};

export type Content = {
  __typename?: 'Content';
  brotli: Maybe<Scalars['String']['output']>;
  zlib: Maybe<Scalars['String']['output']>;
};

export type ContentLatestPlaylists = {
  __typename?: 'ContentLatestPlaylists';
  data: Maybe<Array<Maybe<ContentLatestPlaylistsItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type ContentLatestPlaylistsItem = {
  __typename?: 'ContentLatestPlaylistsItem';
  audioBibles: Maybe<Array<AudioBibleData>>;
  /**
   * This is an optional field of plan or video resource ids.
   * The ids in this array are limited to 50.
   * If multiple pages of ids are needed then use
   * /4.0/playlists/{playlist_id}?page=2&page_size=50
   *
   */
  externalIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** The playlist id */
  externalPlaylistId: Maybe<Scalars['Int']['output']>;
  /** The id of the playlist. */
  id: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  mediaType: Maybe<Schema>;
  plans: Maybe<Array<Plan>>;
  /** An enumerator of the different formats or types of media */
  playlistType: Maybe<Schema2>;
  textBibles: Maybe<Array<TextVersion>>;
  /** The descriptor of the playlist returned as a translated string */
  title: Maybe<Scalars['String']['output']>;
  videos: Maybe<Array<Video>>;
};

export type ContentPlaylist = {
  __typename?: 'ContentPlaylist';
  /**
   * This is an optional field of plan or video resource ids.
   * The ids in this array are limited to 50.
   * If multiple pages of ids are needed then use
   * /4.0/playlists/{playlist_id}?page=2&page_size=50
   *
   */
  externalIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** The id of the playlist. */
  id: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  mediaType: Maybe<Schema>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  /** The id of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  playlistType: Maybe<Schema2>;
  /** The descriptor of the playlist returned as a translated string */
  title: Maybe<Scalars['String']['output']>;
};

export type ContentPlaylists = {
  __typename?: 'ContentPlaylists';
  data: Maybe<Array<Maybe<PlaylistItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type ContentRelationships = {
  __typename?: 'ContentRelationships';
  data: Maybe<Array<Maybe<DataListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export enum ContentType {
  PLAN = 'PLAN',
  VIDEO = 'VIDEO'
}

export type Copyright = {
  __typename?: 'Copyright';
  html: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

export type Copyright2 = {
  __typename?: 'Copyright2';
  html: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

export type CopyrightLong = {
  __typename?: 'CopyrightLong';
  /** A long version of the copyright statement that includes html. */
  html: Maybe<Scalars['String']['output']>;
  /** A plain text long version of the copyright statment. */
  text: Maybe<Scalars['String']['output']>;
};

export type CopyrightShort = {
  __typename?: 'CopyrightShort';
  /** A short version of the copyright statement that includes html. */
  html: Maybe<Scalars['String']['output']>;
  /** A plain text short version of the copyright statment. */
  text: Maybe<Scalars['String']['output']>;
};

export type Country = {
  __typename?: 'Country';
  /** Represents the long version of the largest non-global official government entity. */
  longName: Scalars['String']['output'];
  /** Represents the short version of the largest non-global official government entity. */
  shortName: Scalars['String']['output'];
};

export type CountryInput = {
  /** Represents the long version of the largest non-global official government entity. */
  longName: Scalars['String']['input'];
  /** Represents the short version of the largest non-global official government entity. */
  shortName: Scalars['String']['input'];
};

export enum CreatedMethod {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

export enum CreatedMethod2 {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

export type CrowdinEventsTranslationUpdatedEventInput = {
  event: Scalars['String']['input'];
  file: Scalars['String']['input'];
  fileId: Scalars['Int']['input'];
  language: Scalars['String']['input'];
  newTranslationId?: InputMaybe<Scalars['Int']['input']>;
  oldTranslationId?: InputMaybe<Scalars['Int']['input']>;
  project: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  sourceStringId: Scalars['Int']['input'];
  stepName?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type CrowdinKeyCrowdinKey = {
  __typename?: 'CrowdinKeyCrowdinKey';
  key: Maybe<Scalars['String']['output']>;
};

export type Currencies = {
  __typename?: 'Currencies';
  data: Maybe<Array<Maybe<Currency>>>;
};

export type Currency = {
  __typename?: 'Currency';
  /** Short code for Currency */
  code: Maybe<Scalars['String']['output']>;
  /** A character that marks the beginning or end of a unit of data. */
  delimiter: Maybe<Scalars['String']['output']>;
  /** Format for the currency value. */
  format: Maybe<Scalars['String']['output']>;
  /** Unique id for a Currency */
  id: Maybe<Scalars['Int']['output']>;
  /** Name of the Currency */
  name: Maybe<Scalars['String']['output']>;
  /** Numeric representation of decimal places a value contains. */
  precision: Maybe<Scalars['Int']['output']>;
  /** A character that separates two items. */
  separator: Maybe<Scalars['String']['output']>;
  /** Symbol that represents the currency. */
  symbol: Maybe<Scalars['String']['output']>;
};

export type Data = {
  __typename?: 'Data';
  defaultVersions: Maybe<Array<Maybe<DefaultVersionsListItem>>>;
  shortUrl: Maybe<Scalars['String']['output']>;
  stylesheets: Maybe<Stylesheets>;
  totals: Maybe<Totals>;
};

export type Data2 = {
  __typename?: 'Data2';
  audio: Maybe<Array<Maybe<AudioListItem>>>;
  content: Maybe<Scalars['String']['output']>;
  copyright: Maybe<Copyright>;
  next: Maybe<Next>;
  previous: Maybe<Scalars['String']['output']>;
  reference: Maybe<Reference>;
};

export type Data2ListItem = {
  __typename?: 'Data2ListItem';
  /** Array of challenge requirement objects sorted by time that the caller can access */
  completeReq: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdDt: Maybe<Scalars['String']['output']>;
  day: Maybe<Scalars['Int']['output']>;
  /** Text describing the achievement. */
  description: Maybe<Scalars['String']['output']>;
  /** This is the ID of the event. */
  eventId: Scalars['Float']['output'];
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  /** This is the title of the event. */
  title: Scalars['String']['output'];
};

export type Data3 = {
  __typename?: 'Data3';
  copyright: Maybe<Copyright>;
  id: Maybe<Scalars['Int']['output']>;
  iso6391: Maybe<Scalars['String']['output']>;
  localAbbreviation: Maybe<Scalars['String']['output']>;
  localTitle: Maybe<Scalars['String']['output']>;
  nextVerse: Maybe<Scalars['String']['output']>;
  previousVerse: Maybe<Scalars['String']['output']>;
  verses: Maybe<Array<Maybe<VersesListItem>>>;
};

export type Data3ListItem = {
  __typename?: 'Data3ListItem';
  /** Text describing the collectible. */
  description: Maybe<Scalars['String']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  permissions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resource: Scalars['String']['output'];
};

export type Data4 = {
  __typename?: 'Data4';
  totals: Maybe<Totals2>;
  versions: Maybe<Array<Maybe<Versions3ListItem>>>;
};

export type Data4ListItem = {
  __typename?: 'Data4ListItem';
  /** Iso formatted date and time in UTC when query was issued */
  createdDt: Maybe<Scalars['String']['output']>;
  /** Kind of historical search */
  kind: Maybe<Kind>;
  /** This is no longer used. It might be used again in a future version, but currently does nothing. */
  query: Maybe<Scalars['String']['output']>;
};

export type Data5ListItem = {
  __typename?: 'Data5ListItem';
  id: Scalars['Int']['output'];
};

export type Data5ListItemInput = {
  id: Scalars['Int']['input'];
};

export type Data6 = {
  __typename?: 'Data6';
  content: Maybe<Scalars['String']['output']>;
  copyright: Maybe<Copyright2>;
  reference: Maybe<Reference3>;
};

export type Data6ListItem = {
  __typename?: 'Data6ListItem';
  /** A language in iso639-1 format. */
  id: Maybe<Scalars['String']['output']>;
};

export type Data7ListItem = {
  __typename?: 'Data7ListItem';
  countryCode: Maybe<Scalars['String']['output']>;
  /** Localized string to describe a given interaction taken due to the impact of the user's giving. Double asterisks mark bolded portions of the string. */
  description: Maybe<Scalars['String']['output']>;
  /** Partner's organization id */
  id: Maybe<Scalars['String']['output']>;
  /** Array of kids collectible objects sorted by time that the caller can access */
  items: Maybe<Array<Maybe<ItemsListItem>>>;
  type: Maybe<Type>;
};

export type Data8ListItem = {
  __typename?: 'Data8ListItem';
  /** description of the story */
  description: Maybe<Scalars['String']['output']>;
  /** story id */
  id: Maybe<Scalars['String']['output']>;
  /** This is the plan id. */
  planId: Maybe<Scalars['Int']['output']>;
};

export type Data9ListItem = {
  __typename?: 'Data9ListItem';
  /** This is the day of the week services happen on. This is an integer because start of week takes days into integers. */
  dayOfWeek: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** This is the time of day that services happen on. */
  timeOfDay: Maybe<Scalars['String']['output']>;
};

export type Data10ListItem = {
  __typename?: 'Data10ListItem';
  friend: Maybe<Scalars['Boolean']['output']>;
  /** Unique identifier (user_id) */
  userId: Maybe<Scalars['Float']['output']>;
};

export type Data11ListItem = {
  __typename?: 'Data11ListItem';
  /** Link / deeplink to feature */
  link: Scalars['String']['output'];
  /** Name of feature */
  name: Scalars['String']['output'];
};

export type Data12ListItem = {
  __typename?: 'Data12ListItem';
  /** The id of the content grant. */
  contentGrantId: Maybe<Scalars['String']['output']>;
  /** The id of the organization. */
  id: Maybe<Scalars['String']['output']>;
  /** This is the name of the organization. */
  name: Maybe<Scalars['String']['output']>;
};

export type Data15ListItem = {
  __typename?: 'Data15ListItem';
  /** Link to article */
  link: Scalars['String']['output'];
  /** Support article title */
  name: Scalars['String']['output'];
};

export type Data19ListItem = {
  __typename?: 'Data19ListItem';
  /** This is a few sentence description about the string to translate. It is helpful for the translators to understand what they are translating. */
  context: Maybe<Scalars['String']['output']>;
  /** Unique/primary key */
  id: Maybe<Scalars['String']['output']>;
  /** This is a boolean value indicating whether plural forms should be used in the translation. */
  pluralForms: Maybe<Scalars['Boolean']['output']>;
  /** This is the unique key for the string in the remote translation tool (Crowdin, etc). */
  remoteStringId: Maybe<Scalars['String']['output']>;
  /** English text to translate */
  source: Maybe<Scalars['String']['output']>;
  /** This is a boolean value indicating whether the string should be uploaded to the remote translation tool for tranlation or not. If "false", the string will not be translated. Defaults to "true". */
  translate: Maybe<Scalars['Boolean']['output']>;
};

export type Data22ListItem = {
  __typename?: 'Data22ListItem';
  /** verse usfm */
  usfm: Maybe<Scalars['String']['output']>;
};

export type DataListItem = {
  __typename?: 'DataListItem';
  /** Text describing the achievement. */
  description: Maybe<Scalars['String']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  isLocalizationLanguage: Maybe<Scalars['Boolean']['output']>;
  isSourceLanguage: Maybe<Scalars['Boolean']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  /** The id of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** An enumerator that describes the different types of relationships an organization can have. */
  relationshipType: Maybe<RelationshipType>;
};

export type DefaultVersionsListItem = {
  __typename?: 'DefaultVersionsListItem';
  font: Maybe<Scalars['String']['output']>;
  hasAudio: Maybe<Scalars['Boolean']['output']>;
  hasText: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  iso6391: Maybe<Scalars['String']['output']>;
  iso6393: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  localName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  textDirection: Maybe<Scalars['String']['output']>;
  totalVersions: Maybe<Scalars['Int']['output']>;
};

export enum DevotionalType {
  BOOK = 'BOOK',
  DEVOTION = 'DEVOTION',
  SCRIPTURE = 'SCRIPTURE',
  SERMON = 'SERMON',
  TEACHING_CLIP = 'TEACHING_CLIP'
}

export type DonationRecord = {
  __typename?: 'DonationRecord';
  /** Name of giver */
  attributedTo: Maybe<Scalars['String']['output']>;
  /** Three-character ISO code for the currency being used */
  currency: Maybe<Scalars['String']['output']>;
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId: Maybe<FrequencyId>;
  /** Unique ID of Fund */
  fundId: Maybe<Scalars['Int']['output']>;
  /** ID of donation in Rock */
  id: Maybe<Scalars['Int']['output']>;
  /** Localized gift amount */
  paymentAmount: Maybe<Scalars['String']['output']>;
  /** The date/time of the donation */
  paymentDate: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<PaymentMethod>;
};

export type DonationRecords = {
  __typename?: 'DonationRecords';
  data: Maybe<Array<Maybe<DonationRecord>>>;
  /** Localized total of all donation amounts */
  total: Maybe<Scalars['String']['output']>;
  /** Year in which these donations occurred */
  year: Maybe<Scalars['Int']['output']>;
};

export type DownloadUrls = {
  __typename?: 'DownloadUrls';
  formatHls: Maybe<Scalars['String']['output']>;
  formatMp332k: Maybe<Scalars['String']['output']>;
};

export type Elevation = {
  __typename?: 'Elevation';
  /** List of Church ids to elevate for query */
  churchIds: Array<Maybe<Scalars['String']['output']>>;
  /** Context for translators that is posted in crowdin */
  context: Scalars['String']['output'];
  /** Primary key identifier */
  id: Maybe<Scalars['Int']['output']>;
  /** List of Plan ids to elevate for query */
  planIds: Array<Maybe<Scalars['Int']['output']>>;
  /** The English search query to elevate results for. */
  query: Scalars['String']['output'];
  /** Boolean indicating whether the string corresponding with the elevation should be translated */
  translate: Scalars['Boolean']['output'];
  /** The date the elevation was last updated */
  updatedDt: Maybe<Scalars['String']['output']>;
  /** List of USFM references to elevate for query */
  usfms: Array<Maybe<Scalars['String']['output']>>;
  /** List of Video ids to elevate for query */
  videoIds: Array<Maybe<Scalars['Int']['output']>>;
};

export type ElevationInput = {
  /** List of Church ids to elevate for query */
  churchIds: Array<InputMaybe<Scalars['String']['input']>>;
  /** Context for translators that is posted in crowdin */
  context: Scalars['String']['input'];
  /** Primary key identifier */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** List of Plan ids to elevate for query */
  planIds: Array<InputMaybe<Scalars['Int']['input']>>;
  /** The English search query to elevate results for. */
  query: Scalars['String']['input'];
  /** Boolean indicating whether the string corresponding with the elevation should be translated */
  translate: Scalars['Boolean']['input'];
  /** The date the elevation was last updated */
  updatedDt?: InputMaybe<Scalars['String']['input']>;
  /** List of USFM references to elevate for query */
  usfms: Array<InputMaybe<Scalars['String']['input']>>;
  /** List of Video ids to elevate for query */
  videoIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type Elevations = {
  __typename?: 'Elevations';
  data: Maybe<Array<Maybe<Elevation>>>;
};

export type EventTimeInput = {
  /** This is the duration of the event in minutes */
  duration: Scalars['Int']['input'];
  /** The location information for the event. */
  locations: Array<InputMaybe<Scalars['String']['input']>>;
  /** This is the logical start time of the event 24 hour time format. */
  logicalStartTime: Scalars['String']['input'];
  /** Start date of the event. */
  startDate: Scalars['String']['input'];
};

export type EventsEvent = {
  __typename?: 'EventsEvent';
  content: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  description: Maybe<Scalars['String']['output']>;
  /** This is the ID of the event. */
  id: Maybe<Scalars['Float']['output']>;
  image: Maybe<Image2>;
  /** This is the ID of the organization. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** This is a flag that indicates if the event has been saved or not. */
  saved: Maybe<Scalars['Boolean']['output']>;
  times: Maybe<Array<Maybe<Time>>>;
  /** This is the title of the event. */
  title: Maybe<Scalars['String']['output']>;
};

export type EventsEvents = {
  __typename?: 'EventsEvents';
  data: Maybe<Array<Maybe<EventsEvent>>>;
};

export type ExpandRequestBatchInput = {
  principal: Scalars['String']['input'];
  resources: Array<InputMaybe<Scalars['String']['input']>>;
};

export type ExpandRequestInput = {
  principal: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};

export type ExternalIds2ListItemInput = {
  /** The identifier for an external organization */
  id: Scalars['Int']['input'];
  /** What kind of id is this? */
  type: Type3;
};

export type Feature = {
  __typename?: 'Feature';
  /** Primary key identifier of feature */
  id: Maybe<Scalars['Int']['output']>;
  /** Link / deeplink to feature */
  link: Scalars['String']['output'];
  /** Name of feature */
  name: Scalars['String']['output'];
  /** This tells the API what the original source of the features was. Currently should be either "admin" or "spec". */
  source: Maybe<Source>;
  /** Boolean indicating whether the string corresponding with the feature should be translated */
  translate: Maybe<Scalars['Boolean']['output']>;
  /** ISO 639 date & time feature name or link was last updated */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type FeatureInput = {
  /** Primary key identifier of feature */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Link / deeplink to feature */
  link: Scalars['String']['input'];
  /** Name of feature */
  name: Scalars['String']['input'];
  /** This tells the API what the original source of the features was. Currently should be either "admin" or "spec". */
  source?: InputMaybe<Source>;
  /** Boolean indicating whether the string corresponding with the feature should be translated */
  translate?: InputMaybe<Scalars['Boolean']['input']>;
  /** ISO 639 date & time feature name or link was last updated */
  updatedDt?: InputMaybe<Scalars['String']['input']>;
};

export type Features = {
  __typename?: 'Features';
  data: Maybe<Array<Maybe<Feature>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
};

export type Features2 = {
  __typename?: 'Features2';
  /** A list of Feature resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Data11ListItem>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type Filters = {
  __typename?: 'Filters';
  /** This is a list of book filter objects with the USFM book name & hit count. Ordered by count descending. */
  books: Maybe<Array<Maybe<BooksListItem>>>;
  /** This is a list of canon filter objects with the canon name & hit count. Ordered by count descending. */
  canons: Maybe<Array<Maybe<CanonsListItem>>>;
};

export type FollowingFollowing = {
  __typename?: 'FollowingFollowing';
  /** This is the id of the organization followed */
  organizationId: Scalars['String']['output'];
};

export type FollowingFollowingInput = {
  /** This is the id of the organization followed */
  organizationId: Scalars['String']['input'];
};

export enum Format {
  HTML = 'HTML',
  TEXT = 'TEXT'
}

export type Frequencies = {
  __typename?: 'Frequencies';
  data: Maybe<Array<Maybe<Frequency>>>;
};

export type Frequency = {
  __typename?: 'Frequency';
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  id: Maybe<FrequencyId>;
  /** Localized name for the Frequency */
  name: Maybe<Scalars['String']['output']>;
};

export enum FrequencyId {
  EVERY_2_WEEKS = 'EVERY_2_WEEKS',
  MONTHLY = 'MONTHLY',
  ONETIME = 'ONETIME',
  TWICE_MONTHLY = 'TWICE_MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type FriendsLimit = {
  __typename?: 'FriendsLimit';
  /** The amount of friends someone is allowed to have. */
  friendsLimit: Scalars['Int']['output'];
};

export type FriendsLimitInput = {
  /** The amount of friends someone is allowed to have. */
  friendsLimit: Scalars['Int']['input'];
};

export type FriendshipsIncoming = {
  __typename?: 'FriendshipsIncoming';
  response: Maybe<FriendshipsIncomingResponse>;
};

export type FriendshipsIncomingData = {
  __typename?: 'FriendshipsIncomingData';
  nextPage: Maybe<Scalars['Boolean']['output']>;
  users: Maybe<Array<Maybe<FriendshipsIncomingUsersUserData>>>;
};

export type FriendshipsIncomingResponse = {
  __typename?: 'FriendshipsIncomingResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<FriendshipsIncomingData>;
};

export type FriendshipsIncomingUsersAvatar = {
  __typename?: 'FriendshipsIncomingUsersAvatar';
  /** Link to the user's page */
  actionUrl: Maybe<Scalars['String']['output']>;
  renditions: Maybe<Array<Maybe<FriendshipsIncomingUsersAvatarRendition>>>;
  /** Style of the avatar image */
  style: Maybe<Scalars['String']['output']>;
};

export type FriendshipsIncomingUsersAvatarRendition = {
  __typename?: 'FriendshipsIncomingUsersAvatarRendition';
  /** Height in pixels of the image */
  height: Maybe<Scalars['Int']['output']>;
  /** URL for the image */
  url: Maybe<Scalars['String']['output']>;
  /** Width in pixels of the image */
  width: Maybe<Scalars['Int']['output']>;
};

export type FriendshipsIncomingUsersUserData = {
  __typename?: 'FriendshipsIncomingUsersUserData';
  avatar: Maybe<FriendshipsIncomingUsersAvatar>;
  /** Unique identifier (user_id) */
  id: Maybe<Scalars['Int']['output']>;
  /** The name of a given user. */
  name: Maybe<Scalars['String']['output']>;
  /** The username of a given user. */
  username: Maybe<Scalars['String']['output']>;
};

export type FriendshipsInvite = {
  __typename?: 'FriendshipsInvite';
  response: Maybe<FriendshipsInviteResponse>;
};

export type FriendshipsInviteData = {
  __typename?: 'FriendshipsInviteData';
  incoming: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  outgoing: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type FriendshipsInviteRequestInput = {
  userId: Scalars['Int']['input'];
};

export type FriendshipsInviteResponse = {
  __typename?: 'FriendshipsInviteResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<FriendshipsInviteData>;
};

export type Fund = {
  __typename?: 'Fund';
  /** Unique id for a Fund */
  id: Maybe<Scalars['Int']['output']>;
  /** Name of the Fund */
  name: Maybe<Scalars['String']['output']>;
};

export type Funds = {
  __typename?: 'Funds';
  data: Maybe<Array<Maybe<Fund>>>;
};

export type GivingConfiguration = {
  __typename?: 'GivingConfiguration';
  stripe: Maybe<Stripe>;
};

export type GivingStatement = {
  __typename?: 'GivingStatement';
  /** url where the pdf giving document lives */
  downloadUrl: Maybe<Scalars['String']['output']>;
};

export type GlobalRole = {
  __typename?: 'GlobalRole';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Histories = {
  __typename?: 'Histories';
  data: Maybe<Array<Maybe<Data4ListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type Html = {
  __typename?: 'Html';
  default: Maybe<Scalars['String']['output']>;
};

export enum HubspotPartnerType {
  AUTHOR = 'AUTHOR',
  BIBLE_PUBLISHER = 'BIBLE_PUBLISHER',
  BIBLE_SOCIETY = 'BIBLE_SOCIETY',
  CHURCH = 'CHURCH',
  FILM_STUDIO = 'FILM_STUDIO',
  INFLUENCING_VOICE = 'INFLUENCING_VOICE',
  MUSIC_ARTIST = 'MUSIC_ARTIST',
  ORGANIZATION = 'ORGANIZATION',
  PASTOR = 'PASTOR',
  PUBLISHER = 'PUBLISHER',
  RECORD_LABEL = 'RECORD_LABEL',
  SOCIAL_MEDIA_INFLUENCER = 'SOCIAL_MEDIA_INFLUENCER'
}

export type I18ns3ListItem = {
  __typename?: 'I18ns3ListItem';
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['output'];
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['output'];
};

export type I18ns3ListItemInput = {
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['input'];
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['input'];
};

export type I18ns4ListItem = {
  __typename?: 'I18ns4ListItem';
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['output'];
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['output'];
  /** This is the name of the organization. */
  name: Maybe<Scalars['String']['output']>;
  /** The localized url of the organization website */
  url: Maybe<Scalars['String']['output']>;
};

export type I18ns4ListItemInput = {
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['input'];
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['input'];
  /** This is the name of the organization. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The localized url of the organization website */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type I18nsListItem = {
  __typename?: 'I18nsListItem';
  /** The iso 639 language tag for the internationalization of the video playlist. */
  languageTag: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  /** How tall is this image? */
  height: Maybe<Scalars['Int']['output']>;
  /** image id */
  id: Maybe<Scalars['Int']['output']>;
  /** The image proxy pre-pended url to the image from the relevant GCS bucket for the asset. */
  url: Maybe<Scalars['String']['output']>;
  /** USFMs related to the image */
  usfms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** How wide is this image? */
  width: Maybe<Scalars['Int']['output']>;
};

export type Image2 = {
  __typename?: 'Image2';
  /** How tall is this image? */
  height: Maybe<Scalars['Int']['output']>;
  /** The image proxy pre-pended url to the image from the relevant GCS bucket for the asset. */
  url: Maybe<Scalars['String']['output']>;
  /** How wide is this image? */
  width: Maybe<Scalars['Int']['output']>;
};

export enum ImageType {
  BANNER = 'BANNER',
  LOGO = 'LOGO',
  ORGANIZATION = 'ORGANIZATION',
  PASTOR = 'PASTOR'
}

export type ImageUrls = {
  __typename?: 'ImageUrls';
  collection: Maybe<Scalars['String']['output']>;
  publisherBanner: Maybe<Scalars['String']['output']>;
  publisherLogo: Maybe<Scalars['String']['output']>;
  video: Maybe<Scalars['String']['output']>;
  videoBlur: Maybe<Scalars['String']['output']>;
};

export type Images = {
  __typename?: 'Images';
  collections: Maybe<Collections>;
  /** A list of Image resource objects with related USFMs from the unified results endpoint */
  data: Maybe<Array<Maybe<Items>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  plans: Maybe<Plans>;
};

export type ImagesEventsPresignedImageResponse = {
  __typename?: 'ImagesEventsPresignedImageResponse';
  /** How tall is this image? */
  height: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  /** Presigned GCS Image upload URL */
  uri: Maybe<Scalars['String']['output']>;
  /** How wide is this image? */
  width: Maybe<Scalars['Int']['output']>;
};

export type ImagesImage = {
  __typename?: 'ImagesImage';
  height: Scalars['Int']['output'];
  url: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

export type ImagesImageInput = {
  height: Scalars['Int']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Int']['input'];
};

export type ImagesImagePropertiesEventsInput = {
  /** How tall is this image? */
  height: Scalars['Int']['input'];
  /** How wide is this image? */
  width: Scalars['Int']['input'];
};

export type ImagesImagePropertiesInput = {
  /** How tall is this image? */
  height: Scalars['Int']['input'];
  /** What is the MIME type of this image? */
  mimeType: Scalars['String']['input'];
  /** How wide is this image? */
  width: Scalars['Int']['input'];
};

export type ImagesOrganizationImage = {
  __typename?: 'ImagesOrganizationImage';
  /** How tall is this image? */
  height: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** The image proxy pre-pended url to the image from the relevant GCS bucket for the asset. */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** How wide is this image? */
  width: Maybe<Scalars['Int']['output']>;
};

export type ImagesPresignResponse = {
  __typename?: 'ImagesPresignResponse';
  /** id */
  id: Maybe<Scalars['Int']['output']>;
  /** gcs presign parameters */
  params: Maybe<Params>;
  /** Image upload URL */
  uploadUrl: Maybe<Scalars['String']['output']>;
};

export type ImagesPresignedImageResponse = {
  __typename?: 'ImagesPresignedImageResponse';
  /** How tall is this image? */
  height: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** Presigned GCS Image upload URL */
  uri: Maybe<Scalars['String']['output']>;
  /** How wide is this image? */
  width: Maybe<Scalars['Int']['output']>;
};

export type Info = {
  __typename?: 'Info';
  /** localized text for Activity Idea */
  activityIdea: Maybe<Scalars['String']['output']>;
  /** link to more resources */
  activityResources: Maybe<Scalars['String']['output']>;
  /** alt text for child's avatar */
  avatar: Maybe<Scalars['String']['output']>;
  /** Alt text for BAFK App image */
  bafk: Maybe<Scalars['String']['output']>;
  /** Alt text for BAFK icon */
  bafkIcon: Maybe<Scalars['String']['output']>;
  /** Alt text for BAFK logo image */
  bafkLogo: Maybe<Scalars['String']['output']>;
  /** Alt text for Bible App Image */
  bibleApp: Maybe<Scalars['String']['output']>;
  /** Alt text for the Bible App logo image */
  bibleAppLogo: Maybe<Scalars['String']['output']>;
  /** Alt text for bible lens image */
  bibleLens: Maybe<Scalars['String']['output']>;
  /** Alt text for BAFK blog. */
  blog: Maybe<Scalars['String']['output']>;
  /** The name of the child. */
  childrenTitle: Maybe<Scalars['String']['output']>;
  /** The conclusion of the email */
  conclusion: Maybe<Scalars['String']['output']>;
  /** link to more stories */
  continueReading: Maybe<Scalars['String']['output']>;
  /** link to donate */
  donate: Maybe<Scalars['String']['output']>;
  /** localized text for the weekly wrap up. */
  mainHeading: Maybe<Scalars['String']['output']>;
  /** snippet of text that lets the parent know what the email contains. */
  previewText: Maybe<Scalars['String']['output']>;
  /** localized link pointing to BAFK privacy policy. */
  privacy: Maybe<Scalars['String']['output']>;
  /** question prompt */
  questionsTitle: Maybe<Scalars['String']['output']>;
  /** Alt text for the star0 image */
  star0: Maybe<Scalars['String']['output']>;
  /** Alt text for 1 of 3 stars image */
  star1: Maybe<Scalars['String']['output']>;
  /** Alt text for two start image */
  star2: Maybe<Scalars['String']['output']>;
  /** Alt text for 3 of 3 stars image. */
  star3: Maybe<Scalars['String']['output']>;
  /** subject line of email. */
  subject: Maybe<Scalars['String']['output']>;
  /** localized text telling the user why they are receiving this email. */
  subscription: Maybe<Scalars['String']['output']>;
  /** support link */
  support: Maybe<Scalars['String']['output']>;
  /** terms for using BAFK */
  terms: Maybe<Scalars['String']['output']>;
  /** unsubscribe link. */
  unsubscribe: Maybe<Scalars['String']['output']>;
  /** link to update notification settings */
  updateNotifications: Maybe<Scalars['String']['output']>;
  /** The week of activity recorded */
  week: Maybe<Scalars['String']['output']>;
};

export type Interactions = {
  __typename?: 'Interactions';
  data: Maybe<Array<Maybe<Data7ListItem>>>;
};

export type Items = {
  __typename?: 'Items';
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['output'];
  /** image id */
  id: Maybe<Scalars['Int']['output']>;
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['output'];
  /** This is the name of the organization. */
  name: Maybe<Scalars['String']['output']>;
  /** The localized url of the organization website */
  url: Maybe<Scalars['String']['output']>;
  /** USFMs related to the image */
  usfms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Items2 = {
  __typename?: 'Items2';
  /** Partner's organization id */
  id: Maybe<Scalars['String']['output']>;
  /** What kind of id is this? */
  type: Type3;
};

export type Items2Input = {
  /** The identifier for an external organization */
  id: Scalars['Int']['input'];
  /** What kind of id is this? */
  type: Type3;
};

export type Items3 = {
  __typename?: 'Items3';
  /** plan id */
  id: Maybe<Scalars['Int']['output']>;
};

export type Items4 = {
  __typename?: 'Items4';
  /** Podcast id */
  id: Maybe<Scalars['Int']['output']>;
  /** What kind of podcast object is this? ie episode or show */
  kind: Maybe<Kind2>;
};

export type Items5 = {
  __typename?: 'Items5';
  /** verse usfm */
  usfm: Maybe<Scalars['String']['output']>;
};

export type ItemsInput = {
  /** This is the description of the organization and the target part of the world the organization is reaching using this language and possibly a specific url. */
  description: Scalars['String']['input'];
  /** The iso 639 language tag for the internationalization of the organization. */
  languageTag: Scalars['String']['input'];
  /** This is the name of the organization. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The localized url of the organization website */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ItemsListItem = {
  __typename?: 'ItemsListItem';
  /** The count of a particular collectible earned for this set. */
  count: Maybe<Scalars['Int']['output']>;
  /** Unique identifier */
  item: Maybe<Scalars['String']['output']>;
};

export type Kid = {
  __typename?: 'Kid';
  /** The id of the selected avatar. */
  avatarId: Maybe<Scalars['Int']['output']>;
  /** The id of the selected color. */
  colorId: Maybe<Scalars['Int']['output']>;
  /** This is the date when the kid was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['Int']['output']>;
  /** Name of the child. */
  name: Maybe<Scalars['String']['output']>;
};

export type KidCollectibleSet = {
  __typename?: 'KidCollectibleSet';
  /** Array of kids collectible sets objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<Data7ListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type KidInput = {
  /** The id of the selected avatar. */
  avatarId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the selected color. */
  colorId?: InputMaybe<Scalars['Int']['input']>;
  /** This is the date when the kid was created. */
  createdDt?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Name of the child. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum KidSubscriptionType {
  DIGEST = 'DIGEST',
  NEWSLETTER = 'NEWSLETTER'
}

export type KidUserSettingsUnsubscribeInput = {
  updatedDt?: InputMaybe<Scalars['String']['input']>;
};

export type Kids = {
  __typename?: 'Kids';
  /** Array of kid objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<Kid>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type KidsAchievement = {
  __typename?: 'KidsAchievement';
  /** number of times the achievement has been earned */
  count: Maybe<Scalars['Int']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  /** The current level completed */
  levelsComplete: Maybe<Scalars['Int']['output']>;
};

export type KidsAchievementInput = {
  /** number of times the achievement has been earned */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** Unique identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The current level completed */
  levelsComplete?: InputMaybe<Scalars['Int']['input']>;
};

export type KidsAchievements = {
  __typename?: 'KidsAchievements';
  /** Array of kids achievement objects sorted by achievement_id or descending that the caller can access */
  data: Maybe<Array<Maybe<KidsAchievement>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type KidsChallenge = {
  __typename?: 'KidsChallenge';
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  /** Array of requirements objects sorted by time that the caller can access */
  reqsComplete: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** state of the current challenge. */
  state: Maybe<Scalars['String']['output']>;
  /** The story the challenge is associated with. */
  storyId: Maybe<Scalars['String']['output']>;
};

export type KidsChallengeGet = {
  __typename?: 'KidsChallengeGet';
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  /** Array of requirements objects sorted by time that the caller can access */
  reqsComplete: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** state of the current challenge. */
  state: Maybe<Scalars['String']['output']>;
};

export type KidsChallengeInput = {
  /** Unique identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Array of requirements objects sorted by time that the caller can access */
  reqsComplete?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** state of the current challenge. */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The story the challenge is associated with. */
  storyId?: InputMaybe<Scalars['String']['input']>;
};

export type KidsChallenges = {
  __typename?: 'KidsChallenges';
  /** Array of kids challenges objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<KidsChallengeGet>>>;
};

export type KidsCollectibles = {
  __typename?: 'KidsCollectibles';
  /** The count of collectibles earned for this story. */
  count: Maybe<Scalars['Int']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
  /** The story the set is associated with. */
  storyId: Maybe<Scalars['String']['output']>;
};

export type KidsCollectiblesInput = {
  /** The count of collectibles earned for this story. */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** Unique identifier */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The story the set is associated with. */
  storyId?: InputMaybe<Scalars['String']['input']>;
};

export type KidsDigest = {
  __typename?: 'KidsDigest';
  /** Array of children objects sorted by time that the caller can access */
  children: Maybe<Array<Maybe<ChildrenListItem>>>;
  info: Maybe<Info>;
  parentUnsubscribed: Maybe<Scalars['Boolean']['output']>;
};

export type KidsQuestion = {
  __typename?: 'KidsQuestion';
  /** The count of how many times a given question has been answered. */
  count: Maybe<Scalars['Int']['output']>;
  /** Unique identifier */
  id: Maybe<Scalars['String']['output']>;
};

export type KidsQuestionInput = {
  /** The count of how many times a given question has been answered. */
  count?: InputMaybe<Scalars['Int']['input']>;
  /** Unique identifier */
  id?: InputMaybe<Scalars['String']['input']>;
};

export type KidsQuestions = {
  __typename?: 'KidsQuestions';
  /** Array of question objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<KidsQuestion>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type KidsStories = {
  __typename?: 'KidsStories';
  /** Array of collectibles objects sorted by time that the caller can access */
  data: Maybe<Array<Maybe<KidsStoryGet>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type KidsStory = {
  __typename?: 'KidsStory';
  /** determains if a story was read or downloaded */
  action: Maybe<Scalars['String']['output']>;
  /** how many collectibles have been earned for this story */
  collectiblesCount: Maybe<Scalars['Int']['output']>;
  /** The story id. */
  id: Maybe<Scalars['String']['output']>;
  /** indicates if the story has been downloaded or not */
  isDownloaded: Maybe<Scalars['Boolean']['output']>;
  /** The id of the selected avatar. */
  readCount: Maybe<Scalars['Int']['output']>;
};

export type KidsStoryGet = {
  __typename?: 'KidsStoryGet';
  /** how many collectibles have been earned for this story */
  collectiblesCount: Maybe<Scalars['Int']['output']>;
  /** The story id. */
  id: Maybe<Scalars['String']['output']>;
  /** indicates if the story has been downloaded or not */
  isDownloaded: Maybe<Scalars['Boolean']['output']>;
  /** The id of the selected avatar. */
  readCount: Maybe<Scalars['Int']['output']>;
};

export type KidsStoryInput = {
  /** determains if a story was read or downloaded */
  action?: InputMaybe<Scalars['String']['input']>;
  /** how many collectibles have been earned for this story */
  collectiblesCount?: InputMaybe<Scalars['Int']['input']>;
  /** The story id. */
  id?: InputMaybe<Scalars['String']['input']>;
  /** indicates if the story has been downloaded or not */
  isDownloaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the selected avatar. */
  readCount?: InputMaybe<Scalars['Int']['input']>;
};

export enum Kind {
  ALL = 'ALL',
  BOOKMARK = 'BOOKMARK',
  COMMENT = 'COMMENT',
  COMPLETE = 'COMPLETE',
  EVENTS = 'EVENTS',
  FEATURES = 'FEATURES',
  HIGHLIGHT = 'HIGHLIGHT',
  IMAGE = 'IMAGE',
  LIKE = 'LIKE',
  NOTE = 'NOTE',
  PLANS = 'PLANS',
  SUPPORT_ARTICLES = 'SUPPORT_ARTICLES',
  TOPICS = 'TOPICS',
  UNIFIED = 'UNIFIED',
  USERS = 'USERS',
  VERSES = 'VERSES',
  VIDEOS = 'VIDEOS'
}

export enum Kind2 {
  ACCEPT_NOTIFICATIONS = 'ACCEPT_NOTIFICATIONS',
  COMMENT_NOTIFICATIONS = 'COMMENT_NOTIFICATIONS',
  EPISODE = 'EPISODE',
  SHOW = 'SHOW'
}

export enum Kind3 {
  ALL = 'ALL',
  CHURCHES = 'CHURCHES',
  EVENTS = 'EVENTS',
  FEATURES = 'FEATURES',
  IMAGES = 'IMAGES',
  PARTNERS = 'PARTNERS',
  PLANS = 'PLANS',
  PODCASTS = 'PODCASTS',
  SUPPORT_ARTICLES = 'SUPPORT_ARTICLES',
  TOPICS = 'TOPICS',
  USERS = 'USERS',
  VERSES = 'VERSES',
  VIDEOS = 'VIDEOS'
}

export type Language = {
  __typename?: 'Language';
  /** Primary identifier */
  id: Maybe<Scalars['Int']['output']>;
  iso6391: Maybe<Scalars['String']['output']>;
  iso6393: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  /** Latest language version the snippets have been tested with. */
  latestVersion: Maybe<Scalars['String']['output']>;
  localName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  snippets: Maybe<Snippets>;
  textDirection: Maybe<Scalars['String']['output']>;
};


export type LanguageSnippetsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Language2 = {
  __typename?: 'Language2';
  iso6391: Maybe<Scalars['String']['output']>;
  iso6393: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  localName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  textDirection: Maybe<Scalars['String']['output']>;
};

export type Languages = {
  __typename?: 'Languages';
  /** A list of Languages resource objects */
  data: Maybe<Array<Maybe<Data6ListItem>>>;
  languages: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type LanguagesLanguages = {
  __typename?: 'LanguagesLanguages';
  data: Maybe<Array<Maybe<DataListItem>>>;
};

export type LessonTranslationsTranslation = {
  __typename?: 'LessonTranslationsTranslation';
  /** The language of the translation */
  languageTag: Scalars['String']['output'];
  /** The id of the lesson produced by the translation */
  lessonId: Maybe<Scalars['Int']['output']>;
};

export type LessonTranslationsTranslationInput = {
  /** The language of the translation */
  languageTag: Scalars['String']['input'];
  /** The id of the lesson produced by the translation */
  lessonId?: InputMaybe<Scalars['Int']['input']>;
};

export type LessonTranslationsTranslations = {
  __typename?: 'LessonTranslationsTranslations';
  data: Maybe<Array<Maybe<LessonTranslationsTranslation>>>;
};

export type LessonsLesson = {
  __typename?: 'LessonsLesson';
  ageGroup: Maybe<AgeGroup>;
  backgroundColorId: Scalars['Int']['output'];
  countries: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdDt: Scalars['String']['output'];
  crowdinFileId: Maybe<Scalars['Int']['output']>;
  crowdinProjectId: Maybe<Scalars['Int']['output']>;
  foregroundColorId: Scalars['Int']['output'];
  id: Maybe<Scalars['Int']['output']>;
  imageId: Maybe<Scalars['Int']['output']>;
  languageTag: Scalars['String']['output'];
  liveDate: Maybe<Scalars['String']['output']>;
  localizable: Maybe<Scalars['Boolean']['output']>;
  references: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Maybe<Status>;
  style: Style;
  tertiaryColorId: Maybe<Scalars['Int']['output']>;
  title: Maybe<Scalars['String']['output']>;
  videoId: Maybe<Scalars['Int']['output']>;
};

export type LessonsLessonInput = {
  ageGroup?: InputMaybe<AgeGroup>;
  backgroundColorId: Scalars['Int']['input'];
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdDt: Scalars['String']['input'];
  crowdinFileId?: InputMaybe<Scalars['Int']['input']>;
  crowdinProjectId?: InputMaybe<Scalars['Int']['input']>;
  foregroundColorId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  imageId?: InputMaybe<Scalars['Int']['input']>;
  languageTag: Scalars['String']['input'];
  liveDate?: InputMaybe<Scalars['String']['input']>;
  localizable?: InputMaybe<Scalars['Boolean']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Status>;
  style: Style;
  tertiaryColorId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['Int']['input']>;
};

export type LessonsLessons = {
  __typename?: 'LessonsLessons';
  data: Maybe<Array<Maybe<LessonsLesson>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type LessonsPutLesson = {
  __typename?: 'LessonsPutLesson';
  ageGroup: Maybe<AgeGroup2>;
  backgroundColorId: Maybe<Scalars['Int']['output']>;
  countries: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  crowdinFileId: Maybe<Scalars['Int']['output']>;
  crowdinProjectId: Maybe<Scalars['Int']['output']>;
  foregroundColorId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  imageId: Maybe<Scalars['Int']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  liveDate: Maybe<Scalars['String']['output']>;
  localizable: Maybe<Scalars['Boolean']['output']>;
  moduleIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  references: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Maybe<Status>;
  style: Maybe<Style>;
  tertiaryColorId: Maybe<Scalars['Int']['output']>;
  title: Maybe<Scalars['String']['output']>;
  videoId: Maybe<Scalars['Int']['output']>;
};

export type LessonsPutLessonInput = {
  ageGroup?: InputMaybe<AgeGroup2>;
  backgroundColorId?: InputMaybe<Scalars['Int']['input']>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crowdinFileId?: InputMaybe<Scalars['Int']['input']>;
  crowdinProjectId?: InputMaybe<Scalars['Int']['input']>;
  foregroundColorId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imageId?: InputMaybe<Scalars['Int']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  liveDate?: InputMaybe<Scalars['String']['input']>;
  localizable?: InputMaybe<Scalars['Boolean']['input']>;
  moduleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Status>;
  style?: InputMaybe<Style>;
  tertiaryColorId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoId?: InputMaybe<Scalars['Int']['input']>;
};

export enum LicenseStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum LicenseType {
  CUSTOM = 'CUSTOM',
  UNIVERSAL = 'UNIVERSAL'
}

export type LocaleHeader = {
  __typename?: 'LocaleHeader';
  apiLanguageTag: Maybe<Scalars['String']['output']>;
  bibleLanguageTag: Maybe<Scalars['String']['output']>;
  localName: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  textDirection: Maybe<Scalars['String']['output']>;
};

export type LocaleHeaders = {
  __typename?: 'LocaleHeaders';
  /** List of locale headers */
  locales: Maybe<Array<Maybe<LocaleHeader>>>;
};

export type Locality = {
  __typename?: 'Locality';
  /** Represents the long version of the incorporated city or town, only supplied from the places API if present */
  longName: Maybe<Scalars['String']['output']>;
  /** Represents the short version of the incorporated city or town, only supplied from the places API if present */
  shortName: Maybe<Scalars['String']['output']>;
};

export type Locality2 = {
  __typename?: 'Locality2';
  /** Represents the long version of the incorporated city or town, only supplied from the places API if present */
  longName: Maybe<Scalars['String']['output']>;
  /** Represents the short version of the incorporated city or town, only supplied from the places API if present */
  shortName: Maybe<Scalars['String']['output']>;
};

export type LocalityInput = {
  /** Represents the long version of the incorporated city or town, only supplied from the places API if present */
  longName?: InputMaybe<Scalars['String']['input']>;
  /** Represents the short version of the incorporated city or town, only supplied from the places API if present */
  shortName?: InputMaybe<Scalars['String']['input']>;
};

export enum MediaType {
  BIBLE_AUDIO = 'BIBLE_AUDIO',
  BIBLE_TEXT = 'BIBLE_TEXT',
  PLANS = 'PLANS',
  VIDEOS = 'VIDEOS'
}

export type Member = {
  __typename?: 'Member';
  principal: Scalars['String']['output'];
};

export type MemberInput = {
  principal: Scalars['String']['input'];
};

export type MembersCount = {
  __typename?: 'MembersCount';
  totalMembers: Maybe<Scalars['Int']['output']>;
};

export type ModuleTypesModuleType = {
  __typename?: 'ModuleTypesModuleType';
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type ModuleTypesModuleTypes = {
  __typename?: 'ModuleTypesModuleTypes';
  data: Maybe<Array<Maybe<ModuleTypesModuleType>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type ModulesModules = {
  __typename?: 'ModulesModules';
  data: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
};

export type MomentsColors = {
  __typename?: 'MomentsColors';
  response: Maybe<MomentsColorsResponse>;
};

export type MomentsColorsResponse = {
  __typename?: 'MomentsColorsResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  /** List of available colors. */
  data: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MomentsCreate = {
  __typename?: 'MomentsCreate';
  response: Maybe<MomentsCreateResponse>;
};

export type MomentsCreateBase = {
  __typename?: 'MomentsCreateBase';
  /** URL related to the moment */
  actionUrl: Maybe<Scalars['String']['output']>;
  body: Maybe<MomentsCreateBaseBody>;
  images: Maybe<MomentsCreateBaseImages>;
  /** Share URL for the moment */
  shareUrl: Maybe<Scalars['String']['output']>;
  title: Maybe<MomentsCreateBaseTitle>;
};

export type MomentsCreateBaseBody = {
  __typename?: 'MomentsCreateBaseBody';
  str: Maybe<Scalars['String']['output']>;
};

export type MomentsCreateBaseImages = {
  __typename?: 'MomentsCreateBaseImages';
  avatar: Maybe<FriendshipsIncomingUsersAvatar>;
  body: Maybe<MomentsCreateBaseImagesImage>;
  icon: Maybe<MomentsCreateBaseImagesImage>;
};

export type MomentsCreateBaseImagesImage = {
  __typename?: 'MomentsCreateBaseImagesImage';
  /** Link to the user's page */
  actionUrl: Maybe<Scalars['String']['output']>;
  renditions: Maybe<Array<Maybe<FriendshipsIncomingUsersAvatarRendition>>>;
};

export type MomentsCreateBaseTitle = {
  __typename?: 'MomentsCreateBaseTitle';
  lArgs: Maybe<MomentsCreateBaseTitleLArgs>;
  lStr: Maybe<Scalars['String']['output']>;
};

export type MomentsCreateBaseTitleLArgs = {
  __typename?: 'MomentsCreateBaseTitleLArgs';
  referenceHuman: Maybe<Scalars['String']['output']>;
  versionAbbreviation: Maybe<Scalars['String']['output']>;
};

export type MomentsCreateCommenting = {
  __typename?: 'MomentsCreateCommenting';
  comments: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  total: Maybe<Scalars['Int']['output']>;
};

export type MomentsCreateExtras = {
  __typename?: 'MomentsCreateExtras';
  /** The hexadecimal color. */
  color: Maybe<Scalars['String']['output']>;
  /** Array of labels for the moment. */
  labels: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Array of references for the moment. */
  references: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Title of the moment. */
  title: Maybe<Scalars['String']['output']>;
  user: Maybe<FriendshipsIncomingUsersUserData>;
};

export type MomentsCreateLiking = {
  __typename?: 'MomentsCreateLiking';
  /** Array of User's Ids that have liked the moment. */
  allUsers: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  likes: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type MomentsCreateReferenceInput = {
  /** The human readable reference. */
  human?: InputMaybe<Scalars['String']['input']>;
  /** A valid list of USFM verse references. */
  usfm?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An ID (integer) of the Bible version. */
  versionId?: InputMaybe<Scalars['Int']['input']>;
};

export type MomentsCreateRequestInput = {
  /** Valid color specified as a hexadecimal string. */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Content of the note. */
  content?: InputMaybe<Scalars['String']['input']>;
  createdDt: Scalars['String']['input'];
  /** Integer between 320 and 1080. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** This is the image_id that the API returned to you from images.upload call. */
  imageId?: InputMaybe<Scalars['String']['input']>;
  /** The desired moment kind to be created. */
  kind: Kind;
  /** List of labels that the user wants to “tag” this bookmark with. */
  labels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** List of reference dictionaries, with each dictionary containing 'usfm' and 'version_id', where usfm is a valid list of USFM verse references and version_id is an ID (integer) of the Bible version. */
  references?: InputMaybe<Array<InputMaybe<MomentsCreateReferenceInput>>>;
  /** Currently only accepts “lens”. */
  source?: InputMaybe<Source>;
  /** Title of the moment. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** The desired visibility setting for the note. If not passed, it will be set to 'draft' by default. */
  userStatus?: InputMaybe<UserStatus>;
  /** Integer between 320 and 1080. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type MomentsCreateResponse = {
  __typename?: 'MomentsCreateResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<MomentsCreateResponseData>;
};

export type MomentsCreateResponseData = {
  __typename?: 'MomentsCreateResponseData';
  base: Maybe<MomentsCreateBase>;
  commenting: Maybe<MomentsCreateCommenting>;
  createdDt: Maybe<Scalars['String']['output']>;
  extras: Maybe<MomentsCreateExtras>;
  /** The ID of the moment. */
  id: Maybe<Scalars['JSON']['output']>;
  /** The hexadecimal color value. */
  kindColor: Maybe<Scalars['String']['output']>;
  kindId: Maybe<Scalars['String']['output']>;
  liking: Maybe<MomentsCreateLiking>;
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type MomentsHideVerseColors = {
  __typename?: 'MomentsHideVerseColors';
  response: Maybe<MomentsHideVerseColorsResponse>;
};

export type MomentsHideVerseColorsRequestInput = {
  /** A list of one or more verse USFMs in a single chapter. */
  usfm: Array<InputMaybe<Scalars['JSON']['input']>>;
  /** version_id for Bible version the hidden colors are tracked on. */
  versionId: Scalars['Int']['input'];
};

export type MomentsHideVerseColorsResponse = {
  __typename?: 'MomentsHideVerseColorsResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type MomentsVerseColors = {
  __typename?: 'MomentsVerseColors';
  response: Maybe<MomentsVerseColorsResponse>;
};

export type MomentsVerseColorsResponse = {
  __typename?: 'MomentsVerseColorsResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept a friend request.
   *
   * Equivalent to POST /3.1/accept.json
   */
  acceptFriendship: Maybe<FriendshipsInvite>;
  /**
   * Accept a license agreement
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/profiles/{profile_type}/{profile_id}/license:accept
   */
  acceptLicenseAgreement: Maybe<AdminOrganizationsOrganizationProfilesProfileLicense>;
  /**
   * Create a collection
   *
   * Equivalent to POST /4.0/collections
   */
  addCollection: Maybe<CollectionPost>;
  /**
   * Route for Crowdin webhooks to send events to
   *
   * Equivalent to POST /4.0/crowdin_events
   */
  addCrowdinEvent: Maybe<Scalars['JSON']['output']>;
  /**
   * Upload a devotional image
   *
   * Equivalent to POST /4.0/images
   */
  addImage: Maybe<ImagesPresignResponse>;
  /**
   * This is an authenticated insert of a kid into the database.
   *
   * Equivalent to POST /4.0/kids
   */
  addKid: Maybe<Kid>;
  /**
   * This is an authenticated insert of a kid achievement into the database.
   *
   * Equivalent to POST /4.0/kids/{kid_id}/achievements/{achievement_id}
   */
  addKidAchievement: Maybe<KidsAchievement>;
  /**
   * This is an authenticated insert of a kid challenge into the database.
   *
   * Equivalent to POST /4.0/kids/{kid_id}/challenges/{challenge_id}
   */
  addKidChallenge: Maybe<KidsChallenge>;
  /**
   * This is an authenticated insert of a collectible set into the database.
   *
   * Equivalent to POST /4.0/kids/{kid_id}/collectible-set/{collectible_set_id}/collectibles
   */
  addKidCollectible: Maybe<KidsCollectibles>;
  /**
   * This is an authenticated post request of a kids question resource.
   *
   * Equivalent to POST /4.0/kids/{kid_id}/questions/{question_id}
   */
  addKidsQuestions: Maybe<KidsQuestion>;
  /**
   * This is an authenticated post request of a kids story resource.
   *
   * Equivalent to POST /4.0/kids/{kid_id}/stories/{story_id}
   */
  addKidsStories: Maybe<KidsStory>;
  /**
   * Create a new lesson
   *
   * Equivalent to POST /4.0/lessons
   */
  addLesson: Maybe<LessonsLesson>;
  /**
   * Create a new translation for an english lessons.
   *
   * Equivalent to POST /4.0/lessons/{id}/translations
   */
  addLessonTranslation: Maybe<LessonTranslationsTranslation>;
  /**
   * This API endpoint adds a member to a bindings members.
   *
   * Equivalent to POST /policies/{resource_id}/bindings/{binding_id}/members
   */
  addMember: Maybe<Member>;
  /**
   * Create a new module
   *
   * Equivalent to POST /4.0/modules
   */
  addModule: Maybe<Scalars['JSON']['output']>;
  /**
   * Stripe refers to this as "SetupIntent" which preserves a payment method for future use.
   *
   * Equivalent to POST /4.0/payment-methods
   */
  addPaymentMethod: Maybe<PaymentMethod>;
  /**
   * Used to get the URI the client should call to make a donation or setup a subscription using PayPal.
   *
   * Equivalent to POST /4.0/paypal-checkout
   */
  addPaypalCheckout: Maybe<PaypalCheckout>;
  /**
   * Add content to a playlist
   *
   * Equivalent to POST /admin/4.0/playlists/{playlist_id}/content
   */
  addPlaylistContent: Maybe<AdminPlaylistsPlaylist>;
  /**
   * Create a publisher resource
   *
   * Equivalent to POST /4.0/publishers
   */
  addPublisher: Maybe<PublisherPost>;
  /**
   * Mutually exclusive params:
   *   [id, references]
   * XOR params:
   *   [id, uuid]
   *   [id, publisher_id]
   *   [id, type]
   * Required params:
   *   created_dt
   *   source_url
   *   title
   *   language
   *   orientation
   *
   *
   * Equivalent to POST /4.0/videos
   */
  addVideo: Maybe<VideoPost>;
  /**
   * Update the image_id for a user.
   *
   * Equivalent to POST /3.1/avatar_update.json
   */
  avatarUpdate: Maybe<UsersAvatarAvatarUpdate>;
  /**
   * Claim an invite for an account
   *
   * Equivalent to POST /admin/4.0/accounts/{account_id}:claim
   */
  claimAdminAccount: Maybe<AdminAccountsAccount>;
  /**
   * Claim an invite for an account on an organization
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/accounts/{account_id}:claim
   */
  claimAdminOrganizationAccount: Maybe<AdminOrganizationsAccountsAccount>;
  /**
   * Convert a church to multi-site or single-site
   *
   * Equivalent to POST /admin/4.0/churches/{organization_id}:convert
   */
  convertChurch: Maybe<AdminOrganizationsConvertChurchResponse>;
  /**
   * Create an Activity
   *
   * Equivalent to POST /4.0/together/{together_id}/activities
   */
  createActivity: Maybe<ActivitiesActivity>;
  /**
   * Create a new account with the invited status
   *
   * Equivalent to POST /admin/4.0/accounts
   */
  createAdminAccount: Maybe<AdminAccountsAccount>;
  /**
   * Create an export resource
   *
   * Equivalent to POST /admin/4.0/exports
   */
  createAdminExport: Maybe<Scalars['JSON']['output']>;
  /**
   * Create a new account with the invited status
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/accounts
   */
  createAdminOrganizationAccount: Maybe<AdminOrganizationsAccountsInviteResponse>;
  /**
   * Create a bible version grant for an organization
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/bible_version_grants
   */
  createAdminOrganizationBibleVersionGrant: Maybe<AdminOrganizationsBibleVersionGrant>;
  /**
   * Create a content grant for an organization
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/content_grants
   */
  createAdminOrganizationContentGrant: Maybe<AdminOrganizationsContentGrant>;
  /**
   * Create a new organization post
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/posts
   */
  createAdminOrganizationPost: Maybe<AdminOrganizationsOrganizationPostsPost>;
  /**
   * Create a new organization profile
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/profiles
   */
  createAdminOrganizationProfile: Maybe<Scalars['JSON']['output']>;
  /**
   * Creates a new relationship with the {organization_id} as the parent.
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/organization_relationships
   */
  createAdminOrganizationRelationship: Maybe<AdminOrganizationsOrganizationRelationship>;
  /**
   * Create a new organization
   *
   * Equivalent to POST /admin/4.0/organizations
   */
  createAdminOrganizations: Maybe<AdminOrganizationsOrganization>;
  /**
   * - Who - This should *NOT* be used by clients. Called from an admin tool when necessary to add special cases.
   * - What - This API allows us to add to suggestions that are normally only created by crowd sourced data from our end users.
   * - When - Use this when an admin wants to add a manual suggestion.
   * - Where - Only use this API from an admin tool.
   * - Why - This allows us to insert items into suggestions that users may not naturally search frequently enough to get into the suggestions list.
   * - How - The POST body is parsed and saved to PG & Solr.
   *
   *
   * Equivalent to POST /admin/4.1/suggestions
   */
  createAdminSuggestion: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint creates a binding resource.
   *
   * Equivalent to POST /policies/{resource_id}/bindings
   */
  createBinding: Maybe<RoleBinding>;
  /**
   * This should *NOT* be used by end user clients. It will be used between APIs.
   *
   * Equivalent to POST /4.0/callbacks
   */
  createCallback: Maybe<Scalars['JSON']['output']>;
  /**
   * Create a new Church
   *
   * Equivalent to POST /admin/4.0/churches
   */
  createChurch: Maybe<AdminChurchesChurchPostMutation>;
  /**
   * Create a new church location resource
   *
   * Equivalent to POST /admin/4.0/churches/{organization_id}/locations
   */
  createChurchLocation: Maybe<AdminOrganizationsLocationPostMutation>;
  /**
   * Create a content.
   *
   * Equivalent to POST /admin/4.0/content
   */
  createContent: Maybe<AdminContentContentUpserted>;
  /**
   * Create a content partner
   *
   * Equivalent to POST /admin/4.0/content_partners
   */
  createContentPartner: Maybe<AdminOrganizationsContentPartner>;
  /**
   * Create a content relationship.
   *
   * Equivalent to POST /admin/4.0/content/{content_id}/relationships
   */
  createContentRelationship: Maybe<AdminContentRelationshipUpserted>;
  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows creating an elevation.
   * - When - Use this API when an admin user is wanting to create a new elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want to elevate certain documents (objects) for a given query.
   * - How - Call this URL with the POST HTTP method passing the elevation object in the body.
   *
   *
   * Equivalent to POST /4.0/elevations
   */
  createElevation: Maybe<Elevation>;
  /**
   * Create a new event.
   *
   * Equivalent to POST /admin/4.0/events
   */
  createEvent: Maybe<AdminEventsAdminEventCreated>;
  /**
   * This should *NOT* be used by clients. Called on a build pipeline from our service specs project. It may also be used from an admin tool.
   *
   * Equivalent to POST /4.0/features
   */
  createFeature: Maybe<Feature>;
  /**
   * Follow an Organization
   *
   * Equivalent to POST /4.0/following
   */
  createFollowing: Maybe<FollowingFollowing>;
  /**
   * This should *NOT* be used by clients. Called on a server side cron.
   *
   * Equivalent to POST /4.0/instrument
   */
  createInstrumentation: Maybe<Scalars['JSON']['output']>;
  /**
   * Create a Join Request
   *
   * Equivalent to POST /4.0/together/{together_id}/participants:join
   */
  createJoinRequest: Maybe<ParticipantsParticipant>;
  /**
   * Create a moment of the specified kind and properties.
   *
   * Equivalent to POST /3.1/create.json
   */
  createMoment: Maybe<MomentsCreate>;
  /**
   * Create a new featured plan that starts now.
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/featured_plans
   */
  createOrganizationFeaturedPlan: Maybe<AdminOrganizationsFeaturedPlan>;
  /**
   * Create an action on the organization post
   *
   * Equivalent to POST /4.0/organizations/{organization_id}/posts/{post_id}/actions
   */
  createOrganizationPostAction: Maybe<OrganizationsOrganizationPostsPostAction>;
  /**
   * Create a Participant
   *
   * Equivalent to POST /4.0/together/{together_id}/participants
   */
  createParticipant: Maybe<ParticipantsParticipantsCreate>;
  /**
   * This API endpoint creates a permission.
   *
   * Equivalent to POST /permissions
   */
  createPermission: Maybe<Permission>;
  /**
   * Create a subscription
   *
   * Equivalent to POST /4.0/subscriptions
   */
  createPlanSubscription: Maybe<PlanSubscription>;
  /**
   * Create a playlist
   *
   * Equivalent to POST /admin/4.0/playlists
   */
  createPlaylist: Maybe<AdminPlaylistsPlaylist>;
  /**
   * This API endpoint creates a role.
   *
   * Equivalent to POST /roles
   */
  createRole: Maybe<Role>;
  /**
   * Create a Save and also modify comments on content items from the event. This is the monolith pattern hence the 3.3 version.
   *
   * Equivalent to POST /3.3/saves
   */
  createSave: Maybe<SavesSaves>;
  /**
   * - Who - Only used by the crowdin webhook. Should *NOT* be used by clients. - What - This API endpoint is to be used by crowdin to create a translation. - When - Use this API endpoint when a new translation is being created. - Where - This should only be used by crowdin. - Why - In cases where we want to create a translation from a crowdin webhook. - How - Call this URL with the POST HTTP method and send json data in the body.
   *
   * Equivalent to POST /4.0/translations
   */
  createTranslation: Maybe<Scalars['JSON']['output']>;
  createUser: Maybe<UsersUserData>;
  /**
   * Decline a friend request.
   *
   * Equivalent to POST /3.1/decline.json
   */
  declineFriendship: Maybe<FriendshipsInvite>;
  /**
   * Delete an Activity resource
   *
   * Equivalent to DELETE /4.0/together/{together_id}/activities/{activity_id}
   */
  deleteActivity: Maybe<Scalars['JSON']['output']>;
  /**
   * Remove an account.
   *
   * Equivalent to DELETE /admin/4.0/accounts/{account_id}
   */
  deleteAdminAccount: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete an export resource
   *
   * Equivalent to DELETE /admin/4.0/exports/{export_id}
   */
  deleteAdminExport: Maybe<Scalars['JSON']['output']>;
  /**
   * Remove an administrator from an organization
   *
   * Equivalent to DELETE /admin/4.0/organizations/{organization_id}/accounts/{account_id}
   */
  deleteAdminOrganizationAccount: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete an organization's bible version grant
   *
   * Equivalent to DELETE /admin/4.0/organizations/{organization_id}/bible_version_grants/{grant_id}
   */
  deleteAdminOrganizationBibleVersionGrant: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a content grant from an organization
   *
   * Equivalent to DELETE /admin/4.0/organizations/{organization_id}/content_grants/{grant_id}
   */
  deleteAdminOrganizationContentGrant: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete an organization post
   *
   * Equivalent to DELETE /admin/4.0/organizations/{organization_id}/posts/{post_id}
   */
  deleteAdminOrganizationPost: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a profile
   *
   * Equivalent to DELETE /admin/4.0/organizations/{organization_id}/profiles/{profile_type}/{profile_id}
   */
  deleteAdminOrganizationProfile: Maybe<Scalars['JSON']['output']>;
  /**
   * - Who - Only admins / admin tools should use this API.
   * - What - This is a marked deletion of a trending query that will be ignored from future GET calls.
   * - When - When there is an offensive trending query we can use this to delete it by id.
   * - Where - This API should only be called from our Seek admin tool
   * - Why - In case there is an offensive / odd trending query, we can use this API to remove it.
   * - How - The API deletes the trending query from the database by its id.
   *
   *
   * Equivalent to DELETE /admin/4.1/trending/{trending_id}
   */
  deleteAdminTrending: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint deletes a binding resource.
   *
   * Equivalent to DELETE /policies/{resource_id}/bindings/{binding_id}
   */
  deleteBinding: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a church
   *
   * Equivalent to DELETE /admin/4.0/churches/{organization_id}
   */
  deleteChurch: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a church location
   *
   * Equivalent to DELETE /admin/4.0/churches/{organization_id}/locations/{location_id}
   */
  deleteChurchLocation: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a content resource
   *
   * Equivalent to DELETE /admin/4.0/content/{content_id}
   */
  deleteContent: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a content relationship.
   *
   * Equivalent to DELETE /admin/4.0/content/{content_id}/relationships/{relationship_id}
   */
  deleteContentRelationship: Maybe<Scalars['JSON']['output']>;
  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows deleting an elevation by its id / primary key.
   * - When - Use this API when an admin user is wanting to delete an elevation for *all* languages.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we no longer what to elevate certain documents (objects) for a given query.
   * - How - Call this URL with the DELETE HTTP method passing the unique id in the path.
   *
   *
   * Equivalent to DELETE /4.0/elevations/{elevation_id}
   */
  deleteElevation: Maybe<Scalars['JSON']['output']>;
  /**
   * deletes an event
   *
   * Equivalent to DELETE /admin/4.0/events/{event_id}
   */
  deleteEvent: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a feature from search by its id.
   *
   * Equivalent to DELETE /4.0/features/{feature_id}
   */
  deleteFeature: Maybe<Scalars['JSON']['output']>;
  /**
   * Unfollow an Organization
   *
   * Equivalent to DELETE /4.0/following/{organization_id}
   */
  deleteFollowing: Maybe<Scalars['JSON']['output']>;
  /**
   * This will remove all search history associated with the authenticated user.
   *
   * Equivalent to DELETE /4.0/history
   */
  deleteHistory: Maybe<Scalars['JSON']['output']>;
  /**
   * This is an authenticated deletion of a kid resource.
   *
   * Equivalent to DELETE /4.0/kids/{kid_id}
   */
  deleteKid: Maybe<Scalars['JSON']['output']>;
  /**
   * Who: API devs that want to delete an existing snippet. What: Delete a specific snippet object by id and language. Why: To delete existing snippets that are no longer needed. How: HTTP DELETE rest call
   *
   * Equivalent to DELETE /4.0/languages/{language_id}/snippets/{snippet_id}
   */
  deleteLanguageSnippet: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete an action on the organization post
   *
   * Equivalent to DELETE /4.0/organizations/{organization_id}/posts/{post_id}/actions/{action_type}
   */
  deleteOrganizationPostAction: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete plan together if user is host, otherwise remove resource from plan together
   *
   * Equivalent to DELETE /4.0/together/{together_id}/participants/{user_id}
   */
  deleteParticipant: Maybe<Scalars['JSON']['output']>;
  /**
   * This is an authenticated deletion of a user's payment method.
   *
   * Equivalent to DELETE /4.0/payment-methods/{payment_method_id}
   */
  deletePaymentMethod: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint deletes a permission.
   *
   * Equivalent to DELETE /permissions/{permission}
   */
  deletePermission: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a subscription resource
   *
   * Equivalent to DELETE /4.0/subscriptions/{subscription_id}
   */
  deletePlanSubscription: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a playlist
   *
   * Equivalent to DELETE /admin/4.0/playlists/{playlist_id}
   */
  deletePlaylist: Maybe<Scalars['JSON']['output']>;
  /**
   * Remove a content resource from a playlist
   *
   * Equivalent to DELETE /admin/4.0/playlists/{playlist_id}/content/{content_id}
   */
  deletePlaylistContent: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete progress collection by subscription id
   *
   * Equivalent to DELETE /4.0/subscriptions/{subscription_id}/progress
   */
  deleteProgress: Maybe<Scalars['JSON']['output']>;
  /**
   * Uncomplete all progress on a day
   *
   * Equivalent to DELETE /4.0/subscriptions/{subscription_id}/progress/{progress_day}
   */
  deleteProgressDay: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint deletes a role resource.
   *
   * Equivalent to DELETE /roles/{role_id}
   */
  deleteRole: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete Saves
   *
   * Equivalent to DELETE /4.0/saves/{event_id}
   */
  deleteSaves: Maybe<Scalars['JSON']['output']>;
  /**
   * This is an authenticated deletion of a user's recurring gift.
   *
   * Equivalent to DELETE /4.0/subscriptions/{subscription_id}
   */
  deleteSubscription: Maybe<Scalars['JSON']['output']>;
  /**
   * - Who - Only admins should use this API.
   * - What - This marks a suggestion as deleted so that it will be ignored from future GET calls.
   * - When - When we have a suggestion that is a typo or maybe offensive, an admin could use this API to ignore it.
   * - Where - This should only be implemented in our admin search tool (Seek).
   * - Why - Gives us flexibility to remove bad suggestions.
   * - How - The API looks at the suggestion by id and deletes it from PG & Solr.
   *
   *
   * Equivalent to DELETE /admin/4.1/suggestions/{suggestion_id}
   */
  deleteSuggestion: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a user.
   *
   * Equivalent to POST /3.1/delete.json
   */
  deleteUser: Maybe<UsersDeleteUserDelete>;
  /**
   * Duplicate an existing event creating a new event.
   *
   * Equivalent to POST /admin/4.0/events/{event_id}:duplicate
   */
  duplicateEvent: Maybe<AdminEventsAdminEvent>;
  /**
   * Mutually exclusive params:
   *   [title, start]
   *   [title, end]
   *   [description, start]
   *   [description, end]
   *   [title, videos]
   *   [description, videos]
   * XOR params:
   *   [language, start]
   *   [language, end]
   *   [language, videos]
   *
   *
   * Equivalent to PUT /4.0/collections/{id}
   */
  editCollection: Maybe<VideoCollection>;
  /**
   * Edit a content partner
   *
   * Equivalent to PATCH /admin/4.0/content_partners/{content_partner_id}
   */
  editContentPartner: Maybe<AdminOrganizationsContentPartner>;
  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows updating a single elevation resource by it's id / primary key.
   * - When - Use this API when an admin user is wanting to update a single elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want to allow an admin user to *edit* an existing elevation e.g. add new verses, change plans, etc.
   * - How - Call this URL with the unique id in the path and send the appropriate schema in the body.
   *
   *
   * Equivalent to PUT /4.0/elevations/{elevation_id}
   */
  editElevation: Maybe<Elevation>;
  /**
   * Updates a feature from search by its id.
   *
   * Equivalent to PUT /4.0/features/{feature_id}
   */
  editFeature: Maybe<Feature>;
  /**
   * This is an authenticated update to an already existing user's friend limit.
   *
   * Equivalent to PUT /4.0/{user_id}/friends_limit
   */
  editFriendsLimit: Maybe<FriendsLimit>;
  /**
   * This is an authenticated update to an already existing kid.
   *
   * Equivalent to PUT /4.0/kids/{kid_id}
   */
  editKid: Maybe<Kid>;
  /**
   * Edit a license agreement
   *
   * Equivalent to PATCH /admin/4.0/organizations/{organization_id}/profiles/{profile_type}/{profile_id}/license
   */
  editLicenseAgreement: Maybe<AdminOrganizationsOrganizationProfilesProfileLicense>;
  /**
   * Mutually exclusive params:
   *   [language, name]
   *   [language, url]
   *   [language, tracking_id]
   *   [description, name]
   *   [description, url]
   *   [description, tracking_id]
   *
   *
   * Equivalent to PUT /4.0/publishers/{id}
   */
  editPublisher: Maybe<Publisher>;
  /**
   * This call will act as an upsert for string translations from crowdin. On conflict with an existing string, only the translated text will be updated.
   *
   * Equivalent to PUT /4.0/strings/{crowdin_string_id}/translations/{language_tag}
   */
  editStringTranslation: Maybe<StringTranslationsStringTranslation>;
  /**
   * Mutually exclusive params:
   *   [references, title],
   *   [references, description],
   *   [references, tracking_id],
   *   [type, title],
   *   [type, description],
   *   [type, tracking_id]
   * XOR params:
   *   [language, references]
   *
   *
   * Equivalent to PUT /4.0/videos/{id}
   */
  editVideo: Maybe<Video>;
  /**
   * Set active featured plans to ended for an organization.
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/featured_plans:end
   */
  endOrganizationFeaturedPlan: Maybe<Scalars['JSON']['output']>;
  /**
   * Generates token for temporary authorization and sends forgot password email.
   *
   * Equivalent to POST /3.1/forgot_password.json
   */
  forgotPassword: Maybe<UsersPasswordForgotPassword>;
  /**
   * Hide a module
   *
   * Equivalent to POST /4.0/lessons/{lesson_id}/modules/{module_id}:hide
   */
  hideLessonModules: Maybe<Scalars['JSON']['output']>;
  /**
   * Hide all colors on the verses USFMs posted. Doesn't affect the original moment, only the response from verse_colors.json
   *
   * Equivalent to POST /3.1/hide_verse_colors.json
   */
  hideVerseColors: Maybe<MomentsHideVerseColors>;
  /**
   * This should *not* be used by clients. It is a custom method for indexing Plans in Solr. Only for API use.
   *
   * Equivalent to POST /4.0/plans:index
   */
  indexPlans: Maybe<Scalars['JSON']['output']>;
  /**
   * Join an organization
   *
   * Equivalent to POST /4.0/organizations/{organization_id}:join
   */
  joinOrganization: Maybe<Scalars['JSON']['output']>;
  /**
   * Leave an organization
   *
   * Equivalent to POST /4.0/organizations/{organization_id}:leave
   */
  leaveOrganization: Maybe<Scalars['JSON']['output']>;
  /**
   * Like an Activity resource
   *
   * Equivalent to POST /4.0/together/{together_id}/activities/{activity_id}:like
   */
  likeActivity: Maybe<ActivitiesActivity>;
  /**
   * Stripe refers to this as "PaymentIntent" which notifies the gateway that money should be transferred.
   *
   * Equivalent to POST /4.0/payments
   */
  makePayment: Maybe<Payment>;
  /**
   * Who: API devs that want to create a new snippet. What: POST a code snippet object to be created. Why: To create a new snippet for various plugins, scripts, etc How: HTTP POST rest call
   *
   * Equivalent to POST /4.0/languages/{language_id}/snippets
   */
  postLanguageSnippet: Maybe<Snippet>;
  /**
   * Publish an uploaded `image`
   *
   * Equivalent to PUT /4.0/images/{id}:publish
   */
  publishImage: Maybe<ImagesImage>;
  /**
   * Publish a `lesson`
   *
   * Equivalent to PUT /4.0/lessons/{id}:publish
   */
  publishLesson: Maybe<LessonsLesson>;
  /**
   * An endpoint for publishing a video resource
   *
   * Equivalent to PUT /4.0/videos/{id}:publish
   */
  publishVideo: Maybe<VideoPublishResponse>;
  /**
   * Who: API devs that want to update a snippet. What: Update a snippet resource object by id and language. Why: To update a snippet for various plugins, scripts, etc How: HTTP PUT rest call
   *
   * Equivalent to PUT /4.0/languages/{language_id}/snippets/{snippet_id}
   */
  putLanguageSnippet: Maybe<Snippet>;
  /**
   * Reject a license agreement
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/profiles/{profile_type}/{profile_id}/license:reject
   */
  rejectLicenseAgreement: Maybe<AdminOrganizationsOrganizationProfilesProfileLicense>;
  /**
   * Delete a collection
   *
   * Equivalent to DELETE /4.0/collections/{id}
   */
  removeCollection: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a draft lesson
   *
   * Equivalent to DELETE /4.0/lessons/{id}
   */
  removeLesson: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint deletes a principal from a bindings members.
   *
   * Equivalent to DELETE /policies/{resource_id}/bindings/{binding_id}/members/{principal_id}
   */
  removeMember: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a module resource
   *
   * Equivalent to DELETE /4.0/modules/{id}
   */
  removeModule: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete a publisher
   *
   * Equivalent to DELETE /4.0/publishers/{id}
   */
  removePublisher: Maybe<Scalars['JSON']['output']>;
  /**
   * Delete video
   *
   * Equivalent to DELETE /4.0/videos/{id}
   */
  removeVideo: Maybe<Scalars['JSON']['output']>;
  /**
   * Resend an invite for an account
   *
   * Equivalent to POST /admin/4.0/accounts/{account_id}:resend
   */
  resendAdminAccountInvite: Maybe<Scalars['JSON']['output']>;
  /**
   * Resend an invite for an organization account
   *
   * Equivalent to POST /admin/4.0/organizations/{organization_id}/accounts/{account_id}:resend
   */
  resendAdminOrganizationAccountInvite: Maybe<Scalars['JSON']['output']>;
  /**
   * Reserve collection id
   *
   * Equivalent to POST /4.0/collections:reserve
   */
  reserveCollection: Maybe<CollectionReserveResponse>;
  /**
   * Reserve an id for a publisher.
   *
   * Equivalent to POST /4.0/publishers:reserve
   */
  reservePublisher: Maybe<PublisherReserveResponse>;
  /**
   * Wildcard searching on users for subset filtering
   *
   * Equivalent to POST /admin/4.0/users:search
   */
  searchAdminUsers: Maybe<AdminUsersSearchUsers>;
  /**
   * Show a module
   *
   * Equivalent to POST /4.0/lessons/{lesson_id}/modules/{module_id}:show
   */
  showLessonModules: Maybe<Scalars['JSON']['output']>;
  /**
   * get a new image url to upload to our bucket
   *
   * Equivalent to POST /admin/4.0/images
   */
  signEventsImage: Maybe<ImagesEventsPresignedImageResponse>;
  /**
   * get a new image url to upload to our bucket
   *
   * Equivalent to POST /admin/4.0/images/{image_type}
   */
  signOrganizationImage: Maybe<ImagesPresignedImageResponse>;
  /**
   * Switch the status of an event.
   *
   * Equivalent to POST /admin/4.0/events/{event_id}:transition
   */
  transitionEvent: Maybe<AdminEventsAdminEvent>;
  /**
   * Unlike an Activity resource
   *
   * Equivalent to DELETE /4.0/together/{together_id}/activities/{activity_id}:like
   */
  unlikeActivity: Maybe<ActivitiesActivity>;
  /**
   * Unsubscribe from notifications of a specified type. All email will be unsubscribed from if type == all
   *
   * Equivalent to POST /3.1/unsubscribe.json
   */
  unsubscribe: Maybe<NotificationsUnsubscribe>;
  /**
   * Method for unsubscribing.
   *
   * Equivalent to PUT /4.0/settings:unsubscribe_all
   */
  unsubscribeKidUserSettings: Maybe<Setting>;
  /**
   * Update an Activity resource
   *
   * Equivalent to PUT /4.0/together/{together_id}/activities/{activity_id}
   */
  updateActivity: Maybe<ActivitiesActivity>;
  /**
   * Update an organization resource
   *
   * Equivalent to PATCH /admin/4.0/organizations/{organization_id}
   */
  updateAdminOrganization: Maybe<AdminOrganizationsOrganization>;
  /**
   * Update an organization post
   *
   * Equivalent to PATCH /admin/4.0/organizations/{organization_id}/posts/{post_id}
   */
  updateAdminOrganizationPost: Maybe<AdminOrganizationsOrganizationPostsPost>;
  /**
   * Update a organization profile
   *
   * Equivalent to PATCH /admin/4.0/organizations/{organization_id}/profiles/{profile_type}/{profile_id}
   */
  updateAdminOrganizationProfile: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint updates a binding resource.
   *
   * Equivalent to PUT /policies/{resource_id}/bindings/{binding_id}
   */
  updateBinding: Maybe<RoleBinding>;
  /**
   * Update a church
   *
   * Equivalent to PATCH /admin/4.0/churches/{organization_id}
   */
  updateChurch: Maybe<AdminChurchesChurchMutation>;
  /**
   * Update a content.
   *
   * Equivalent to PATCH /admin/4.0/content/{content_id}
   */
  updateContent: Maybe<AdminContentContentUpserted>;
  /**
   * Update a content relationship.
   *
   * Equivalent to PATCH /admin/4.0/content/{content_id}/relationships/{relationship_id}
   */
  updateContentRelationship: Maybe<AdminContentRelationshipUpserted>;
  /**
   * Update users email address.
   *
   * Equivalent to POST /3.1/update_email.json
   */
  updateEmail: Maybe<UsersEmailEmail>;
  /**
   * Update an events title, description, image_id, and times.
   *
   * Equivalent to PATCH /admin/4.0/events/{event_id}
   */
  updateEvent: Maybe<AdminEventsAdminEvent>;
  /**
   * Update the Array of Content Items for an Event
   *
   * Equivalent to PUT /admin/4.0/events/{event_id}/content
   */
  updateEventContent: Maybe<AdminEventsAdminEventContent>;
  /**
   * Update the Times for an event
   *
   * Equivalent to PUT /admin/4.0/events/{event_id}/times
   */
  updateEventTimes: Maybe<AdminEventsAdminEventTimes>;
  /**
   * Method for updating user settings.
   *
   * Equivalent to PUT /4.0/settings
   */
  updateKidUserSettings: Maybe<Setting>;
  /**
   * Update a `lessons`
   *
   * Equivalent to PUT /4.0/lessons/{id}
   */
  updateLesson: Maybe<LessonsPutLesson>;
  /**
   * Update a module
   *
   * Equivalent to PUT /4.0/modules/{id}
   */
  updateModule: Maybe<Scalars['JSON']['output']>;
  /**
   * Update a user's notification settings. This call can either be auth'd or a user's settings token can be passed. If they have previous settings merge the new settings into those. If they didn't have previous settings merge the new settings into the default settings.
   *
   * Equivalent to POST /3.1/update_settings.json
   */
  updateNotificationSettings: Maybe<NotificationsUpdateSettings>;
  /**
   * Update the order in which playlists will be displayed for a certain organization and language tag
   *
   * Equivalent to PUT /admin/4.0/organizations/{path_organization_id}/playlist_orders/{language_tag}
   */
  updateOrganizationsPlaylistsOrder: Maybe<AdminPlaylistOrder>;
  /**
   * Update an participant resource
   *
   * Equivalent to PUT /4.0/together/{together_id}/participants/{user_id}
   */
  updateParticipant: Maybe<ParticipantsParticipant>;
  /**
   * Update users password.
   *
   * Equivalent to POST /3.1/update_password.json
   */
  updatePassword: Maybe<UsersPasswordPassword>;
  /**
   * Update a subscription resource
   *
   * Equivalent to PUT /4.0/subscriptions/{subscription_id}
   */
  updatePlanSubscription: Maybe<PlanSubscription>;
  /**
   * Update a playlist
   *
   * Equivalent to PATCH /admin/4.0/playlists/{playlist_id}
   */
  updatePlaylist: Maybe<AdminPlaylistsPlaylist>;
  /**
   * Update the order in which playlists will be displayed
   *
   * Equivalent to PUT /admin/4.1/playlists_order
   */
  updatePlaylistsOrder: Maybe<PlaylistsOrder>;
  /**
   * Update progress on a day
   *
   * Equivalent to PUT /4.0/subscriptions/{subscription_id}/progress/{progress_day}
   */
  updateProgressDay: Maybe<ProgressProgressDay>;
  /**
   * This API endpoint updates a role resource.
   *
   * Equivalent to PUT /roles/{role_id}
   */
  updateRole: Maybe<Role>;
  /**
   * Creates new service times and saves the `week_starts_on` value to the church profile
   *
   * Equivalent to PUT /admin/4.0/churches/{organization_id}/schedule
   */
  updateSchedule: Maybe<AdminChurchesSchedule>;
  /**
   * Update a settings resource
   *
   * Equivalent to PUT /4.0/subscriptions/{subscription_id}/settings/{kind}
   */
  updateSetting: Maybe<SettingsSetting>;
  /**
   * This should *NOT* be used by clients. This will only be used by admin/API tools.
   *
   * Equivalent to PUT /4.0/strings/{string_id}
   */
  updateString: Maybe<Scalars['JSON']['output']>;
  /**
   * Used to modify an existing recurring gift's details.
   *
   * Equivalent to PATCH /4.0/subscriptions/{subscription_id}
   */
  updateSubscription: Maybe<Subscription>;
  /**
   * - Who - Any admin user can use this API but only in an admin tool (Seek).
   * - What - This is an authenticated update to an already existing suggestion.
   * - When - When there is a suggestion with a typo, we can use this API to fix it.
   * - Where - This API should only be usable from Seek.
   * - Why - Allows us to update crowd sourced data that might have typos, etc.
   * - How - The API uses the request body to update the suggestion in Solr & PG.
   *
   *
   * Equivalent to PUT /admin/4.1/suggestions/{suggestion_id}
   */
  updateSuggestion: Maybe<AdminSuggestion>;
  /**
   * - Who - This should *NOT* be used by clients. This will only be used by the admin tool. - What - In cases where we want to allow an admin user to *edit* the subtopics of a topics. - When - When editing the subtopics of a topic. - Where - In the Seek admin tool. - Why - To add or remove subtopics from a topic. - How - Call this URL with the topic id, passing the new subtopics in the body.
   *
   * Equivalent to PUT /4.0/topics/{topic_id}
   */
  updateTopic: Maybe<Topic>;
  /**
   * - Who - Only for search admins to use through a script. Should *NOT* be used by clients. - What - This API endpoint is used to update strings via CLI. - When - Use this API endpoint when strings need to be manually updated. - Where - This should only be used by the crowdin.py script. - Why - In cases where we need to manually update string translations. - How - Call this URL with the PUT HTTP method and send json data in the body.
   *
   * Equivalent to PUT /4.0/translations/{translation_source}
   */
  updateTranslation: Maybe<Scalars['JSON']['output']>;
  /**
   * Update a user's information such as address, email, preferred statement type, etc.
   *
   * Equivalent to PUT /4.0/users/{user_id}
   */
  updateUser: Maybe<User>;
  /**
   * Update Verse of the Day (VOTD) subscription settings for the authenticated user.
   *
   * Equivalent to POST /3.1/update_votd_subscription.json
   */
  updateVotdSubscription: Maybe<NotificationsUpdateVotdSubscription>;
  upsertRoleBinding: Maybe<UpsertBindingResponse>;
  /**
   * Update a user.
   *
   * Equivalent to POST /3.1/update.json
   */
  userUpdate31: Maybe<UsersUser>;
};


export type MutationAcceptFriendshipArgs = {
  friendshipsInviteRequestInput?: InputMaybe<FriendshipsInviteRequestInput>;
};


export type MutationAcceptLicenseAgreementArgs = {
  adminOrganizationsOrganizationProfilesAcceptProfileLicenseInput?: InputMaybe<AdminOrganizationsOrganizationProfilesAcceptProfileLicenseInput>;
  organizationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
  profileType: ProfileType2;
};


export type MutationAddCollectionArgs = {
  collectionPostInput?: InputMaybe<CollectionPostInput>;
};


export type MutationAddCrowdinEventArgs = {
  crowdinEventsTranslationUpdatedEventInput?: InputMaybe<CrowdinEventsTranslationUpdatedEventInput>;
};


export type MutationAddImageArgs = {
  imagesImageInput?: InputMaybe<ImagesImageInput>;
};


export type MutationAddKidArgs = {
  kidInput?: InputMaybe<KidInput>;
};


export type MutationAddKidAchievementArgs = {
  achievementId: Scalars['String']['input'];
  kidId: Scalars['Int']['input'];
  kidsAchievementInput?: InputMaybe<KidsAchievementInput>;
};


export type MutationAddKidChallengeArgs = {
  challengeId: Scalars['String']['input'];
  kidId: Scalars['Int']['input'];
  kidsChallengeInput?: InputMaybe<KidsChallengeInput>;
};


export type MutationAddKidCollectibleArgs = {
  collectibleSetId: Scalars['String']['input'];
  kidId: Scalars['Int']['input'];
  kidsCollectiblesInput?: InputMaybe<KidsCollectiblesInput>;
};


export type MutationAddKidsQuestionsArgs = {
  kidId: Scalars['Int']['input'];
  kidsQuestionInput?: InputMaybe<KidsQuestionInput>;
  questionId: Scalars['String']['input'];
};


export type MutationAddKidsStoriesArgs = {
  kidId: Scalars['Int']['input'];
  kidsStoryInput?: InputMaybe<KidsStoryInput>;
  storyId: Scalars['String']['input'];
};


export type MutationAddLessonArgs = {
  lessonsLessonInput?: InputMaybe<LessonsLessonInput>;
};


export type MutationAddLessonTranslationArgs = {
  id: Scalars['Int']['input'];
  lessonTranslationsTranslationInput?: InputMaybe<LessonTranslationsTranslationInput>;
};


export type MutationAddMemberArgs = {
  bindingId: Scalars['String']['input'];
  etag: Scalars['String']['input'];
  memberInput?: InputMaybe<MemberInput>;
  resourceId: Scalars['String']['input'];
};


export type MutationAddModuleArgs = {
  modulesPostModuleInput?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationAddPaymentMethodArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  paymentMethodPostInput?: InputMaybe<PaymentMethodPostInput>;
};


export type MutationAddPaypalCheckoutArgs = {
  paypalCheckoutPostInput?: InputMaybe<PaypalCheckoutPostInput>;
};


export type MutationAddPlaylistContentArgs = {
  adminPlaylistsPlaylistContentInput?: InputMaybe<AdminPlaylistsPlaylistContentInput>;
  playlistId: Scalars['Float']['input'];
};


export type MutationAddPublisherArgs = {
  publisherPostInput?: InputMaybe<PublisherPostInput>;
};


export type MutationAddVideoArgs = {
  videoPostInput?: InputMaybe<VideoPostInput>;
};


export type MutationAvatarUpdateArgs = {
  usersAvatarAvatarImageIdPropInput?: InputMaybe<UsersAvatarAvatarImageIdPropInput>;
};


export type MutationClaimAdminAccountArgs = {
  accountId: Scalars['String']['input'];
  adminAccountsInviteVerifyInput?: InputMaybe<AdminAccountsInviteVerifyInput>;
};


export type MutationClaimAdminOrganizationAccountArgs = {
  accountId: Scalars['String']['input'];
  adminOrganizationsAccountsInviteVerifyInput?: InputMaybe<AdminOrganizationsAccountsInviteVerifyInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationConvertChurchArgs = {
  adminOrganizationsConvertChurchRequestInput?: InputMaybe<AdminOrganizationsConvertChurchRequestInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateActivityArgs = {
  activitiesActivityCreateInput?: InputMaybe<ActivitiesActivityCreateInput>;
  togetherId: Scalars['Int']['input'];
};


export type MutationCreateAdminAccountArgs = {
  adminAccountsInviteInput?: InputMaybe<AdminAccountsInviteInput>;
};


export type MutationCreateAdminExportArgs = {
  admin40ExportsInput?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationCreateAdminOrganizationAccountArgs = {
  adminOrganizationsAccountsInviteRequestInput?: InputMaybe<AdminOrganizationsAccountsInviteRequestInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminOrganizationBibleVersionGrantArgs = {
  adminOrganizationsBibleVersionGrantInput?: InputMaybe<AdminOrganizationsBibleVersionGrantInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminOrganizationContentGrantArgs = {
  adminOrganizationsContentGrantInput?: InputMaybe<AdminOrganizationsContentGrantInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminOrganizationPostArgs = {
  adminOrganizationsOrganizationPostsPostRequestInput?: InputMaybe<AdminOrganizationsOrganizationPostsPostRequestInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminOrganizationProfileArgs = {
  organizationId: Scalars['String']['input'];
  organizationsPostOrganizationProfileInput?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationCreateAdminOrganizationRelationshipArgs = {
  adminOrganizationsOrganizationRelationshipInput?: InputMaybe<AdminOrganizationsOrganizationRelationshipInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateAdminOrganizationsArgs = {
  adminOrganizationsOrganizationCreateInput?: InputMaybe<AdminOrganizationsOrganizationCreateInput>;
};


export type MutationCreateAdminSuggestionArgs = {
  adminSuggestionInput?: InputMaybe<AdminSuggestionInput>;
};


export type MutationCreateBindingArgs = {
  resourceId: Scalars['String']['input'];
  roleBindingInput?: InputMaybe<RoleBindingInput>;
};


export type MutationCreateChurchArgs = {
  adminChurchesChurchPostMutationInput?: InputMaybe<AdminChurchesChurchPostMutationInput>;
};


export type MutationCreateChurchLocationArgs = {
  adminOrganizationsLocationPostMutationInput?: InputMaybe<AdminOrganizationsLocationPostMutationInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateContentArgs = {
  adminContentContentCreateInput?: InputMaybe<AdminContentContentCreateInput>;
};


export type MutationCreateContentPartnerArgs = {
  adminOrganizationsContentPartnerPostInput?: InputMaybe<AdminOrganizationsContentPartnerPostInput>;
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateContentRelationshipArgs = {
  adminContentRelationshipInput?: InputMaybe<AdminContentRelationshipInput>;
  contentId: Scalars['Float']['input'];
};


export type MutationCreateElevationArgs = {
  elevationInput?: InputMaybe<ElevationInput>;
};


export type MutationCreateEventArgs = {
  adminEventsAdminEventCreateInput?: InputMaybe<AdminEventsAdminEventCreateInput>;
};


export type MutationCreateFeatureArgs = {
  featureInput?: InputMaybe<FeatureInput>;
};


export type MutationCreateFollowingArgs = {
  followingFollowingInput?: InputMaybe<FollowingFollowingInput>;
};


export type MutationCreateJoinRequestArgs = {
  participantsParticipantJoinInput?: InputMaybe<ParticipantsParticipantJoinInput>;
  togetherId: Scalars['Int']['input'];
};


export type MutationCreateMomentArgs = {
  momentsCreateRequestInput?: InputMaybe<MomentsCreateRequestInput>;
};


export type MutationCreateOrganizationFeaturedPlanArgs = {
  adminOrganizationsFeaturedPlanCreateInput?: InputMaybe<AdminOrganizationsFeaturedPlanCreateInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationCreateOrganizationPostActionArgs = {
  organizationId: Scalars['String']['input'];
  organizationsOrganizationPostsPostActionRequestInput?: InputMaybe<OrganizationsOrganizationPostsPostActionRequestInput>;
  postId: Scalars['String']['input'];
};


export type MutationCreateParticipantArgs = {
  participantsParticipantsCreateInput?: InputMaybe<ParticipantsParticipantsCreateInput>;
  togetherId: Scalars['Int']['input'];
};


export type MutationCreatePermissionArgs = {
  permissionInput?: InputMaybe<PermissionInput>;
};


export type MutationCreatePlanSubscriptionArgs = {
  subscriptionsSubscriptionsCreateInput?: InputMaybe<PlanSubscriptionsCreateInput>;
};


export type MutationCreatePlaylistArgs = {
  adminPlaylistsPlaylistCreateInput?: InputMaybe<AdminPlaylistsPlaylistCreateInput>;
};


export type MutationCreateRoleArgs = {
  roleInput?: InputMaybe<RoleInput>;
};


export type MutationCreateSaveArgs = {
  savesSaveCreateInput?: InputMaybe<SavesSaveCreateInput>;
};


export type MutationCreateTranslationArgs = {
  xTranslationKey: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  usersUserCreateInputInput?: InputMaybe<UserCreateInput>;
};


export type MutationDeclineFriendshipArgs = {
  friendshipsInviteRequestInput?: InputMaybe<FriendshipsInviteRequestInput>;
};


export type MutationDeleteActivityArgs = {
  activityId: Scalars['Int']['input'];
  togetherId: Scalars['Int']['input'];
};


export type MutationDeleteAdminAccountArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationDeleteAdminExportArgs = {
  exportId: Scalars['String']['input'];
};


export type MutationDeleteAdminOrganizationAccountArgs = {
  accountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteAdminOrganizationBibleVersionGrantArgs = {
  grantId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteAdminOrganizationContentGrantArgs = {
  grantId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteAdminOrganizationPostArgs = {
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type MutationDeleteAdminOrganizationProfileArgs = {
  organizationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
  profileType: ProfileType2;
};


export type MutationDeleteAdminTrendingArgs = {
  trendingId: Scalars['Int']['input'];
};


export type MutationDeleteBindingArgs = {
  bindingId: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
};


export type MutationDeleteChurchArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteChurchLocationArgs = {
  locationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteContentArgs = {
  contentId: Scalars['Float']['input'];
};


export type MutationDeleteContentRelationshipArgs = {
  contentId: Scalars['Float']['input'];
  relationshipId: Scalars['Float']['input'];
};


export type MutationDeleteElevationArgs = {
  elevationId: Scalars['Int']['input'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['Float']['input'];
};


export type MutationDeleteFeatureArgs = {
  featureId: Scalars['Int']['input'];
};


export type MutationDeleteFollowingArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteKidArgs = {
  kidId: Scalars['Int']['input'];
};


export type MutationDeleteLanguageSnippetArgs = {
  languageId: Scalars['Int']['input'];
  snippetId: Scalars['Int']['input'];
};


export type MutationDeleteOrganizationPostActionArgs = {
  actionType: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type MutationDeleteParticipantArgs = {
  togetherId: Scalars['Int']['input'];
  userId: Scalars['Float']['input'];
};


export type MutationDeletePaymentMethodArgs = {
  paymentMethodId: Scalars['Int']['input'];
};


export type MutationDeletePermissionArgs = {
  permission: Scalars['String']['input'];
};


export type MutationDeletePlanSubscriptionArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type MutationDeletePlaylistArgs = {
  playlistId: Scalars['Float']['input'];
};


export type MutationDeletePlaylistContentArgs = {
  contentId: Scalars['Float']['input'];
  playlistId: Scalars['Float']['input'];
};


export type MutationDeleteProgressArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type MutationDeleteProgressDayArgs = {
  progressDay: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};


export type MutationDeleteRoleArgs = {
  roleId: Scalars['String']['input'];
};


export type MutationDeleteSavesArgs = {
  eventId: Scalars['Float']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type MutationDeleteSuggestionArgs = {
  suggestionId: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  usersDeleteUserDeleteInputInput?: InputMaybe<UsersDeleteUserDeleteInputInput>;
};


export type MutationDuplicateEventArgs = {
  adminEventsAdminEventDuplicateInput?: InputMaybe<AdminEventsAdminEventDuplicateInput>;
  eventId: Scalars['Float']['input'];
};


export type MutationEditCollectionArgs = {
  collection2Input?: InputMaybe<Collection2Input>;
  id: Scalars['Int']['input'];
};


export type MutationEditContentPartnerArgs = {
  adminOrganizationsContentPartnerPatchInput?: InputMaybe<AdminOrganizationsContentPartnerPatchInput>;
  contentPartnerId: Scalars['String']['input'];
};


export type MutationEditElevationArgs = {
  elevationId: Scalars['Int']['input'];
  elevationInput?: InputMaybe<ElevationInput>;
};


export type MutationEditFeatureArgs = {
  featureId: Scalars['Int']['input'];
  featureInput?: InputMaybe<FeatureInput>;
};


export type MutationEditFriendsLimitArgs = {
  friendsLimitInput?: InputMaybe<FriendsLimitInput>;
  userId: Scalars['Float']['input'];
};


export type MutationEditKidArgs = {
  kidId: Scalars['Int']['input'];
  kidInput?: InputMaybe<KidInput>;
};


export type MutationEditLicenseAgreementArgs = {
  adminOrganizationsOrganizationProfilesPatchProfileLicenseInput?: InputMaybe<AdminOrganizationsOrganizationProfilesPatchProfileLicenseInput>;
  organizationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
  profileType: ProfileType2;
};


export type MutationEditPublisherArgs = {
  id: Scalars['Int']['input'];
  publisherInput?: InputMaybe<PublisherInput>;
};


export type MutationEditStringTranslationArgs = {
  crowdinStringId: Scalars['Int']['input'];
  languageTag: Scalars['String']['input'];
  stringTranslationsStringTranslationInput?: InputMaybe<StringTranslationsStringTranslationInput>;
};


export type MutationEditVideoArgs = {
  _40VideoInput?: InputMaybe<_40VideoInput>;
  id: Scalars['Int']['input'];
};


export type MutationEndOrganizationFeaturedPlanArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  usersPasswordEmailInput?: InputMaybe<UsersPasswordEmailInput>;
};


export type MutationHideLessonModulesArgs = {
  lessonId: Scalars['Int']['input'];
  moduleId: Scalars['Int']['input'];
};


export type MutationHideVerseColorsArgs = {
  momentsHideVerseColorsRequestInput?: InputMaybe<MomentsHideVerseColorsRequestInput>;
};


export type MutationIndexPlansArgs = {
  fullReindex?: InputMaybe<Scalars['Boolean']['input']>;
  sinceDt?: InputMaybe<Scalars['String']['input']>;
  untilDt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationJoinOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  organizationsOrganizationJoinInput?: InputMaybe<OrganizationsOrganizationJoinInput>;
};


export type MutationLeaveOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationLikeActivityArgs = {
  activityId: Scalars['Int']['input'];
  togetherId: Scalars['Int']['input'];
};


export type MutationMakePaymentArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  paymentPostInput?: InputMaybe<PaymentPostInput>;
};


export type MutationPostLanguageSnippetArgs = {
  languageId: Scalars['Int']['input'];
  snippetInput: SnippetInput;
};


export type MutationPublishImageArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPublishLessonArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPublishVideoArgs = {
  id: Scalars['Int']['input'];
  videoPublishAndDeleteRequestInput?: InputMaybe<VideoPublishAndDeleteRequestInput>;
};


export type MutationPutLanguageSnippetArgs = {
  languageId: Scalars['Int']['input'];
  snippetId: Scalars['Int']['input'];
  snippetInput: SnippetInput;
};


export type MutationRejectLicenseAgreementArgs = {
  organizationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
  profileType: ProfileType2;
};


export type MutationRemoveCollectionArgs = {
  collectionDeleteRequestInput?: InputMaybe<CollectionDeleteRequestInput>;
  id: Scalars['Int']['input'];
};


export type MutationRemoveLessonArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveMemberArgs = {
  bindingId: Scalars['String']['input'];
  etag: Scalars['String']['input'];
  principalId: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
};


export type MutationRemoveModuleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemovePublisherArgs = {
  id: Scalars['Int']['input'];
  publisherDeleteRequestInput?: InputMaybe<PublisherDeleteRequestInput>;
};


export type MutationRemoveVideoArgs = {
  id: Scalars['Int']['input'];
  videoPublishAndDeleteRequestInput?: InputMaybe<VideoPublishAndDeleteRequestInput>;
};


export type MutationResendAdminAccountInviteArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationResendAdminOrganizationAccountInviteArgs = {
  accountId: Scalars['String']['input'];
  adminOrganizationsAccountsInviteResendInput?: InputMaybe<AdminOrganizationsAccountsInviteResendInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationSearchAdminUsersArgs = {
  adminUsersUserSearchInput?: InputMaybe<AdminUsersUserSearchInput>;
};


export type MutationShowLessonModulesArgs = {
  lessonId: Scalars['Int']['input'];
  moduleId: Scalars['Int']['input'];
};


export type MutationSignEventsImageArgs = {
  imagesImagePropertiesEventsInput?: InputMaybe<ImagesImagePropertiesEventsInput>;
};


export type MutationSignOrganizationImageArgs = {
  imageType: ImageType;
  imagesImagePropertiesInput?: InputMaybe<ImagesImagePropertiesInput>;
};


export type MutationTransitionEventArgs = {
  adminEventsAdminEventTransitionInput?: InputMaybe<AdminEventsAdminEventTransitionInput>;
  eventId: Scalars['Float']['input'];
};


export type MutationUnlikeActivityArgs = {
  activityId: Scalars['Int']['input'];
  togetherId: Scalars['Int']['input'];
};


export type MutationUnsubscribeArgs = {
  notificationsUnsubscribeRequestInput?: InputMaybe<NotificationsUnsubscribeRequestInput>;
};


export type MutationUnsubscribeKidUserSettingsArgs = {
  _40SettingsUnsubscribeAllInput?: InputMaybe<KidUserSettingsUnsubscribeInput>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<KidSubscriptionType>;
};


export type MutationUpdateActivityArgs = {
  activitiesActivityUpdateInput?: InputMaybe<ActivitiesActivityUpdateInput>;
  activityId: Scalars['Int']['input'];
  togetherId: Scalars['Int']['input'];
};


export type MutationUpdateAdminOrganizationArgs = {
  adminOrganizationsOrganizationUpdateInput?: InputMaybe<AdminOrganizationsOrganizationUpdateInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateAdminOrganizationPostArgs = {
  adminOrganizationsOrganizationPostsPostPatchInput?: InputMaybe<AdminOrganizationsOrganizationPostsPostPatchInput>;
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type MutationUpdateAdminOrganizationProfileArgs = {
  organizationId: Scalars['String']['input'];
  organizationsPatchOrganizationProfileInput?: InputMaybe<Scalars['JSON']['input']>;
  profileId: Scalars['String']['input'];
  profileType: ProfileType2;
};


export type MutationUpdateBindingArgs = {
  bindingId: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
  roleBindingInput?: InputMaybe<RoleBindingInput>;
};


export type MutationUpdateChurchArgs = {
  adminChurchesChurchMutationInput?: InputMaybe<AdminChurchesChurchMutationInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateContentArgs = {
  adminContentContentUpdateInput?: InputMaybe<AdminContentContentUpdateInput>;
  contentId: Scalars['Float']['input'];
};


export type MutationUpdateContentRelationshipArgs = {
  adminContentRelationshipPatchInput?: InputMaybe<AdminContentRelationshipPatchInput>;
  contentId: Scalars['Float']['input'];
  relationshipId: Scalars['Float']['input'];
};


export type MutationUpdateEmailArgs = {
  usersEmailUserUpdateEmailPropInput?: InputMaybe<UsersEmailUserUpdateEmailPropInput>;
};


export type MutationUpdateEventArgs = {
  adminEventsAdminEventUpdateInput?: InputMaybe<AdminEventsAdminEventUpdateInput>;
  eventId: Scalars['Float']['input'];
};


export type MutationUpdateEventContentArgs = {
  adminEventsAdminEventContentInput?: InputMaybe<AdminEventsAdminEventContentInput>;
  eventId: Scalars['Float']['input'];
};


export type MutationUpdateEventTimesArgs = {
  adminEventsAdminEventTimesInput?: InputMaybe<AdminEventsAdminEventTimesInput>;
  eventId: Scalars['Float']['input'];
};


export type MutationUpdateKidUserSettingsArgs = {
  settingsInfoInput?: InputMaybe<SettingsInfoInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLessonArgs = {
  id: Scalars['Int']['input'];
  lessonsPutLessonInput?: InputMaybe<LessonsPutLessonInput>;
};


export type MutationUpdateModuleArgs = {
  id: Scalars['Int']['input'];
  modulesModuleInput?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateNotificationSettingsArgs = {
  notificationsUpdateSettingsRequestInput?: InputMaybe<NotificationsUpdateSettingsRequestInput>;
};


export type MutationUpdateOrganizationsPlaylistsOrderArgs = {
  adminPlaylistOrderInput?: InputMaybe<AdminPlaylistOrderInput>;
  languageTag: Scalars['String']['input'];
  pathOrganizationId: Scalars['String']['input'];
};


export type MutationUpdateParticipantArgs = {
  participantsParticipantUpdateInput?: InputMaybe<ParticipantsParticipantUpdateInput>;
  togetherId: Scalars['Int']['input'];
  userId: Scalars['Float']['input'];
};


export type MutationUpdatePasswordArgs = {
  usersPasswordPasswordInputInput?: InputMaybe<UsersPasswordPasswordInputInput>;
};


export type MutationUpdatePlanSubscriptionArgs = {
  subscriptionId: Scalars['Int']['input'];
  subscriptionsSubscriptionUpdateInput?: InputMaybe<PlanSubscriptionUpdateInput>;
};


export type MutationUpdatePlaylistArgs = {
  adminPlaylistsPlaylistUpdateInput?: InputMaybe<AdminPlaylistsPlaylistUpdateInput>;
  playlistId: Scalars['Float']['input'];
};


export type MutationUpdatePlaylistsOrderArgs = {
  playlistsOrderInput?: InputMaybe<PlaylistsOrderInput>;
};


export type MutationUpdateProgressDayArgs = {
  progressDay: Scalars['Int']['input'];
  progressProgressDayUpdateInput?: InputMaybe<ProgressProgressDayUpdateInput>;
  subscriptionId: Scalars['Int']['input'];
};


export type MutationUpdateRoleArgs = {
  roleId: Scalars['String']['input'];
  roleInput?: InputMaybe<RoleInput>;
};


export type MutationUpdateScheduleArgs = {
  adminChurchesScheduleInput?: InputMaybe<AdminChurchesScheduleInput>;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateSettingArgs = {
  kind: Kind2;
  settingsSettingUpdateInput?: InputMaybe<SettingsSettingUpdateInput>;
  subscriptionId: Scalars['Int']['input'];
};


export type MutationUpdateStringArgs = {
  _40StringInput?: InputMaybe<_40StringInput>;
  stringId: Scalars['Int']['input'];
};


export type MutationUpdateSubscriptionArgs = {
  subscriptionId: Scalars['Int']['input'];
  subscriptionPatchInput?: InputMaybe<SubscriptionPatchInput>;
};


export type MutationUpdateSuggestionArgs = {
  adminSuggestionInput?: InputMaybe<AdminSuggestionInput>;
  suggestionId: Scalars['Int']['input'];
};


export type MutationUpdateTopicArgs = {
  topicId: Scalars['Int']['input'];
  topicInput?: InputMaybe<TopicInput>;
};


export type MutationUpdateTranslationArgs = {
  contentLanguage: Scalars['String']['input'];
  translationInput?: InputMaybe<TranslationInput>;
  translationSource: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  userId: Scalars['Int']['input'];
  userUpdateInput?: InputMaybe<UserUpdateInput>;
};


export type MutationUpdateVotdSubscriptionArgs = {
  notificationsUpdateVotdSubscriptionRequestInput?: InputMaybe<NotificationsUpdateVotdSubscriptionRequestInput>;
};


export type MutationUpsertRoleBindingArgs = {
  upsertRoleBindingInput: UpsertRoleBindingInput;
};


export type MutationUserUpdate31Args = {
  usersUserUpdateRequestInput?: InputMaybe<UsersUserUpdateRequestInput>;
};

export type MyImpact = {
  __typename?: 'MyImpact';
  /** Number of installs funded calculated by API using opaque formula. May be 0. */
  installs: Maybe<Scalars['Float']['output']>;
  /** Number of user interactions calculated by API using opaque formula. May be 0. */
  interactions: Maybe<Scalars['Float']['output']>;
  mostRecentGiftDt: Maybe<Scalars['String']['output']>;
};

export type Next = {
  __typename?: 'Next';
  canonical: Maybe<Scalars['Boolean']['output']>;
  human: Maybe<Scalars['String']['output']>;
  toc: Maybe<Scalars['Boolean']['output']>;
  usfm: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  versionId: Maybe<Scalars['Int']['output']>;
};

export type NotificationSettingsInput = {
  badges?: InputMaybe<PwfCommentsInput>;
  comments?: InputMaybe<PwfCommentsInput>;
  contactJoins?: InputMaybe<PwfCommentsInput>;
  friendships?: InputMaybe<PwfCommentsInput>;
  likes?: InputMaybe<PwfCommentsInput>;
  moments?: InputMaybe<PwfCommentsInput>;
  newPrayerFrom?: InputMaybe<PwfCommentsInput>;
  newsletter?: InputMaybe<PwfCommentsInput>;
  noteLike?: InputMaybe<PwfCommentsInput>;
  organizationsFeaturedPlan?: InputMaybe<PwfCommentsInput>;
  organizationsPost?: InputMaybe<PwfCommentsInput>;
  partners?: InputMaybe<PwfCommentsInput>;
  prayedFor?: InputMaybe<PwfCommentsInput>;
  prayerAnswered?: InputMaybe<PwfCommentsInput>;
  prayerArchived?: InputMaybe<PwfCommentsInput>;
  prayerComment?: InputMaybe<PwfCommentsInput>;
  prayerUpdate?: InputMaybe<PwfCommentsInput>;
  pwfAccepts?: InputMaybe<PwfCommentsInput>;
  pwfComments?: InputMaybe<PwfCommentsInput>;
  pwfInvites?: InputMaybe<PwfCommentsInput>;
  pwfReminders?: InputMaybe<PwfRemindersInput>;
  readingPlans?: InputMaybe<PwfCommentsInput>;
  streaks?: InputMaybe<PwfCommentsInput>;
  yv4cNewsletter?: InputMaybe<PwfCommentsInput>;
};

export type Notifications = {
  __typename?: 'Notifications';
  /** Is the newsletter being sent or not */
  newsletterEmail: Maybe<Scalars['Boolean']['output']>;
  /** Is the parent receiving the parent_digest */
  parentDigest: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationsGetSettings = {
  __typename?: 'NotificationsGetSettings';
  response: Maybe<NotificationsGetSettingsResponse>;
};

export type NotificationsGetSettingsResponse = {
  __typename?: 'NotificationsGetSettingsResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<NotificationsGetSettingsResponseData>;
};

export type NotificationsGetSettingsResponseData = {
  __typename?: 'NotificationsGetSettingsResponseData';
  languageTag: Maybe<Scalars['String']['output']>;
  notificationSettings: Maybe<NotificationsNotificationSettings>;
  token: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type NotificationsGetVotdSubscription = {
  __typename?: 'NotificationsGetVotdSubscription';
  response: Maybe<NotificationsGetVotdSubscriptionResponse>;
};

export type NotificationsGetVotdSubscriptionResponse = {
  __typename?: 'NotificationsGetVotdSubscriptionResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<NotificationsGetVotdSubscriptionResponseData>;
};

export type NotificationsGetVotdSubscriptionResponseData = {
  __typename?: 'NotificationsGetVotdSubscriptionResponseData';
  email: Maybe<NotificationsSubscriptionSettingsEmail>;
  imageEmail: Maybe<NotificationsSubscriptionSettingsImageEmail>;
};

export type NotificationsInput = {
  /** Is the newsletter being sent or not */
  newsletterEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the parent receiving the parent_digest */
  parentDigest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationsNotificationSettings = {
  __typename?: 'NotificationsNotificationSettings';
  badges: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  comments: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  contactJoins: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  friendships: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  likes: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  moments: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  newPrayerFrom: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  newsletter: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  noteLike: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  organizationsFeaturedPlan: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  organizationsPost: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  partners: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  prayedFor: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  prayerAnswered: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  prayerArchived: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  prayerComment: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  prayerUpdate: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  pwfAccepts: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  pwfComments: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  pwfInvites: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  pwfReminders: Maybe<NotificationsNotificationSettingsSettingsOptionsEmail>;
  readingPlans: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  streaks: Maybe<NotificationsNotificationSettingsSettingsOptions>;
  yv4cNewsletter: Maybe<NotificationsNotificationSettingsSettingsOptions>;
};

export type NotificationsNotificationSettingsInput = {
  badges?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  comments?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  contactJoins?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  friendships?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  likes?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  moments?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  newPrayerFrom?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  newsletter?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  noteLike?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  organizationsFeaturedPlan?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  organizationsPost?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  partners?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  prayedFor?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  prayerAnswered?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  prayerArchived?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  prayerComment?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  prayerUpdate?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  pwfAccepts?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  pwfComments?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  pwfInvites?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  pwfReminders?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsEmailInput>;
  readingPlans?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  streaks?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
  yv4cNewsletter?: InputMaybe<NotificationsNotificationSettingsSettingsOptionsInput>;
};

export type NotificationsNotificationSettingsSettingsOptions = {
  __typename?: 'NotificationsNotificationSettingsSettingsOptions';
  email: Maybe<Scalars['Boolean']['output']>;
  push: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationsNotificationSettingsSettingsOptionsEmail = {
  __typename?: 'NotificationsNotificationSettingsSettingsOptionsEmail';
  email: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationsNotificationSettingsSettingsOptionsEmailInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationsNotificationSettingsSettingsOptionsInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  push?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationsSubscriptionSettingsEmail = {
  __typename?: 'NotificationsSubscriptionSettingsEmail';
  time: Maybe<Scalars['String']['output']>;
  versionId: Maybe<Scalars['Int']['output']>;
};

export type NotificationsSubscriptionSettingsEmailInput = {
  time?: InputMaybe<Scalars['String']['input']>;
  versionId?: InputMaybe<Scalars['Int']['input']>;
};

export type NotificationsSubscriptionSettingsImageEmail = {
  __typename?: 'NotificationsSubscriptionSettingsImageEmail';
  languageTag: Maybe<Scalars['String']['output']>;
  time: Maybe<Scalars['String']['output']>;
};

export type NotificationsSubscriptionSettingsImageEmailInput = {
  languageTag?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationsUnsubscribe = {
  __typename?: 'NotificationsUnsubscribe';
  response: Maybe<NotificationsUnsubscribeResponse>;
};

export type NotificationsUnsubscribeRequestInput = {
  /** Required with type rp_daily (will be the reading plan id) and the pwf_accepts/pwf_comments types (will be the subscription_id); ignored for all other types. Provided by API in email link. */
  data?: InputMaybe<Scalars['Int']['input']>;
  /** A notification settings token from monolith used to auth a bible notification setting update */
  token?: InputMaybe<Scalars['JSON']['input']>;
  /** Type of notification to unsubscribe from. One of badges, comments, friendships, likes, moments, newsletter, pwf_accepts, pwf_comments, reading_plans, rp_daily, votd, votd_image, contact_joins, all. Provided by API in email link with exception of “all” - this will be from unsubscribe all button only. */
  type: NotificationsUnsubscribeType;
};

export type NotificationsUnsubscribeResponse = {
  __typename?: 'NotificationsUnsubscribeResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export enum NotificationsUnsubscribeType {
  ALL = 'ALL',
  BADGES = 'BADGES',
  COMMENTS = 'COMMENTS',
  CONTACT_JOINS = 'CONTACT_JOINS',
  FRIENDSHIPS = 'FRIENDSHIPS',
  LIKES = 'LIKES',
  MOMENTS = 'MOMENTS',
  NEWSLETTER = 'NEWSLETTER',
  PWF_ACCEPTS = 'PWF_ACCEPTS',
  PWF_COMMENTS = 'PWF_COMMENTS',
  READING_PLANS = 'READING_PLANS',
  RP_DAILY = 'RP_DAILY',
  VOTD = 'VOTD',
  VOTD_IMAGE = 'VOTD_IMAGE'
}

export type NotificationsUpdateSettings = {
  __typename?: 'NotificationsUpdateSettings';
  response: Maybe<NotificationsUpdateSettingsResponse>;
};

export type NotificationsUpdateSettingsRequestInput = {
  notificationSettings: NotificationsNotificationSettingsInput;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationsUpdateSettingsResponse = {
  __typename?: 'NotificationsUpdateSettingsResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type NotificationsUpdateVotdSubscription = {
  __typename?: 'NotificationsUpdateVotdSubscription';
  response: Maybe<NotificationsUpdateVotdSubscriptionResponse>;
};

export type NotificationsUpdateVotdSubscriptionRequestInput = {
  email: NotificationsSubscriptionSettingsEmailInput;
  imageEmail?: InputMaybe<NotificationsSubscriptionSettingsImageEmailInput>;
};

export type NotificationsUpdateVotdSubscriptionResponse = {
  __typename?: 'NotificationsUpdateVotdSubscriptionResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type Offline = {
  __typename?: 'Offline';
  allowRedownload: Maybe<Scalars['Boolean']['output']>;
  alwaysAllowUpdates: Maybe<Scalars['Boolean']['output']>;
  build: Maybe<Build>;
  platforms: Maybe<Platforms>;
  requireEmailAgreement: Maybe<Scalars['Boolean']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type Offline2 = {
  __typename?: 'Offline2';
  agreementVersion: Maybe<Scalars['String']['output']>;
  allowRedownload: Maybe<Scalars['Boolean']['output']>;
  alwaysAllowUpdates: Maybe<Scalars['Boolean']['output']>;
  build: Maybe<Build2>;
  platforms: Maybe<Platforms3>;
  requireEmailAgreement: Maybe<Scalars['Boolean']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Order2 {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Order3 {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Order4 {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Order5 {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Order6 {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OrderBy {
  CREATED_DT = 'CREATED_DT',
  DAY = 'DAY'
}

export enum OrderBy2 {
  COMPLETED_DT = 'COMPLETED_DT',
  CREATED_DT = 'CREATED_DT'
}

export type OrganizationsFeaturedPlans = {
  __typename?: 'OrganizationsFeaturedPlans';
  data: Maybe<Array<Maybe<Data8ListItem>>>;
};

export type OrganizationsLanguage = {
  __typename?: 'OrganizationsLanguage';
  /** An iso639 compliant language tag string */
  languageTag: Maybe<Scalars['String']['output']>;
};

export type OrganizationsLanguages = {
  __typename?: 'OrganizationsLanguages';
  data: Maybe<Array<Maybe<OrganizationsLanguage>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type OrganizationsOrganization = {
  __typename?: 'OrganizationsOrganization';
  /** The hex color code for the background color of an organization */
  backgroundColor: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** This is the email of the organization. */
  email: Maybe<Scalars['String']['output']>;
  /** The fully qualified resource name of an organization */
  fqrn: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  locations: Maybe<OrganizationsOrganizationLocations>;
  logo: Maybe<ImagesOrganizationImage>;
  /** This is the name of the organization. */
  name: Maybe<Scalars['String']['output']>;
  parentOrganization: Maybe<ParentOrganization2>;
  /** This is the phone number of the organization. */
  phone: Maybe<Scalars['String']['output']>;
  /** The primary language of the organization represented as a an iso 639 language code */
  primaryLanguage: Maybe<Scalars['String']['output']>;
  profiles: Maybe<OrganizationsOrganizationProfiles>;
  /** This is the timezone of the organization. */
  timezone: Maybe<Scalars['String']['output']>;
  /** This is the website_url of the organization. */
  websiteUrl: Maybe<Scalars['String']['output']>;
};


export type OrganizationsOrganizationLocationsArgs = {
  clientLatitude?: InputMaybe<Scalars['Float']['input']>;
  clientLongitude?: InputMaybe<Scalars['Float']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationsOrganizationProfilesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationsOrganizationJoinInput = {
  /** An enumerator to determine who can view a membership to an organization */
  visibilityPreference?: InputMaybe<VisibilityPreference>;
};

/** This is a specific view of an Organization but still represents an Organization. Here it is combined with its Location Profile if it has one in order to filter/show Organizations relative to their physical location. */
export type OrganizationsOrganizationLocation = {
  __typename?: 'OrganizationsOrganizationLocation';
  administrativeAreaLevel1: Maybe<AdministrativeAreaLevel12>;
  /** The distance from the client's location in miles to this location. */
  distance: Maybe<Scalars['Float']['output']>;
  /** The human-readable address of the organization. */
  formattedAddress: Maybe<Scalars['String']['output']>;
  /** A less specific and more broad field that can be a combination of different regional fields. */
  formattedLocality: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** The angular distance of a location north or south of the earths equator. */
  latitude: Maybe<Scalars['Float']['output']>;
  locality: Maybe<Locality2>;
  /** The angular distance of a location east or west of the meridian. */
  longitude: Maybe<Scalars['Float']['output']>;
  /** The name of the location. */
  name: Maybe<Scalars['String']['output']>;
  /** The textual identifier that uniquely identifies a place using google places api id. */
  placeId: Maybe<Scalars['String']['output']>;
  /** An enumerator to determine the current approval state of a Profile */
  status: Maybe<Status2>;
  /** This is the timezone the organization is located in. */
  timezone: Maybe<Scalars['String']['output']>;
};

export type OrganizationsOrganizationLocations = {
  __typename?: 'OrganizationsOrganizationLocations';
  data: Maybe<Array<Maybe<OrganizationsOrganizationLocation>>>;
};

export type OrganizationsOrganizationMember = {
  __typename?: 'OrganizationsOrganizationMember';
  /** The created date time of the organization. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** Unique identifier (organization_id) */
  organizationId: Maybe<Scalars['String']['output']>;
  /** Unique identifier (user_id) */
  userId: Maybe<Scalars['Float']['output']>;
  /** An enumerator to determine who can view a membership to an organization */
  visibilityPreference: Maybe<VisibilityPreference>;
};

export type OrganizationsOrganizationMembers = {
  __typename?: 'OrganizationsOrganizationMembers';
  data: Maybe<Array<Maybe<Data10ListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the total members that are set to the church profile. */
  totalMembers: Maybe<Scalars['Int']['output']>;
};

export type OrganizationsOrganizationPostsPost = {
  __typename?: 'OrganizationsOrganizationPostsPost';
  /** The body of the post containing html or markdown based on content type tags only bold, italics, links, headings, numbered lists, and bulleted lists. */
  body: Maybe<Scalars['String']['output']>;
  /** The time that this post was created. */
  createdDt: Maybe<Scalars['String']['output']>;
  /** The id of the post. */
  id: Maybe<Scalars['String']['output']>;
  /** The organization id of the organization that this post belongs to. */
  organizationId: Maybe<Scalars['String']['output']>;
  /** The title of the post. */
  title: Maybe<Scalars['String']['output']>;
  /** The time that this post was updated. */
  updatedDt: Maybe<Scalars['String']['output']>;
};

export type OrganizationsOrganizationPostsPostAction = {
  __typename?: 'OrganizationsOrganizationPostsPostAction';
  /** Count of actions */
  count: Maybe<Scalars['Int']['output']>;
  /** Value of action */
  taken: Maybe<Scalars['Boolean']['output']>;
  /** An enumerator to determine action type */
  type: Maybe<Type2>;
};

export type OrganizationsOrganizationPostsPostActionRequestInput = {
  /** An enumerator to determine action type */
  type: Type2;
};

export type OrganizationsOrganizationPostsPostActions = {
  __typename?: 'OrganizationsOrganizationPostsPostActions';
  data: Maybe<Array<Maybe<OrganizationsOrganizationPostsPostAction>>>;
};

export type OrganizationsOrganizationPostsPosts = {
  __typename?: 'OrganizationsOrganizationPostsPosts';
  data: Maybe<Array<Maybe<OrganizationsOrganizationPostsPost>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type OrganizationsOrganizationProfiles = {
  __typename?: 'OrganizationsOrganizationProfiles';
  data: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
};

export type OrganizationsOrganizations = {
  __typename?: 'OrganizationsOrganizations';
  data: Maybe<Array<Maybe<OrganizationsOrganization>>>;
};

export type OrganizationsServiceTimes = {
  __typename?: 'OrganizationsServiceTimes';
  data: Maybe<Array<Maybe<Data9ListItem>>>;
};

export enum Orientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT'
}

export enum Orientation2 {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
  _ = '_'
}

/** gcs presign parameters */
export type Params = {
  __typename?: 'Params';
  aWSAccessKeyId: Maybe<Scalars['String']['output']>;
  acl: Maybe<Scalars['String']['output']>;
  bucket: Maybe<Scalars['String']['output']>;
  googleAccessId: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  policy: Maybe<Scalars['String']['output']>;
  signature: Maybe<Scalars['String']['output']>;
};

export type ParentOrganization = {
  __typename?: 'ParentOrganization';
  /** The id of the parent organization. */
  id: Maybe<Scalars['String']['output']>;
  /** The name of the parent organization. */
  name: Maybe<Scalars['String']['output']>;
};

export type ParentOrganization2 = {
  __typename?: 'ParentOrganization2';
  id: Maybe<Scalars['String']['output']>;
  /** This is the name of the parent organization. */
  name: Maybe<Scalars['String']['output']>;
};

export type ParticipantsParticipant = {
  __typename?: 'ParticipantsParticipant';
  id: Maybe<Scalars['Int']['output']>;
  status: Maybe<Schema2>;
};

export type ParticipantsParticipantJoinInput = {
  token: Scalars['String']['input'];
};

export type ParticipantsParticipantUpdateInput = {
  status: Schema2;
};

export type ParticipantsParticipants = {
  __typename?: 'ParticipantsParticipants';
  data: Maybe<Array<Maybe<ParticipantsParticipant>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type ParticipantsParticipantsCreate = {
  __typename?: 'ParticipantsParticipantsCreate';
  data: Array<Maybe<Data5ListItem>>;
};

export type ParticipantsParticipantsCreateInput = {
  data: Array<InputMaybe<Data5ListItemInput>>;
};

export type Partners = {
  __typename?: 'Partners';
  /** A list of Partner resource objects */
  data: Maybe<Array<Maybe<Data7ListItem>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent>;
};

export type Partners2 = {
  __typename?: 'Partners2';
  /** A list of Partner resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Items2>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  /** Value to be charged in string form */
  amount: Maybe<Scalars['String']['output']>;
  /** Unique ID of Currency code */
  currencyId: Maybe<Scalars['Int']['output']>;
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId: Maybe<FrequencyId>;
  /** Unique ID of Fund */
  fundId: Maybe<Scalars['Int']['output']>;
  /** Success message */
  message: Maybe<Scalars['String']['output']>;
  /** the next payment date of the new subscription in ISO-8601 format if a subscription was created */
  nextPaymentDate: Maybe<Scalars['String']['output']>;
  /** Unique ID of the payment method to use (Can be left out if using PayPal). Client must provide either this OR payment_token field NOT BOTH. */
  paymentMethodId: Maybe<Scalars['Int']['output']>;
  /** ID of the new subscription in Rock if a subscription was created */
  subscriptionId: Maybe<Scalars['Int']['output']>;
  /** Success title */
  title: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** Whether or not this is the default payment method. */
  default: Maybe<Scalars['Boolean']['output']>;
  /** Label to display for this payment method. */
  displayLabel: Maybe<Scalars['String']['output']>;
  /** when does this payment method expire? */
  expirationLabel: Maybe<Scalars['String']['output']>;
  /** Payment gateway for this payment method. */
  gateway: Maybe<Scalars['String']['output']>;
  /** Unique id for a PaymentMethod */
  id: Maybe<Scalars['Int']['output']>;
  /** Last 4 digits of the payment method */
  last4: Maybe<Scalars['String']['output']>;
  /** Kind of payment method this is. */
  paymentMethodType: Maybe<Scalars['String']['output']>;
  /** Brand of payment method type. */
  paymentType: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodPostInput = {
  /** The first name of the user (required only in the case of guest giving in order to populate Rock) */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The last name of the user (required only in the case of guest giving in order to populate Rock) */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Unique token received from the gateway (Stripe or PayPal) */
  paymentToken: Scalars['String']['input'];
};

export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  data: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type PaymentPostInput = {
  /** Value to be charged in string form */
  amount: Scalars['String']['input'];
  /** When the payment should take place */
  chargeDt: Scalars['String']['input'];
  /** The IANA timezone name of the timezone in which the charge_dt applies */
  chargeTz: Scalars['String']['input'];
  /** Unique ID of Currency code */
  currencyId: Scalars['Int']['input'];
  /** The first name of the user (required only in the case of guest giving in order to populate Rock) */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId: FrequencyId;
  /** Unique ID of Fund */
  fundId: Scalars['Int']['input'];
  /** The last name of the user (required only in the case of guest giving in order to populate Rock) */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the payment method to use (Can be left out if using PayPal). Client must provide either this OR payment_token field NOT BOTH. */
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique token for the desired dynamic payment method (currently PayPal or Apple Pay).  Client must provide either this OR payment_method_id field NOT BOTH. */
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  /** The source device of a gift. */
  transactionSource?: InputMaybe<TransactionSource>;
};

export type PaypalCheckout = {
  __typename?: 'PaypalCheckout';
  /** PayPal Checkout URL to which client should be directed */
  url: Maybe<Scalars['String']['output']>;
};

export type PaypalCheckoutPostInput = {
  /** Value to be charged. Only USD is currently supported for PayPal and USD is assumed when client makes a request. */
  amount: Scalars['String']['input'];
  /** URL to be returned if PayPal donation is not approved by user */
  cancelUrl: Scalars['String']['input'];
  /** When the payment should take place */
  chargeDt: Scalars['String']['input'];
  /** The IANA timezone name of the timezone in which the charge_dt applies */
  chargeTz: Scalars['String']['input'];
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId: FrequencyId;
  /** Unique ID of Fund */
  fundId: Scalars['Int']['input'];
  /** URL to be returned to after user sets up PayPal donation */
  returnUrl: Scalars['String']['input'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String']['output'];
};

export type PermissionInput = {
  id: Scalars['String']['input'];
};

export type Permissions = {
  __typename?: 'Permissions';
  data: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type PermissionsBatch = {
  __typename?: 'PermissionsBatch';
  data: Maybe<Array<Maybe<Data3ListItem>>>;
};

export type Plan = {
  __typename?: 'Plan';
  about: Maybe<ReadingPlansPlanAbout>;
  canRate: Maybe<Scalars['Boolean']['output']>;
  completedDt: Maybe<Scalars['String']['output']>;
  completionPercentage: Maybe<Scalars['Int']['output']>;
  copyright: Maybe<ReadingPlansPlanCopyright>;
  createdDt: Maybe<Scalars['String']['output']>;
  defaultStartDt: Maybe<Scalars['String']['output']>;
  emailDelivery: Maybe<Scalars['String']['output']>;
  emailDeliveryVersionId: Maybe<Scalars['Int']['output']>;
  endDt: Maybe<Scalars['String']['output']>;
  formattedLength: Maybe<Scalars['JSON']['output']>;
  hasDevotionalAudio: Maybe<Scalars['Boolean']['output']>;
  /** plan id */
  id: Maybe<Scalars['Int']['output']>;
  images: Maybe<Array<Maybe<ReadingPlansPlanImage>>>;
  languageTags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Maybe<Scalars['JSON']['output']>;
  private: Maybe<Scalars['Boolean']['output']>;
  publisherUrl: Maybe<Scalars['String']['output']>;
  rating: Maybe<Scalars['Int']['output']>;
  shortUrl: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  startDay: Maybe<Scalars['Int']['output']>;
  startDt: Maybe<Scalars['String']['output']>;
  subscribedDt: Maybe<Scalars['String']['output']>;
  subscriptionId: Maybe<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
  totalDays: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  userAvatarUrl: Maybe<Scalars['JSON']['output']>;
  userId: Maybe<Scalars['Int']['output']>;
  username: Maybe<Scalars['String']['output']>;
  versionId: Maybe<Scalars['Int']['output']>;
};

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  completedDt: Maybe<Scalars['String']['output']>;
  createdDt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  plan: Maybe<Plan>;
  planId: Maybe<Scalars['Int']['output']>;
  privacy: Maybe<Privacy>;
  progress: Maybe<PlanSubscriptionProgress>;
  startDt: Maybe<Scalars['String']['output']>;
  togetherId: Maybe<Scalars['Int']['output']>;
};

export type PlanSubscriptionProgress = {
  __typename?: 'PlanSubscriptionProgress';
  days: Maybe<Array<Maybe<ProgressProgressDay>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export enum PlanSubscriptionStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  HOST = 'HOST',
  INVITED = 'INVITED',
  KICKED = 'KICKED'
}

export type PlanSubscriptionUpdateInput = {
  completedDt?: InputMaybe<Scalars['String']['input']>;
  createdDt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  privacy?: InputMaybe<Privacy>;
  startDt?: InputMaybe<Scalars['String']['input']>;
  togetherId?: InputMaybe<Scalars['Int']['input']>;
};

export type PlanSubscriptions = {
  __typename?: 'PlanSubscriptions';
  data: Maybe<Array<Maybe<PlanSubscription>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type PlanSubscriptionsCreateInput = {
  createdDt?: InputMaybe<Scalars['String']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['Int']['input'];
  privacy: Privacy;
  startDt: Scalars['String']['input'];
  together?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlanTogether = {
  __typename?: 'PlanTogether';
  archived: Maybe<Scalars['Boolean']['output']>;
  createdDt: Maybe<Scalars['String']['output']>;
  hostUserId: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  plan: Maybe<Plan>;
  planId: Maybe<Scalars['Int']['output']>;
  publicShare: Maybe<Scalars['String']['output']>;
  redirect: Maybe<Scalars['String']['output']>;
  startDt: Maybe<Scalars['String']['output']>;
  token: Maybe<Scalars['String']['output']>;
};

export type PlanTogethers = {
  __typename?: 'PlanTogethers';
  data: Maybe<Array<Maybe<PlanTogether>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type Plans = {
  __typename?: 'Plans';
  /** A list of Plan resource objects */
  data: Maybe<Array<Maybe<Plan>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  sizes: Maybe<Sizes>;
  url: Maybe<Scalars['String']['output']>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent2>;
};

export type Plans2 = {
  __typename?: 'Plans2';
  /** A list of Plan resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Items3>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export type Platforms = {
  __typename?: 'Platforms';
  android: Maybe<Scalars['Boolean']['output']>;
  blackberry: Maybe<Scalars['Boolean']['output']>;
  ios: Maybe<Scalars['Boolean']['output']>;
  win8: Maybe<Scalars['Boolean']['output']>;
  wp7: Maybe<Scalars['Boolean']['output']>;
};

export type Platforms2 = {
  __typename?: 'Platforms2';
  android: Maybe<Scalars['Boolean']['output']>;
  blackberry: Maybe<Scalars['Boolean']['output']>;
  facebook: Maybe<Scalars['Boolean']['output']>;
  ios: Maybe<Scalars['Boolean']['output']>;
  win8: Maybe<Scalars['Boolean']['output']>;
  wp7: Maybe<Scalars['Boolean']['output']>;
};

export type Platforms3 = {
  __typename?: 'Platforms3';
  android: Maybe<Scalars['Boolean']['output']>;
  blackberry: Maybe<Scalars['Boolean']['output']>;
  ios: Maybe<Scalars['Boolean']['output']>;
  win8: Maybe<Scalars['Boolean']['output']>;
  wp7: Maybe<Scalars['Boolean']['output']>;
};

export type PlaylistItem = {
  __typename?: 'PlaylistItem';
  /** Deeplink to playlist view */
  actionUrl: Maybe<Scalars['String']['output']>;
  audioBibles: Maybe<Array<AudioBibleData>>;
  /**
   * This is an optional field of plan or video resource ids.
   * The ids in this array are limited to 50.
   * If multiple pages of ids are needed then use
   * /4.0/playlists/{playlist_id}?page=2&page_size=50
   *
   */
  externalIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id: Maybe<Scalars['Int']['output']>;
  /** An enumerator of the different formats or types of media */
  mediaType: Maybe<Schema>;
  plans: Maybe<Array<Plan>>;
  /** An enumerator of the different formats or types of media */
  playlistType: Maybe<Schema2>;
  textBibles: Maybe<Array<TextVersion>>;
  /** The descriptor of the playlist returned as a translated string */
  title: Maybe<Scalars['String']['output']>;
  videos: Maybe<Array<Video>>;
};

/** This returns an object containing the ordered array of playlist ids. Pagination has been removed from this collection because all ids will always be needed. */
export type PlaylistsOrder = {
  __typename?: 'PlaylistsOrder';
  playlistsIds: Array<Maybe<Scalars['Int']['output']>>;
};

/** This returns an object containing the ordered array of playlist ids. Pagination has been removed from this collection because all ids will always be needed. */
export type PlaylistsOrderInput = {
  playlistsIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type Podcasts = {
  __typename?: 'Podcasts';
  /** A list of Podcast resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Items4>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type Policy = {
  __typename?: 'Policy';
  bindings: Array<Maybe<Bindings2ListItem>>;
  id: Scalars['String']['output'];
  resource: Scalars['String']['output'];
};

export type Principal = {
  __typename?: 'Principal';
  data: Maybe<Array<Maybe<RoleBinding>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type Principals = {
  __typename?: 'Principals';
  data: Maybe<Array<Maybe<PrincipalsListItem>>>;
};

export type PrincipalsListItem = {
  __typename?: 'PrincipalsListItem';
  bindings: Array<Maybe<RoleBinding>>;
  principal: Scalars['String']['output'];
};

export enum Privacy {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum ProfileType {
  ADDRESS = 'ADDRESS',
  BIBLE = 'BIBLE',
  CHURCH = 'CHURCH',
  CONTENT = 'CONTENT'
}

export enum ProfileType2 {
  ADDRESS = 'ADDRESS',
  BIBLE = 'BIBLE',
  CHURCH = 'CHURCH',
  CONTENT = 'CONTENT',
  GIVING = 'GIVING'
}

export type ProgressProgressDay = {
  __typename?: 'ProgressProgressDay';
  complete: Maybe<Scalars['Boolean']['output']>;
  day: Maybe<Scalars['Int']['output']>;
  partial: Maybe<Array<Maybe<Scalars['Boolean']['output']>>>;
};

export type ProgressProgressDayUpdateInput = {
  complete: Scalars['Boolean']['input'];
  partial?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  updatedDt: Scalars['String']['input'];
};

export type Publisher = {
  __typename?: 'Publisher';
  /** An optional description of the publisher. */
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the publisher. */
  id: Maybe<Scalars['Int']['output']>;
  language: Maybe<Scalars['String']['output']>;
  /** The translated version of the publisher's name. */
  localName: Maybe<Scalars['String']['output']>;
  /** The name of the publisher. */
  name: Maybe<Scalars['String']['output']>;
  trackingId: Maybe<Scalars['String']['output']>;
  /** A link to the publisher's site. */
  url: Maybe<Scalars['String']['output']>;
};

export type PublisherDeleteRequestInput = {
  language: Scalars['String']['input'];
};

export type PublisherInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PublisherPost = {
  __typename?: 'PublisherPost';
  createdDt: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
  trackingId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type PublisherPostInput = {
  createdDt: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  language: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PublisherPresignResponse = {
  __typename?: 'PublisherPresignResponse';
  /** id */
  id: Maybe<Scalars['Int']['output']>;
  /** presign parameters */
  params: Maybe<Params>;
  /** Thumbnail upload URL */
  uploadUrl: Maybe<Scalars['String']['output']>;
};

export type PublisherReserveResponse = {
  __typename?: 'PublisherReserveResponse';
  id: Maybe<Scalars['Int']['output']>;
};

export type Publishers = {
  __typename?: 'Publishers';
  data: Maybe<Array<Maybe<Publisher>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type PwfCommentsInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  push?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PwfRemindersInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Request to initiate the avatar image upload process.
   *
   * Equivalent to GET /3.1/avatar_upload.json
   */
  avatarUpload: Maybe<UsersAvatarAvatarUpload>;
  /**
   * This endpoint has been deprecated in favor of /4.1/suggestions and will return 204.
   *
   * Equivalent to GET /4.0/suggestions
   */
  deprecatedGetSuggestions: Maybe<Scalars['JSON']['output']>;
  /**
   * This is an authenticated list of achievements.
   *
   * Equivalent to GET /4.0/achievements
   */
  getAchievements: Maybe<Achievements>;
  /**
   * View an Activity collection
   *
   * Equivalent to GET /4.0/together/{together_id}/activities
   */
  getActivities: Maybe<ActivitiesActivities>;
  /**
   * View an Activity resource
   *
   * Equivalent to GET /4.0/together/{together_id}/activities/{activity_id}
   */
  getActivity: Maybe<ActivitiesActivity>;
  /**
   * Get an account.
   *
   * Equivalent to GET /admin/4.0/accounts/{account_id}
   */
  getAdminAccount: Maybe<AdminAccountsAccount>;
  /**
   * This is an authenticated request to see all accounts.
   *
   * Equivalent to GET /admin/4.0/accounts
   */
  getAdminAccounts: Maybe<AdminAccountsAccounts>;
  /**
   * Get all accounts across organizations.
   *
   * Equivalent to GET /admin/4.0/organizations/-/accounts
   */
  getAdminAllOrganizationAccounts: Maybe<AdminOrganizationsAccountsAccounts>;
  /**
   * Get a content collection.
   *
   * Equivalent to GET /admin/4.0/content
   */
  getAdminContent: Maybe<AdminContentContentCollection>;
  /**
   * Gets every organization who has granted a certain piece of content.
   *
   * Equivalent to GET /admin/4.0/{content_type}_grants/{external_id}/organizations
   */
  getAdminContentGrantOrganizations: Maybe<AdminContentTypeGrantsOrganizations>;
  /**
   * Get a content relationship.
   *
   * Equivalent to GET /admin/4.0/content/{content_id}/relationships/{relationship_id}
   */
  getAdminContentRelationship: Maybe<AdminContentRelationshipUpserted>;
  /**
   * Return all relationship objects for a given content resource specific to the type of media
   *
   * Equivalent to GET /admin/4.0/content/{media_type}/{external_content_id}/relationships
   */
  getAdminContentRelationships: Maybe<AdminContentRelationships>;
  /**
   * Get a resource of a content item.
   *
   * Equivalent to GET /admin/4.0/content/{content_id}
   */
  getAdminContentResource: Maybe<AdminContentContentResource>;
  /**
   * Admin fetch an event record by id.
   *
   * Equivalent to GET /admin/4.0/events/{event_id}
   */
  getAdminEvent: Maybe<AdminEventsAdminEvent>;
  /**
   * Returns the organizations events.
   *
   * Equivalent to GET /admin/4.0/events
   */
  getAdminEvents: Maybe<AdminEventsAdminEvents>;
  /**
   * Get an export resource
   *
   * Equivalent to GET /admin/4.0/exports/{export_id}
   */
  getAdminExport: Maybe<Scalars['JSON']['output']>;
  /**
   * Get a collection of exports for a given organization
   *
   * Equivalent to GET /admin/4.0/exports
   */
  getAdminExports: Maybe<AdminExports>;
  /**
   * Return all the languages an Organization has content in
   *
   * Equivalent to GET /4.0/organizations/{path_organization_id}/languages
   */
  getAdminLanguages: Maybe<OrganizationsLanguages>;
  /**
   * Get an organization resource
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}
   */
  getAdminOrganization: Maybe<AdminOrganizationsOrganization>;
  /**
   * Get an account for an organization.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/accounts/{account_id}
   */
  getAdminOrganizationAccount: Maybe<AdminOrganizationsAccountsAccount>;
  /**
   * Get all accounts for an organization.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/accounts
   */
  getAdminOrganizationAccounts: Maybe<AdminOrganizationsAccountsAccounts>;
  /**
   * Get the versions an organization has been granted access to.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/bible_version_grants
   */
  getAdminOrganizationBibleVersionGrants: Maybe<AdminOrganizationsBibleVersionGrants>;
  /**
   * Get the content an organization has been granted access to.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/content_grants
   */
  getAdminOrganizationContentGrants: Maybe<AdminOrganizationsContentGrants>;
  /**
   * get a collection of featured plans.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/featured_plans
   */
  getAdminOrganizationFeaturedPlans: Maybe<AdminOrganizationsFeaturedPlans>;
  /**
   * Get an organizations followers
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/followers
   */
  getAdminOrganizationFollowers: Maybe<AdminOrganizationsOrganizationFollowersCollection>;
  /**
   * Get an organization post
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/posts/{post_id}
   */
  getAdminOrganizationPost: Maybe<AdminOrganizationsOrganizationPostsPost>;
  /**
   * Get a collection of organization posts
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/posts
   */
  getAdminOrganizationPosts: Maybe<AdminOrganizationsOrganizationPostsPosts>;
  /**
   * Get all profiles for an organization, same as mobile client endpoint for now.
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/profiles
   */
  getAdminOrganizationProfiles: Maybe<OrganizationsOrganizationProfiles>;
  /**
   * Admin view of organizations.
   *
   * Equivalent to GET /admin/4.0/organizations
   */
  getAdminOrganizations: Maybe<AdminOrganizationsOrganizations>;
  /**
   * Get a playlist
   *
   * Equivalent to GET /admin/4.0/playlists/{playlist_id}
   */
  getAdminPlaylist: Maybe<AdminPlaylistsPlaylist>;
  /**
   * Return all the playlists tied to an organization
   *
   * Equivalent to GET /admin/4.0/playlists
   */
  getAdminPlaylists: Maybe<AdminPlaylistsPlaylists>;
  /**
   * Returns a collection of service times for the organization
   *
   * Equivalent to GET /admin/4.0/organizations/{organization_id}/service_times
   */
  getAdminServiceTimes: Maybe<AdminOrganizationsServiceTimes>;
  /**
   * Admin view of a User
   *
   * Equivalent to GET /admin/4.0/users/{user_id}
   */
  getAdminUser: Maybe<AdminUsersUser>;
  getAudioBibleVersion: Maybe<AudioBibleData>;
  /**
   * Get a chapter object of a Bible version.
   *
   * Equivalent to GET /3.1/chapter.json
   */
  getBibleChapter: Maybe<BibleChapter>;
  /**
   * Get a list of bible locale headers from bible.youversionapi.com/3.2/configuration.json.
   *
   * Equivalent to GET /4.0/locales/bible
   */
  getBibleLocaleHeaders: Maybe<BibleLocaleHeaders>;
  /**
   * Get the verse for the specified id and reference
   *
   * Equivalent to GET /3.1/verse.json
   */
  getBibleVerse: Maybe<BibleVerse>;
  /**
   * Get one or more verses/verse ranges
   *
   * Equivalent to GET /3.1/verses.json
   */
  getBibleVerses: Maybe<BibleVerses>;
  /**
   * Get a version of a Bible
   *
   * Equivalent to GET /3.1/version.json
   */
  getBibleVersion: Maybe<BibleVersion>;
  /**
   * Get a list of Bible versions
   *
   * Equivalent to GET /3.1/versions.json
   */
  getBibleVersions: Maybe<BibleVersions>;
  /**
   * This API endpoint returns a binding resource associated with a resource_id
   *
   * Equivalent to GET /policies/{resource_id}/bindings/{binding_id}
   */
  getBinding: Maybe<RoleBinding>;
  /**
   * This API endpoint returns a collection of all bindings for a resource.
   *
   * Equivalent to GET /policies/{resource_id}/bindings
   */
  getBindings: Maybe<RoleBindings>;
  /**
   * This is an authenticated list of challenges.
   *
   * Equivalent to GET /4.0/challenges
   */
  getChallenges: Maybe<Challenges>;
  /**
   * View a list of chapters that have related videos
   *
   * Equivalent to GET /4.0/chapters
   */
  getChapters: Maybe<Chapters>;
  /**
   * This API endpoint cgshecks if the given principal has authorization to perform the given action on the given resource.
   *
   * Equivalent to POST /check
   */
  getCheck: Maybe<Check>;
  /**
   * This is an authenticated list of collectibles.
   *
   * Equivalent to GET /4.0/collectibles
   */
  getCollectibles: Maybe<Collectibles>;
  /**
   * Get video collection
   *
   * Equivalent to GET /4.0/collections/{id}
   */
  getCollection: Maybe<VideoCollection>;
  /**
   * View a collection of video collections
   *
   * Equivalent to GET /4.0/collections
   */
  getCollections: Maybe<Collections>;
  /**
   * Returns a list of default colors plus previously used colors by the user when creating moments. Not authenticating will give you a list of default colors, if it's an authenticated request, it will prepend the user's previously used colors to the beginning of the list ordered by how recent they were used.
   *
   * Equivalent to GET /3.1/colors.json
   */
  getColors: Maybe<MomentsColors>;
  /**
   * Get view of a completed reading plans.
   *
   * Equivalent to GET /3.1/completed.json
   */
  getCompletedPlans: Maybe<CompletedReadingPlansPlan>;
  /**
   * Get the configuration data for this API
   *
   * Equivalent to GET /3.1/configuration.json
   */
  getConfiguration: Maybe<BibleConfiguration>;
  /**
   * Get a content partner
   *
   * Equivalent to GET /admin/4.0/content_partners/{content_partner_id}
   */
  getContentPartner: Maybe<AdminOrganizationsContentPartner>;
  /**
   * Get all content partners
   *
   * Equivalent to GET /admin/4.0/content_partners
   */
  getContentPartners: Maybe<AdminOrganizationsContentPartners>;
  /**
   * Returns a crowdin API key for the CMS
   *
   * Equivalent to GET /4.0/crowdin_key
   */
  getCrowdinKey: Maybe<CrowdinKeyCrowdinKey>;
  /**
   * Helpful for displaying a list of user currencies and formatting relevant details.
   *
   * Equivalent to GET /4.0/currencies
   */
  getCurrencies: Maybe<Currencies>;
  /**
   * This is an authenticated request to acquire a users data from prayer , reading plans etc..."
   *
   * Equivalent to GET /4.0/download
   */
  getDownloads: Maybe<Scalars['String']['output']>;
  /**
   * Fetch an event record by id.
   *
   * Equivalent to GET /4.0/events/{event_id}
   */
  getEvent: Maybe<EventsEvent>;
  /**
   * Fetches the organizations closest event
   *
   * Equivalent to GET /4.0/events
   */
  getEvents: Maybe<EventsEvents>;
  /**
   * This API endpoint evaluates the resource and principal provided and returns permissions associated.
   *
   * Equivalent to POST /expand
   */
  getExpand: Maybe<Permissions>;
  /**
   * This API endpoint evaluates the resources and principal provided and returns permissions associated.
   *
   * Equivalent to POST /expand:batchPost
   */
  getExpandBatch: Maybe<PermissionsBatch>;
  /**
   * Informs the client if the User is following this Organization
   *
   * Equivalent to GET /4.0/following/{organization_id}
   */
  getFollowing: Maybe<FollowingFollowing>;
  /**
   * Used to get list of possible giving frequencies displayed i.e. recurring, one-time, etc.
   *
   * Equivalent to GET /4.0/frequencies
   */
  getFrequencies: Maybe<Frequencies>;
  /**
   * This is an authenticated count of the max friends a user can have.
   *
   * Equivalent to GET /4.0/{user_id}/friends_limit
   */
  getFriendsLimit: Maybe<FriendsLimit>;
  /**
   * Helpful for displaying a list of fund types.
   *
   * Equivalent to GET /4.0/funds
   */
  getFunds: Maybe<Funds>;
  /**
   * configurations like 'Stripe instance Id' can be fetched from the client and used to communicate with Stripe
   *
   * Equivalent to GET /4.0/configuration
   */
  getGivingConfiguration: Maybe<GivingConfiguration>;
  /**
   * Fetch the url to download a yearly giving statement from the cloud
   *
   * Equivalent to GET /4.0/giving-statements/{statement_year}
   */
  getGivingStatement: Maybe<GivingStatement>;
  getGlobalOrganizationRoles: Array<GlobalRole>;
  /**
   * This is an unauthenticated endpoint only for Kubernetes probe usage.
   *
   * Equivalent to GET /health-check
   */
  getHealthcheck: Maybe<Scalars['JSON']['output']>;
  /**
   * Retrieve a user's transaction history for a given year and defaults to the current year's transactions
   *
   * Equivalent to GET /4.0/history
   */
  getHistory: Maybe<DonationRecords>;
  /**
   * View incoming friendship requests
   *
   * Equivalent to GET /3.1/incoming.json
   */
  getIncomingFriendships: Maybe<FriendshipsIncoming>;
  /**
   * Return a list of 100 random interactions as a sample set of interactions that may have been funded by a user's giving. This is not paged, because results are randomized, and requests are not repeatable. Each user gets a *different* subset of 100 interactions. Current implementation is for clients to cache this data for a set window of time before requesting a fresh set _only_ if the user stayed on the screen that displays the interactions.
   *
   * Equivalent to GET /4.0/interactions
   */
  getInteractions: Maybe<Interactions>;
  /**
   * This is an authenticated single kid resource available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}
   */
  getKid: Maybe<Kid>;
  /**
   * This is an authenticated single kid achievement resource available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/achievements/{achievement_id}
   */
  getKidAchievement: Maybe<KidsAchievement>;
  /**
   * This is an authenticated list of a kid's achievements that are available for your interaction.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/achievements
   */
  getKidAchievements: Maybe<KidsAchievements>;
  /**
   * This is an authenticated single kid challenge resource available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/challenges/{challenge_id}
   */
  getKidChallenge: Maybe<KidsChallengeGet>;
  /**
   * This is an authenticated list of a kid's challenges that are available for your interaction.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/challenges
   */
  getKidChallenges: Maybe<KidsChallenges>;
  /**
   * This is an authenticated single kid collectible-set available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/collectible-set
   */
  getKidCollectibleSet: Maybe<KidCollectibleSet>;
  /**
   * This is an authenticated resource get of kids question available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/questions/{question_id}
   */
  getKidQuestion: Maybe<KidsQuestion>;
  /**
   * This is an authenticated list of a kid's questions that are available for your interaction.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/questions
   */
  getKidQuestions: Maybe<KidsQuestions>;
  /**
   * This is an authenticated collection get of kids stories available for retrieval.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/stories
   */
  getKidStories: Maybe<KidsStories>;
  /**
   * This is an authenticated get request of a kids story resource.
   *
   * Equivalent to GET /4.0/kids/{kid_id}/stories/{story_id}
   */
  getKidStory: Maybe<KidsStoryGet>;
  /**
   * Method for retrieving a user's settings.
   *
   * Equivalent to GET /4.0/settings
   */
  getKidUserSettings: Maybe<Setting>;
  /**
   * This is an authenticated list of kids.
   *
   * Equivalent to GET /4.0/kids
   */
  getKids: Maybe<Kids>;
  /**
   * This is an authenticated get request for the digest email.
   *
   * Equivalent to GET /4.0/kids/{parent_id}/digest
   */
  getKidsDigest: Maybe<KidsDigest>;
  /**
   * Who: API devs that want to see what languages are supported. What: A specific language resource object by id. Why: To view details of a language for various plugins, scripts, etc How: HTTP GET rest call
   *
   * Equivalent to GET /4.0/languages/{language_id}
   */
  getLanguage: Maybe<Language>;
  /**
   * Who: API devs that want to view a snippet by language & id. What: A specific snippet resource object by language & id. Why: To view details of a snippet for various plugins, scripts, etc How: HTTP GET rest call
   *
   * Equivalent to GET /4.0/languages/{language_id}/snippets/{snippet_id}
   */
  getLanguageSnippet: Maybe<Snippet>;
  /**
   * Who: API devs that want to see what snippets are available for a language. What: A list of snippets for a given language. Why: To view all snippets of a language for various plugins, scripts, etc How: HTTP GET rest call
   *
   * Equivalent to GET /4.0/languages/{language_id}/snippets
   */
  getLanguageSnippets: Maybe<Snippets>;
  /**
   * View the collection of available languages for stories
   *
   * Equivalent to GET /4.0/languages
   */
  getLanguages: Maybe<LanguagesLanguages>;
  /**
   * View an the most recently created comment for each day.
   *
   * Equivalent to GET /4.0/together/{together_id}/comments:last_per_day
   */
  getLastCommentPerDay: Maybe<CommentsLastPerDay>;
  /**
   * Return a collection of latest playlists
   *
   * Equivalent to GET /4.0/playlists:latest
   */
  getLatestPlaylist: Maybe<ContentLatestPlaylists>;
  /**
   * View an individual lesson.
   *
   * Equivalent to GET /4.0/lessons/{id}
   */
  getLesson: Maybe<LessonsLesson>;
  /**
   * View an individual translation of a lesson
   *
   * Equivalent to GET /4.0/lessons/{id}/translations/{language_tag}
   */
  getLessonTranslation: Maybe<LessonTranslationsTranslation>;
  /**
   * View a list of translation objects for a given lesson
   *
   * Equivalent to GET /4.0/lessons/{id}/translations
   */
  getLessonTranslations: Maybe<LessonTranslationsTranslations>;
  /**
   * View a collection of `lessons`
   *
   * Equivalent to GET /4.0/lessons
   */
  getLessons: Maybe<LessonsLessons>;
  /**
   * Get a list of locale headers for the given locales and platform. Default platform is determined from the `User-Agent` header. If given empty list of locales, this will return a list of locale headers for the default locales.
   *
   * Equivalent to GET /4.0/locales
   */
  getLocaleHeaders: Maybe<LocaleHeaders>;
  getLocalizedChapterReferences: Maybe<Array<Maybe<BibleChapter>>>;
  getLocalizedVerseReferences: Maybe<Array<Maybe<BibleVerses>>>;
  /**
   * This is an authenticated request to acquire a users data.
   *
   * Equivalent to GET /4.0/me
   */
  getMeData: Maybe<UserV4>;
  /**
   * View a collection of `moduleTypes`
   *
   * Equivalent to GET /4.0/moduleTypes
   */
  getModuleTypes: Maybe<ModuleTypesModuleTypes>;
  /**
   * View a collection of `modules`
   *
   * Equivalent to GET /4.0/modules
   */
  getModules: Maybe<ModulesModules>;
  /**
   * Returns numbers calculated from the authenticating user's total dollar number amounts without specifying the dollar amount directly, as the amount may be somewhat out of sync with reality at any given time.
   *
   * Equivalent to GET /4.0/impacts/me
   */
  getMyImpact: Maybe<MyImpact>;
  /**
   * Get all the notification settings for a user. This used to be on the users api but has been moved to notifications.
   *
   * Equivalent to GET /3.1/settings.json
   */
  getNotificationSettings: Maybe<NotificationsGetSettings>;
  /**
   * Get an organization resource
   *
   * Equivalent to GET /4.0/organizations/{organization_id}
   */
  getOrganization: Maybe<OrganizationsOrganization>;
  /**
   * Get active featured plans for an organization and its parent organization if applicable.
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/featured_plans
   */
  getOrganizationFeaturedPlans: Maybe<OrganizationsFeaturedPlans>;
  /**
   * Get a member of an organization
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/members/{user_id}
   */
  getOrganizationMember: Maybe<OrganizationsOrganizationMember>;
  /**
   * Get a collection of members of an organization
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/members
   */
  getOrganizationMembers: Maybe<OrganizationsOrganizationMembers>;
  /**
   * Get an organization post
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/posts/{post_id}
   */
  getOrganizationPost: Maybe<OrganizationsOrganizationPostsPost>;
  /**
   * Get a collection of actions for a post
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/posts/{post_id}/actions
   */
  getOrganizationPostActions: Maybe<OrganizationsOrganizationPostsPostActions>;
  /**
   * Get a collection of the most recent organization posts
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/posts
   */
  getOrganizationPosts: Maybe<OrganizationsOrganizationPostsPosts>;
  /**
   * Get the profiles for organization resource
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/profiles
   */
  getOrganizationProfiles: Maybe<OrganizationsOrganizationProfiles>;
  /**
   * Get a collection of Locations for a parent organization
   *
   * Equivalent to GET /4.0/organizations/{parent_id_path}/locations
   */
  getOrganizationsOrganizationLocations: Maybe<OrganizationsOrganizationLocations>;
  /**
   * Get the order in which playlists will be displayed for a certain organization and language tag
   *
   * Equivalent to GET /admin/4.0/organizations/{path_organization_id}/playlist_orders/{language_tag}
   */
  getOrganizationsPlaylistsOrder: Maybe<AdminPlaylistOrder>;
  /**
   * View a participant resource
   *
   * Equivalent to GET /4.0/together/{together_id}/participants/{user_id}
   */
  getParticipant: Maybe<ParticipantsParticipant>;
  /**
   * View a participant collection
   *
   * Equivalent to GET /4.0/together/{together_id}/participants
   */
  getParticipants: Maybe<ParticipantsParticipants>;
  /**
   * Fetch a payment method
   *
   * Equivalent to GET /4.0/payment-methods/{payment_method_id}
   */
  getPaymentMethod: Maybe<PaymentMethod>;
  /**
   * Helpful for providing a list of payment methods before a user is makes a gift.
   *
   * Equivalent to GET /4.0/payment-methods
   */
  getPaymentMethods: Maybe<PaymentMethods>;
  /**
   * This API endpoint returns a collection of all permissions.
   *
   * Equivalent to GET /permissions
   */
  getPermissions: Maybe<Permissions>;
  /**
   * This is an unauthenticated endpoint only for API monitoring usage by tools such as Panopta.
   *
   * Equivalent to GET /4.0/ping
   */
  getPing: Maybe<_40Ping>;
  getPlan: Maybe<Plan>;
  /**
   * View a subscription resource
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}
   */
  getPlanSubscription: Maybe<PlanSubscription>;
  /**
   * Get service configuration values such as image urls, language tags, and maximum values
   *
   * Equivalent to GET /4.0/configuration
   */
  getPlanSubscriptionConfiguration: Maybe<ConfigurationConfiguration>;
  /**
   * View a progress collection
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}/progress
   */
  getPlanSubscriptionProgress: Maybe<PlanSubscriptionProgress>;
  /**
   * View a subscription collection
   *
   * Equivalent to GET /4.0/subscriptions
   */
  getPlanSubscriptions: Maybe<PlanSubscriptions>;
  /**
   * View a Together Resource
   *
   * Equivalent to GET /4.0/together/{together_id}
   */
  getPlanTogether: Maybe<PlanTogether>;
  /**
   * View a Together collection
   *
   * Equivalent to GET /4.0/together
   */
  getPlanTogethers: Maybe<PlanTogethers>;
  /**
   * Return a paginated list of content items from a playlist
   *
   * Equivalent to GET /4.0/playlists/{playlist_id}
   */
  getPlaylist: Maybe<ContentPlaylist>;
  /**
   * Return the playlists for a given organization
   *
   * Equivalent to GET /4.0/playlists
   */
  getPlaylists: Maybe<ContentPlaylists>;
  /**
   * View an ordered list of playlist ids
   *
   * Equivalent to GET /admin/4.1/playlists_order
   */
  getPlaylistsOrder: Maybe<PlaylistsOrder>;
  /**
   * This API endpoint returns a policy resource associated with a resource_id
   *
   * Equivalent to GET /policies/{resource_id}
   */
  getPolicy: Maybe<Policy>;
  /**
   * This API endpoint returns a paged collection of role bindings that belong to a principal.  The response is filtered down to only resources that the requesting principal has access to.
   *
   * Equivalent to GET /principals/{principal_id}
   */
  getPrincipal: Maybe<Principal>;
  /**
   * This API endpoint returns a collection of principals and the role bindings that belong to each
   *
   * Equivalent to GET /principals:batchGet
   */
  getPrincipals: Maybe<Principals>;
  /**
   * View a progress day resource
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}/progress/{progress_day}
   */
  getProgressDay: Maybe<ProgressProgressDay>;
  /**
   * Get a publisher resource
   *
   * Equivalent to GET /4.0/publishers/{id}
   */
  getPublisher: Maybe<Publisher>;
  /**
   * View a publisher collection
   *
   * Equivalent to GET /4.0/publishers
   */
  getPublishers: Maybe<Publishers>;
  /**
   * Get the configuration data for this API.
   *
   * Equivalent to GET /3.1/configuration.json
   */
  getReadingPlansConfiguration: Maybe<ReadingPlansConfigurationPlan>;
  /**
   * Return a relationship object for a given content resource specific to the type of media
   *
   * Equivalent to GET /4.0/content/{media_type}/{external_content_id}/relationships
   */
  getRelationship: Maybe<ContentRelationships>;
  /**
   * This API endpoint returns a role resource.
   *
   * Equivalent to GET /roles/{role_id}
   */
  getRole: Maybe<Role>;
  /**
   * This API endpoint returns a paged collection of roles. If the oowner query parameter is specified,  the roles are filtered by owner. Otherwise, the global roles are returned.
   *
   * Equivalent to GET /roles
   */
  getRoles: Maybe<Roles>;
  /**
   * Returns user's saved events
   *
   * Equivalent to GET /4.0/saves
   */
  getSaved: Maybe<SavesSaved>;
  /**
   * Returns a collection of service times for the church profile
   *
   * Equivalent to GET /4.0/organizations/{organization_id}/service_times
   */
  getServiceTimes: Maybe<OrganizationsServiceTimes>;
  /**
   * View a settings resource
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}/settings/{kind}
   */
  getSetting: Maybe<SettingsSetting>;
  /**
   * View a settings collection
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}/settings
   */
  getSettings: Maybe<SettingsSettings>;
  /**
   * Create Stripe SetupIntent resource will be created in Stripe and the client secreate that provides access to it by clients will be returned.
   *
   * Equivalent to GET /4.0/stripe-customers/me/setup-intents
   */
  getSetupIntent: Maybe<SetupIntent>;
  /**
   * This is an authenticated list of stories.
   *
   * Equivalent to GET /4.0/stories
   */
  getStories: Maybe<Stories2>;
  /**
   * Fetch a recurring gift
   *
   * Equivalent to GET /4.0/subscriptions/{subscription_id}
   */
  getSubscription: Maybe<Subscription>;
  /**
   * Helpful for providing a list of the user's existing recurring gifts.
   *
   * Equivalent to GET /4.0/subscriptions
   */
  getSubscriptions: Maybe<Subscriptions>;
  /**
   * Get the authenticated user's information including address, email, preferred statement type, etc.
   *
   * Equivalent to GET /4.0/users
   */
  getUser: Maybe<User>;
  /**
   * Gets a user ID.
   *
   * Equivalent to GET /3.1/user_id.json
   */
  getUserID: Maybe<UsersIdUserId>;
  /**
   * Returns an object of verses and colors to be shown in the reader.
   *
   * Equivalent to GET /3.1/verse_colors.json
   */
  getVerseColors: Maybe<MomentsVerseColors>;
  /**
   * An endpoint for viewing a video resource
   *
   * Equivalent to GET /4.0/videos/{id}
   */
  getVideo: Maybe<Video>;
  /**
   * Mutually exclusive params:
   *   [related, collection, status]
   *   [related, collection, publisher]
   *
   *
   * Equivalent to GET /4.0/videos
   */
  getVideos: Maybe<_40Videos>;
  /**
   * An endpoint for viewing video configuration value
   *
   * Equivalent to GET /4.0/configuration
   */
  getVideosConfiguration: Maybe<VideosConfiguration>;
  /**
   * Get Verse of the Day (VOTD) subscription for the authenticated user.
   *
   * Equivalent to GET /3.1/votd_subscription.json
   */
  getVotdSubscription: Maybe<NotificationsGetVotdSubscription>;
  /**
   * presign POST for updating thumbnail asset
   *
   * Equivalent to GET /4.0/collections/{id}:presign
   */
  presignCollection: Maybe<CollectionPresignResponse>;
  /**
   * Receive presigned POST info for uploading the thumbnail asset to s3 for either a banner or logo image.
   *
   * Equivalent to GET /4.0/publishers/{id}:presign
   */
  presignPublisher: Maybe<PublisherPresignResponse>;
  /**
   * - Who - This API should only be used by web clients building admin portals.
   * - What - This API endpoint returns a collection object with a data array of suggestion objects.
   * - When - Use this API when getting a list of suggestions for an admin tool.
   * - Where - Currently used for Seek admin tool.
   * - Why - This allows an admin to view suggestions directly and separately from clients which serve end users.
   * - How - This API uses PostgreSQL to get a list of suggestions by language tag.
   *
   *
   * Equivalent to GET /admin/4.1/suggestions
   */
  searchAdminSuggestions: Maybe<Admin41Suggestions>;
  /**
   * - Who - Admin users wanting to view trending in an admin tool
   * - What - Returns an object containing a data field populated with a list of trending searches in the given language.
   * - When - When we want to display trending by language in our admin.
   * - Where - This API should only be used from Seek.
   * - Why - This gives us an admin GET view different from what we display in clients.
   * - How - Get the data from PG and send it to the browser.
   *
   *
   * Equivalent to GET /admin/4.1/trending
   */
  searchAdminTrending: Maybe<AdminTrendings>;
  /**
   * - Who - This API can be used by all clients.
   * - What - This API endpoint returns a collection object with a data array of church objects
   *   along with the page number and page size the query used.
   * - When - When you want to display Church search results without any other types/kinds.
   * - Where - This is used in Unified Search (page 2 & beyond of results), Discover
   *   & Church Portal.
   * - Why - Gives the users the ability to find specific churches from a query.
   * - How - This API takes the provided parameters and queries Apache Solr with them to
   *   return just the desired churches.
   *
   *
   * Equivalent to GET /4.0/churches
   */
  searchChurches: Maybe<Churches>;
  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows getting a single elevation resource by it's id / primary key.
   * - When - Use this API when an admin user is wanting to view a single elevation.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - In cases where we want a view with a single elevation to show all the elevated thigns.
   * - How - Call this URL with the unique id in the path.
   *
   *
   * Equivalent to GET /4.0/elevations/{elevation_id}
   */
  searchElevation: Maybe<Elevation>;
  /**
   * - Who - Only devs working on seek.thewardro.be should use this API.
   * - What - This allows getting all the elevations
   * - When - Use this API to get all the elevations.
   * - Where - This should only be used in our admin search tool seek.thewardro.be.
   * - Why - To populate the list of elevations in Seek.
   * - How - Call this API to get all the elevations.
   *
   *
   * Equivalent to GET /4.0/elevations
   */
  searchElevations: Maybe<Elevations>;
  /**
   * Get a feature from search by its id.
   *
   * Equivalent to GET /4.0/features/{feature_id}
   */
  searchFeature: Maybe<Feature>;
  /**
   * This will return an array of feature objects based on parameters. For now there is no need to paginate with such a small set of results and limited use for this API.
   *
   * Equivalent to GET /4.0/features
   */
  searchFeatures: Maybe<Features>;
  /**
   * This is an unauthenticated endpoint only for Kubernetes probe usage.
   *
   * Equivalent to GET /health-check
   */
  searchHealthcheck: Maybe<Scalars['JSON']['output']>;
  /**
   * This will return an array of history objects for the authenticated user based on parameters.
   *
   * Equivalent to GET /4.0/history
   */
  searchHistory: Maybe<Histories>;
  /**
   * This will return an array of image objects based on parameters.
   *
   * Equivalent to GET /4.0/images
   */
  searchImages: Maybe<_40Images>;
  /**
   * This API endpoint returns a dictionary with an array of all languages currently supported by search.
   *
   * Equivalent to GET /4.0/languages
   */
  searchLanguages: Maybe<Languages>;
  /**
   * Look up existing modules that are of a specified type with a given reference or id
   *
   * Equivalent to GET /4.0/modules:lookup
   */
  searchModules: Maybe<ModulesModules>;
  /**
   * Why: This API allows clients to search for Partners without other objects in the response. Who: For all client devs. What: This API endpoint returns a collection object with a data array of partner objects along with the page number and page size the query used. When: This can be used to power any screen or admin where we want to get a list of partners by a search query filtered by language tag. How: This endpoint queries our Apache Solr backend and stores results in cache to match partners name & associated metadata to our search query.
   *
   * Equivalent to GET /4.0/partners
   */
  searchPartners: Maybe<Partners>;
  /**
   * This is an unauthenticated endpoint only for API monitoring usage by tools such as Panopta.
   *
   * Equivalent to GET /4.0/ping
   */
  searchPing: Maybe<_40Ping>;
  /**
   * This API endpoint returns a collection object with a data array of plan objects along with the page number and page size the query used.
   *
   * Equivalent to GET /4.0/plans
   */
  searchPlans: Maybe<Plans>;
  /**
   * Podcasts are deprecated. Returns an empty list
   *
   * Equivalent to GET /4.0/podcasts
   */
  searchPodcasts: Maybe<Scalars['JSON']['output']>;
  /**
   * This API endpoint returns an array of result kinds based on search query input. This is the API where a user's search history gets saved under Unified Search so make sure and pass auth when it is available.
   *
   * Equivalent to GET /4.0/results
   */
  searchResults: Maybe<Results>;
  /**
   * This should *NOT* be used by clients. This will only be used by admin/APIs.
   *
   * Equivalent to GET /4.0/strings/{string_id}/translations
   */
  searchStringTranslations: Maybe<Translations>;
  /**
   * This API endpoint returns a collection object with a data array of string objects. By default these are all the strings that do not have a remote_string_id yet. No pagination - all strings are returned.
   *
   * Equivalent to GET /4.0/strings
   */
  searchStrings: Maybe<Strings>;
  /**
   * - Who - This API can be used by all clients.
   * - What - This API endpoint returns a collection object with a data array of suggestion objects.  There is no pagination to this specific collection. Query is optional and if not passed then a list of "trending" searches is passed for that provided language.
   * - When - When we need to display a list of suggestions to a user as they engage in the Search UI.
   * - Where - Currently this is used when a user taps into the search navigation bar, and is called additionally as they type out each character in their query.
   * - Why - This API allows us to provide high quality suggestions of what we think the user is looking for to get them to the best content as quickly as possible.
   * - How - We use Solr to get the best possible suggestions for a user. They are crowd sourced from what our community has searched for in the past. The order they are returned in is related to the frequency with which the community has searched for that specific query.
   *
   *
   * Equivalent to GET /4.1/suggestions
   */
  searchSuggestions: Maybe<Suggestions41>;
  /**
   * - Who - This API can be used by all clients. - What - This API endpoint returns a single topic object. - When - When you want to display a single topic. - Where - In the app when displaying a single topic. - Why - Single topic for the search results. - How - Call this URL with the topic id.
   *
   * Equivalent to GET /4.0/topics/{topic_id}
   */
  searchTopic: Maybe<Topic>;
  /**
   * - Who - This API can be used by all clients. - What - This API endpoint returns a collection object with a data array of topic objects,
   *   did_you_mean for spelling corrections, search_instead_for to apply corrections, and the
   *   original query
   * - When - When you want to display Topics search results without any other types/kinds. - Where - In the app when searching for topics as well as in Seek. - Why - List of topics for the search results. - How - Call this URL with the appropriate parameters.
   *
   * Equivalent to GET /4.0/topics
   */
  searchTopics: Maybe<_40Topics>;
  /**
   * This API endpoint returns a collection object with a data array of verse objects along with the page number and page size the query used. If this is being used in RED for bundling data in a build, there is a special token that can be used instead of auth. *Note* Page size with the user intent "text" is currently locked to 25. This is because it calls the monolith 3.1 API internally which had no notion of dynamic page sizing.
   *
   * Equivalent to GET /4.0/verses
   */
  searchVerses: Maybe<_40Verses>;
  /**
   * - Who - All clients could use this API.
   * - What - This API endpoint returns a collection object with a data array of video objects along with the page number and page size the query used.
   * - When - When you want to search for videos and not display other search kinds/objects.
   * - Where - This API is used in Unified Search for results beyond page 1, also used in Blockbuster & Stories CMS.
   * - Why - This allows a user to search for just videos.
   * - How - The API takes the parameters from the client as uses them to find Videos in Apache Solr.
   *
   *
   * Equivalent to GET /4.0/videos
   */
  searchVideos: Maybe<_40Videos>;
};


export type QueryDeprecatedGetSuggestionsArgs = {
  appLanguageTag: Scalars['String']['input'];
  bibleVersionId: Scalars['Int']['input'];
  kind?: InputMaybe<Kind3>;
  plansLanguageTag: Scalars['String']['input'];
  query: Scalars['String']['input'];
  videosLanguageTag: Scalars['String']['input'];
};


export type QueryGetActivitiesArgs = {
  day?: InputMaybe<Scalars['Int']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Kind>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  togetherId: Scalars['Int']['input'];
};


export type QueryGetActivityArgs = {
  activityId: Scalars['Int']['input'];
  togetherId: Scalars['Int']['input'];
};


export type QueryGetAdminAccountArgs = {
  accountId: Scalars['String']['input'];
  verify?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminAccountsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminAllOrganizationAccountsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminContentArgs = {
  mediaType?: InputMaybe<MediaType>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminContentGrantOrganizationsArgs = {
  contentType: ContentType;
  externalId: Scalars['Float']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminContentRelationshipArgs = {
  contentId: Scalars['Float']['input'];
  relationshipId: Scalars['Float']['input'];
};


export type QueryGetAdminContentRelationshipsArgs = {
  externalContentId: Scalars['Float']['input'];
  mediaType: MediaType;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminContentResourceArgs = {
  contentId: Scalars['Float']['input'];
};


export type QueryGetAdminEventArgs = {
  eventId: Scalars['Float']['input'];
};


export type QueryGetAdminEventsArgs = {
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminExportArgs = {
  exportId: Scalars['String']['input'];
};


export type QueryGetAdminExportsArgs = {
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminLanguagesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pathOrganizationId: Scalars['String']['input'];
};


export type QueryGetAdminOrganizationArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetAdminOrganizationAccountArgs = {
  accountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  verify?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminOrganizationAccountsArgs = {
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAdminOrganizationBibleVersionGrantsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminOrganizationContentGrantsArgs = {
  contentType?: InputMaybe<ContentType>;
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminOrganizationFeaturedPlansArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetAdminOrganizationFollowersArgs = {
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminOrganizationPostArgs = {
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type QueryGetAdminOrganizationPostsArgs = {
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminOrganizationProfilesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetAdminOrganizationsArgs = {
  churchStatuses?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  forUser?: InputMaybe<Scalars['Float']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  supportUserId?: InputMaybe<Scalars['Float']['input']>;
  typeStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetAdminPlaylistArgs = {
  playlistId: Scalars['Float']['input'];
};


export type QueryGetAdminPlaylistsArgs = {
  contentLimit?: InputMaybe<Scalars['Int']['input']>;
  languageTagQuery?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdminServiceTimesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetAdminUserArgs = {
  userId: Scalars['Float']['input'];
};


export type QueryGetAudioBibleVersionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetBibleChapterArgs = {
  format?: InputMaybe<Format>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetBibleVerseArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetBibleVersesArgs = {
  format?: InputMaybe<Format>;
  id?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetBibleVersionArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetBibleVersionsArgs = {
  languageTag?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Type>;
};


export type QueryGetBindingArgs = {
  bindingId: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
};


export type QueryGetBindingsArgs = {
  resourceId: Scalars['String']['input'];
};


export type QueryGetChaptersArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCheckArgs = {
  checkRequestInput?: InputMaybe<CheckRequestInput>;
};


export type QueryGetCollectionArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type QueryGetCollectionsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  showEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  showSeasonal?: InputMaybe<Scalars['Boolean']['input']>;
  videoId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCompletedPlansArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetContentPartnerArgs = {
  contentPartnerId: Scalars['String']['input'];
};


export type QueryGetContentPartnersArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  partnerPortalOrganizationId?: InputMaybe<Scalars['Float']['input']>;
  videoPublisherId?: InputMaybe<Scalars['Float']['input']>;
  yvBiblePublisherId?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetCurrenciesArgs = {
  acceptLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEventArgs = {
  eventId: Scalars['Float']['input'];
};


export type QueryGetEventsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetExpandArgs = {
  expandRequestInput?: InputMaybe<ExpandRequestInput>;
};


export type QueryGetExpandBatchArgs = {
  expandRequestBatchInput?: InputMaybe<ExpandRequestBatchInput>;
};


export type QueryGetFollowingArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetFrequenciesArgs = {
  acceptLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFriendsLimitArgs = {
  userId: Scalars['Float']['input'];
};


export type QueryGetFundsArgs = {
  acceptLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetGivingStatementArgs = {
  statementYear: Scalars['Int']['input'];
};


export type QueryGetHistoryArgs = {
  historyYear?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetIncomingFriendshipsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetInteractionsArgs = {
  acceptLanguage: Scalars['String']['input'];
};


export type QueryGetKidArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
};


export type QueryGetKidAchievementArgs = {
  achievementId: Scalars['String']['input'];
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
};


export type QueryGetKidAchievementsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
  order?: InputMaybe<Order2>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidChallengeArgs = {
  challengeId: Scalars['String']['input'];
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
};


export type QueryGetKidChallengesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
  order?: InputMaybe<Order3>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidCollectibleSetArgs = {
  kidId: Scalars['Int']['input'];
  order?: InputMaybe<Order4>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidQuestionArgs = {
  kidId: Scalars['Int']['input'];
  questionId: Scalars['String']['input'];
};


export type QueryGetKidQuestionsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  kidId: Scalars['Int']['input'];
  order?: InputMaybe<Order6>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidStoriesArgs = {
  kidId: Scalars['Int']['input'];
  order?: InputMaybe<Order5>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidStoryArgs = {
  kidId: Scalars['Int']['input'];
  storyId: Scalars['String']['input'];
};


export type QueryGetKidUserSettingsArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetKidsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Order>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetKidsDigestArgs = {
  parentId: Scalars['Int']['input'];
};


export type QueryGetLanguageArgs = {
  languageId: Scalars['Int']['input'];
};


export type QueryGetLanguageSnippetArgs = {
  languageId: Scalars['Int']['input'];
  snippetId: Scalars['Int']['input'];
};


export type QueryGetLanguageSnippetsArgs = {
  languageId: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetLastCommentPerDayArgs = {
  togetherId: Scalars['Int']['input'];
};


export type QueryGetLatestPlaylistArgs = {
  browserLanguage: Scalars['String']['input'];
  contentLimit?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetLessonArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type QueryGetLessonTranslationArgs = {
  id: Scalars['Int']['input'];
  languageTag: Scalars['String']['input'];
};


export type QueryGetLessonTranslationsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetLessonsArgs = {
  cohort?: InputMaybe<Cohort>;
  fields?: InputMaybe<Scalars['String']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  liveDate?: InputMaybe<Scalars['String']['input']>;
  localizable?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Status2>;
  xCountryCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetLocaleHeadersArgs = {
  locales?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Platform>;
};


export type QueryGetLocalizedChapterReferencesArgs = {
  format?: InputMaybe<Format>;
  locale: Scalars['String']['input'];
  references: Array<Scalars['String']['input']>;
};


export type QueryGetLocalizedVerseReferencesArgs = {
  format?: InputMaybe<Format>;
  locale: Scalars['String']['input'];
  references: Array<Scalars['String']['input']>;
};


export type QueryGetModuleTypesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetModulesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  lesson: Scalars['Int']['input'];
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetNotificationSettingsArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationArgs = {
  acceptLanguage?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetOrganizationFeaturedPlansArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetOrganizationMemberArgs = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['Float']['input'];
};


export type QueryGetOrganizationMembersArgs = {
  friends?: InputMaybe<Scalars['Float']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetOrganizationPostArgs = {
  bodyAccept?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type QueryGetOrganizationPostActionsArgs = {
  organizationId: Scalars['String']['input'];
  postId: Scalars['String']['input'];
};


export type QueryGetOrganizationPostsArgs = {
  bodyAccept?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetOrganizationProfilesArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetOrganizationsOrganizationLocationsArgs = {
  clientLatitude?: InputMaybe<Scalars['Float']['input']>;
  clientLongitude?: InputMaybe<Scalars['Float']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  parentIdPath: Scalars['String']['input'];
};


export type QueryGetOrganizationsPlaylistsOrderArgs = {
  languageTag: Scalars['String']['input'];
  pathOrganizationId: Scalars['String']['input'];
};


export type QueryGetParticipantArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  togetherId: Scalars['Int']['input'];
  userId: Scalars['Float']['input'];
};


export type QueryGetParticipantsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PlanSubscriptionStatus>;
  togetherId: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPaymentMethodArgs = {
  paymentMethodId: Scalars['Int']['input'];
};


export type QueryGetPaymentMethodsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPermissionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPlanArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPlanSubscriptionArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetPlanSubscriptionConfigurationArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlanSubscriptionProgressArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetPlanSubscriptionsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Order>;
  orderBy?: InputMaybe<OrderBy2>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Status2>;
};


export type QueryGetPlanTogetherArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  togetherId: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlanTogethersArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  startDt?: InputMaybe<Scalars['String']['input']>;
  status: PlanSubscriptionStatus;
};


export type QueryGetPlaylistArgs = {
  appLanguage: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  planLanguage?: InputMaybe<Scalars['String']['input']>;
  playlistId: Scalars['Float']['input'];
  readerLanguage: Scalars['String']['input'];
  videoLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlaylistsArgs = {
  appLanguage: Scalars['String']['input'];
  contentLimit?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  planLanguage?: InputMaybe<Scalars['String']['input']>;
  readerLanguage: Scalars['String']['input'];
  videoLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPlaylistsOrderArgs = {
  isOrdered: Scalars['Boolean']['input'];
  languageTag: Scalars['String']['input'];
};


export type QueryGetPolicyArgs = {
  resourceId: Scalars['String']['input'];
};


export type QueryGetPrincipalArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  principalId: Scalars['String']['input'];
};


export type QueryGetPrincipalsArgs = {
  principals: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetProgressDayArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  progressDay: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetPublisherArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type QueryGetPublishersArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetRelationshipArgs = {
  appLanguage: Scalars['String']['input'];
  externalContentId: Scalars['Float']['input'];
  mediaType: MediaType;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  planLanguage?: InputMaybe<Scalars['String']['input']>;
  readerLanguage: Scalars['String']['input'];
  videoLanguage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetRoleArgs = {
  roleId: Scalars['String']['input'];
};


export type QueryGetRolesArgs = {
  owner?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetSavedArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetServiceTimesArgs = {
  acceptLanguage?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryGetSettingArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  kind: Kind2;
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetSettingsArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetSetupIntentArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSubscriptionArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type QueryGetSubscriptionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUserIdArgs = {
  username: Scalars['String']['input'];
};


export type QueryGetVerseColorsArgs = {
  usfm: Scalars['String']['input'];
  versionId: Scalars['Int']['input'];
};


export type QueryGetVideoArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type QueryGetVideosArgs = {
  collection?: InputMaybe<Scalars['Int']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  orientation?: InputMaybe<Orientation2>;
  page?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  publisher?: InputMaybe<Scalars['Int']['input']>;
  related?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status2>;
  types?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetVideosConfigurationArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPresignCollectionArgs = {
  id: Scalars['Int']['input'];
  language: Scalars['String']['input'];
};


export type QueryPresignPublisherArgs = {
  id: Scalars['Int']['input'];
  imageType: ImageType;
  language: Scalars['String']['input'];
};


export type QuerySearchAdminSuggestionsArgs = {
  languageTag?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchAdminTrendingArgs = {
  languageTag: Scalars['String']['input'];
};


export type QuerySearchChurchesArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  languageTag: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  onlyParentOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  parentOrg?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  supportUserId?: InputMaybe<Scalars['Int']['input']>;
  userIntent: UserIntent5;
};


export type QuerySearchElevationArgs = {
  elevationId: Scalars['Int']['input'];
};


export type QuerySearchElevationsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchFeatureArgs = {
  featureId: Scalars['Int']['input'];
};


export type QuerySearchFeaturesArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
  source?: InputMaybe<Source4>;
};


export type QuerySearchHistoryArgs = {
  kind?: InputMaybe<Kind3>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchImagesArgs = {
  bibleVersionId: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QuerySearchLanguagesArgs = {
  kind?: InputMaybe<Kind3>;
};


export type QuerySearchModulesArgs = {
  contentId?: InputMaybe<Scalars['Int']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: Type2;
};


export type QuerySearchPartnersArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  userIntent: UserIntent5;
};


export type QuerySearchPlansArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  userIntent: UserIntent5;
};


export type QuerySearchPodcastsArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  userIntent: UserIntent5;
};


export type QuerySearchResultsArgs = {
  appLanguageTag: Scalars['String']['input'];
  bibleVersionId: Scalars['Int']['input'];
  book?: InputMaybe<Scalars['String']['input']>;
  canon?: InputMaybe<Canon>;
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  organizationsLanguageTag?: InputMaybe<Scalars['String']['input']>;
  partnersLanguageTag?: InputMaybe<Scalars['String']['input']>;
  plansLanguageTag: Scalars['String']['input'];
  query: Scalars['String']['input'];
  userIntent: UserIntent5;
  videosLanguageTag: Scalars['String']['input'];
};


export type QuerySearchStringTranslationsArgs = {
  stringId: Scalars['Int']['input'];
};


export type QuerySearchStringsArgs = {
  fields?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchSuggestionsArgs = {
  languageTag: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchTopicArgs = {
  topicId: Scalars['Int']['input'];
};


export type QuerySearchTopicsArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QuerySearchVersesArgs = {
  bibleVersionId: Scalars['Int']['input'];
  book?: InputMaybe<Scalars['String']['input']>;
  canon?: InputMaybe<Canon>;
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  userIntent: UserIntent5;
};


export type QuerySearchVideosArgs = {
  checkSpelling?: InputMaybe<Scalars['Boolean']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userIntent: UserIntent5;
};

export type ReaderFooter = {
  __typename?: 'ReaderFooter';
  html: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

export type ReadingPlansConfigurationPlan = {
  __typename?: 'ReadingPlansConfigurationPlan';
  response: Maybe<ReadingPlansConfigurationPlanResponse>;
};

export type ReadingPlansConfigurationPlanData = {
  __typename?: 'ReadingPlansConfigurationPlanData';
  additionalContentAudioUrl: Maybe<Scalars['String']['output']>;
  availableLanguageTags: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  images: Maybe<Scalars['JSON']['output']>;
};

export type ReadingPlansConfigurationPlanResponse = {
  __typename?: 'ReadingPlansConfigurationPlanResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<ReadingPlansConfigurationPlanData>;
};

export type ReadingPlansPlan = {
  __typename?: 'ReadingPlansPlan';
  response: Maybe<ReadingPlansPlanResponse>;
};

export type ReadingPlansPlanAbout = {
  __typename?: 'ReadingPlansPlanAbout';
  html: Maybe<Html>;
  text: Maybe<Scalars['JSON']['output']>;
};

export type ReadingPlansPlanCopyright = {
  __typename?: 'ReadingPlansPlanCopyright';
  html: Maybe<Html>;
  text: Maybe<Scalars['JSON']['output']>;
};

export type ReadingPlansPlanImage = {
  __typename?: 'ReadingPlansPlanImage';
  height: Maybe<Scalars['Int']['output']>;
  url: Maybe<Scalars['String']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type ReadingPlansPlanResponse = {
  __typename?: 'ReadingPlansPlanResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type Reference = {
  __typename?: 'Reference';
  human: Maybe<Scalars['String']['output']>;
  usfm: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Reference2 = {
  __typename?: 'Reference2';
  human: Maybe<Scalars['String']['output']>;
  usfm: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Reference3 = {
  __typename?: 'Reference3';
  human: Maybe<Scalars['String']['output']>;
  usfm: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  versionId: Maybe<Scalars['Int']['output']>;
};

export enum RelationshipType {
  AUTHOR = 'AUTHOR',
  COLLABORATOR = 'COLLABORATOR',
  CO_AUTHOR = 'CO_AUTHOR',
  PARTNER = 'PARTNER',
  PREFERRED_PARTNER = 'PREFERRED_PARTNER',
  PUBLISHER = 'PUBLISHER'
}

export type Response = {
  __typename?: 'Response';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Data>;
};

export type Response2 = {
  __typename?: 'Response2';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Data2>;
};

export type Response3 = {
  __typename?: 'Response3';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Data3>;
};

export type Response4 = {
  __typename?: 'Response4';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Data4>;
};

export type Response5 = {
  __typename?: 'Response5';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<TextVersion>;
};

export type Response6 = {
  __typename?: 'Response6';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<Data6>;
};

export type Results = {
  __typename?: 'Results';
  churches: Maybe<Churches2>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  features: Maybe<Features2>;
  images: Maybe<Images>;
  /** *LEGACY* Do not use this property any longer, but use order_v3 instead. Podcast and Churches will never appear in this list due to a serialization issue. A list of strings describing the object kind order. */
  order: Maybe<Array<Maybe<Schema>>>;
  /** *LEGACY* Do not use this property any longer, but use order_v3 instead. Churches will never appear in this list due to a serialization issue. A list of strings describing the object kind order. */
  orderV2: Maybe<Array<Maybe<Schema>>>;
  /** A list of strings describing the object kind order. */
  orderV3: Maybe<Array<Maybe<Schema>>>;
  partners: Maybe<Partners2>;
  plans: Maybe<Plans2>;
  podcasts: Maybe<Podcasts>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  supportArticles: Maybe<SupportArticles>;
  topics: Maybe<Topics>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent3>;
  verses: Maybe<Verses>;
  videos: Maybe<Videos>;
};

export type Role = {
  __typename?: 'Role';
  id: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions: Array<Maybe<Scalars['String']['output']>>;
};

export type Role3 = {
  __typename?: 'Role3';
  id: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type Role3Input = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RoleBinding = {
  __typename?: 'RoleBinding';
  etag: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  members: Array<Maybe<Scalars['String']['output']>>;
  resource: Scalars['String']['output'];
  role: Role3;
  version: Maybe<Scalars['Int']['output']>;
};

export type RoleBindingCreateInput = {
  members: Array<InputMaybe<Scalars['String']['input']>>;
  resource: Scalars['String']['input'];
  role: RoleBindingRoleInput;
};

export type RoleBindingInput = {
  etag: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  members: Array<InputMaybe<Scalars['String']['input']>>;
  resource: Scalars['String']['input'];
  role: RoleBindingRoleInput;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleBindingRoleInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RoleBindings = {
  __typename?: 'RoleBindings';
  data: Maybe<Array<Maybe<RoleBinding>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type RoleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  permissions: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Roles = {
  __typename?: 'Roles';
  data: Maybe<Array<Maybe<Role>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type RolesListItemInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SavesSaveCreateInput = {
  comments?: InputMaybe<Array<InputMaybe<CommentsListItemInput>>>;
  /** This property represents an event_id */
  id: Scalars['Float']['input'];
};

export type SavesSaved = {
  __typename?: 'SavesSaved';
  data: Maybe<Array<Maybe<Data2ListItem>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type SavesSaves = {
  __typename?: 'SavesSaves';
  comments: Maybe<Array<Maybe<Comments2ListItem>>>;
};

export enum Schema {
  ALL = 'ALL',
  BIBLE_AUDIO = 'BIBLE_AUDIO',
  BIBLE_TEXT = 'BIBLE_TEXT',
  CHURCHES = 'CHURCHES',
  COMMENT = 'COMMENT',
  COMPLETE = 'COMPLETE',
  EVENTS = 'EVENTS',
  FEATURES = 'FEATURES',
  IMAGES = 'IMAGES',
  LIKE = 'LIKE',
  PARTNERS = 'PARTNERS',
  PLAN = 'PLAN',
  PLANS = 'PLANS',
  PODCASTS = 'PODCASTS',
  SUPPORT_ARTICLES = 'SUPPORT_ARTICLES',
  TOPICS = 'TOPICS',
  USERS = 'USERS',
  VERSES = 'VERSES',
  VIDEO = 'VIDEO',
  VIDEOS = 'VIDEOS'
}

export enum Schema2 {
  ACCEPTED = 'ACCEPTED',
  BIBLE_AUDIO = 'BIBLE_AUDIO',
  BIBLE_TEXT = 'BIBLE_TEXT',
  DECLINED = 'DECLINED',
  HOST = 'HOST',
  INVITED = 'INVITED',
  KICKED = 'KICKED',
  PLANS = 'PLANS',
  TEACHING_CLIPS = 'TEACHING_CLIPS',
  VIDEOS = 'VIDEOS'
}

export enum Schema3 {
  ACCEPT_NOTIFICATIONS = 'ACCEPT_NOTIFICATIONS',
  COMMENT_NOTIFICATIONS = 'COMMENT_NOTIFICATIONS'
}

export enum Section {
  AP = 'AP',
  NT = 'NT',
  OT = 'OT'
}

export type Setting = {
  __typename?: 'Setting';
  email: Scalars['Boolean']['output'];
  language: Maybe<Scalars['String']['output']>;
  notifications: Maybe<Notifications>;
  push: Scalars['Boolean']['output'];
};

export type SettingInput = {
  email: Scalars['Boolean']['input'];
  push: Scalars['Boolean']['input'];
};

export type SettingsInfoInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationsInput>;
  updatedDt?: InputMaybe<Scalars['String']['input']>;
};

export type SettingsSetting = {
  __typename?: 'SettingsSetting';
  kind: Schema3;
  setting: Setting;
  updatedDt: Scalars['String']['output'];
};

export type SettingsSettingUpdateInput = {
  setting: SettingInput;
  updatedDt: Scalars['String']['input'];
};

export type SettingsSettings = {
  __typename?: 'SettingsSettings';
  data: Maybe<Array<Maybe<SettingsSetting>>>;
};

export type SetupIntent = {
  __typename?: 'SetupIntent';
  /** Client secret for a SetupIntent as outlined in this doc:https://stripe.com/docs/payments/save-and-reuse?platform=web#web-create-intent */
  clientSecret: Maybe<Scalars['String']['output']>;
};

export type ShiftDatesInput = {
  /** Number of days to shift event times. */
  days?: InputMaybe<Scalars['Int']['input']>;
  /** Number of hours to shift event times. */
  hours?: InputMaybe<Scalars['Int']['input']>;
  /** Number of minutes to shift event times. */
  minutes?: InputMaybe<Scalars['Int']['input']>;
  /** Number of weeks to shift event times. */
  weeks?: InputMaybe<Scalars['Int']['input']>;
};

export type Sizes = {
  __typename?: 'Sizes';
  standard: Maybe<Array<Maybe<StandardListItem>>>;
  thumbnails: Maybe<Array<Maybe<ThumbnailsListItem>>>;
};

export type Snippet = {
  __typename?: 'Snippet';
  /** Sample code that is interesting to learn from */
  code: Scalars['String']['output'];
  /** This should be a concise description for the snippet */
  description: Scalars['String']['output'];
  /** Unique id of this code snippet example */
  id: Maybe<Scalars['Int']['output']>;
  languageId: Scalars['Int']['output'];
};

export type SnippetInput = {
  /** Sample code that is interesting to learn from */
  code: Scalars['String']['input'];
  /** This should be a concise description for the snippet */
  description: Scalars['String']['input'];
  /** Unique id of this code snippet example */
  id?: InputMaybe<Scalars['Int']['input']>;
  languageId: Scalars['Int']['input'];
};

export type Snippets = {
  __typename?: 'Snippets';
  data: Maybe<Array<Maybe<Snippet>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export enum Source {
  ADMIN = 'ADMIN',
  LENS = 'LENS',
  SPEC = 'SPEC'
}

export enum Source3 {
  COMMUNITY = 'COMMUNITY',
  TRENDING = 'TRENDING',
  USER = 'USER'
}

export enum Source4 {
  ADMIN = 'ADMIN',
  SPEC = 'SPEC'
}

export type StandardListItem = {
  __typename?: 'StandardListItem';
  height: Maybe<Scalars['Int']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export enum StatementType {
  EMAIL = 'EMAIL',
  PAPER = 'PAPER'
}

export enum Status {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  INVITED = 'INVITED',
  LIVE = 'LIVE',
  PUBLISHED = 'PUBLISHED',
  READY = 'READY',
  UNREADY = 'UNREADY'
}

export enum Status2 {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  GENERATED = 'GENERATED',
  HIDDEN = 'HIDDEN',
  LIVE = 'LIVE',
  PUBLISHED = 'PUBLISHED',
  READY = 'READY',
  REJECTED = 'REJECTED',
  UNREADY = 'UNREADY',
  VERIFIED = 'VERIFIED',
  _ = '_'
}

export type Stories2 = {
  __typename?: 'Stories2';
  /** Array of stories sorted by time that the caller can access */
  data: Maybe<Array<Maybe<Data8ListItem>>>;
};

export type StoryDiscussion = {
  __typename?: 'StoryDiscussion';
  /** Answer to the story discussion question */
  answer: Maybe<Scalars['String']['output']>;
  /** Story discussion story */
  question: Maybe<Scalars['String']['output']>;
};

export type StoryQuestionsListItem = {
  __typename?: 'StoryQuestionsListItem';
  /** answer to the question asked */
  answer: Maybe<Scalars['String']['output']>;
  /** question asked */
  question: Maybe<Scalars['String']['output']>;
};

export type StringTranslationsStringTranslation = {
  __typename?: 'StringTranslationsStringTranslation';
  crowdinStringId: Maybe<Scalars['Int']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  translation: Scalars['String']['output'];
};

export type StringTranslationsStringTranslationInput = {
  crowdinStringId?: InputMaybe<Scalars['Int']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  translation: Scalars['String']['input'];
};

export type Strings = {
  __typename?: 'Strings';
  data: Maybe<Array<Maybe<Data19ListItem>>>;
};

export type Stripe = {
  __typename?: 'Stripe';
  /** Instance Id for the Stripe Account found here: https://dashboard.stripe.com/apikeys  */
  instanceId: Maybe<Scalars['String']['output']>;
};

export enum Style {
  EDITORIAL = 'EDITORIAL',
  GRADIENT = 'GRADIENT',
  MASK = 'MASK'
}

export type Stylesheets = {
  __typename?: 'Stylesheets';
  android: Maybe<Scalars['String']['output']>;
  ios: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Value to be charged in string form */
  amount: Maybe<Scalars['String']['output']>;
  /** Unique ID of Currency code */
  currencyId: Maybe<Scalars['Int']['output']>;
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId: Maybe<FrequencyId>;
  /** Unique ID of Fund */
  fundId: Maybe<Scalars['Int']['output']>;
  /** ID of subscription in Rock */
  id: Maybe<Scalars['Int']['output']>;
  /** the next payment date of this subscription in ISO-8601 format */
  nextPaymentDate: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<PaymentMethod>;
};

export type SubscriptionPatchInput = {
  /** Value to be charged in string form */
  amount?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of Currency code */
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  /** A frequency for the donation which indicates how often the gift should be made, i.e. only one time, weekly, monthly, etc. */
  frequencyId?: InputMaybe<FrequencyId>;
  /** Unique ID of Fund */
  fundId?: InputMaybe<Scalars['Int']['input']>;
  /** the next payment date of this subscription in ISO-8601 format */
  nextPaymentDate?: InputMaybe<Scalars['String']['input']>;
  /** The IANA timezone name of the timezone in which the next_payment_date applies */
  paymentDateTz?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the payment method that is used by this subscription. */
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  /** The source device of a gift. */
  transactionSource?: InputMaybe<TransactionSource>;
};

export type Subscriptions = {
  __typename?: 'Subscriptions';
  data: Maybe<Array<Maybe<Subscription>>>;
};

export type Suggestion41 = {
  __typename?: 'Suggestion41';
  /** Source of query suggestion. Was it from the user's history, the community, or trending? */
  source: Maybe<Source3>;
  /** Query suggestion text */
  text: Scalars['String']['output'];
};

export type Suggestions41 = {
  __typename?: 'Suggestions41';
  /** A list of Suggestion resource objects */
  data: Maybe<Array<Maybe<Suggestion41>>>;
};

export type SupportArticles = {
  __typename?: 'SupportArticles';
  /** A list of Support Article resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Data15ListItem>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type TextBibleVersion = AdminBibleVersion & {
  __typename?: 'TextBibleVersion';
  abbreviation: Maybe<Scalars['String']['output']>;
  dblId: Scalars['String']['output'];
  deletedDt: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  languageName: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  localAbbrevation: Maybe<Scalars['String']['output']>;
  localLanguageName: Maybe<Scalars['String']['output']>;
  localTitle: Maybe<Scalars['String']['output']>;
  releaseDt: Maybe<Scalars['String']['output']>;
  replacedById: Maybe<Scalars['Int']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: VersionType;
};

export type TextVersion = {
  __typename?: 'TextVersion';
  abbreviation: Maybe<Scalars['String']['output']>;
  audio: Maybe<Scalars['Boolean']['output']>;
  books: Maybe<Array<Maybe<BooksListItem>>>;
  copyrightLong: Maybe<CopyrightLong>;
  copyrightShort: Maybe<CopyrightShort>;
  id: Maybe<Scalars['Int']['output']>;
  language: Maybe<Language2>;
  localAbbreviation: Maybe<Scalars['String']['output']>;
  localTitle: Maybe<Scalars['String']['output']>;
  metadataBuild: Maybe<Scalars['Int']['output']>;
  offline: Maybe<Offline2>;
  platforms: Maybe<Platforms2>;
  publisher: Maybe<Publisher>;
  readerFooter: Maybe<ReaderFooter>;
  readerFooterUrl: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['Boolean']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type ThumbnailsListItem = {
  __typename?: 'ThumbnailsListItem';
  height: Maybe<Scalars['Int']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type Time = {
  __typename?: 'Time';
  /** This is the duration of the event in minutes */
  duration: Scalars['Int']['output'];
  /** The location information for the event. */
  locations: Array<Maybe<Scalars['String']['output']>>;
  /** This is the logical start time of the event 24 hour time format. */
  logicalStartTime: Scalars['String']['output'];
  /** Start date of the event. */
  startDate: Scalars['String']['output'];
};

export type Times3ListItem = {
  __typename?: 'Times3ListItem';
  /** This is the duration of the event in minutes */
  duration: Scalars['Int']['output'];
  /** The location information for the event. */
  locations: Array<Maybe<Scalars['String']['output']>>;
  /** This is the logical start time of the event 24 hour time format. */
  logicalStartTime: Scalars['String']['output'];
  /** Start date of the event. */
  startDate: Scalars['String']['output'];
};

export type Times3ListItemInput = {
  /** This is the duration of the event in minutes */
  duration: Scalars['Int']['input'];
  /** The location information for the event. */
  locations: Array<InputMaybe<Scalars['String']['input']>>;
  /** This is the logical start time of the event 24 hour time format. */
  logicalStartTime: Scalars['String']['input'];
  /** Start date of the event. */
  startDate: Scalars['String']['input'];
};

export type TimesListItem = {
  __typename?: 'TimesListItem';
  /** This is the duration of the event in minutes */
  duration: Scalars['Int']['output'];
  /** The location information for the event. */
  locations: Array<Maybe<Scalars['String']['output']>>;
  /** This is the logical start time of the event 24 hour time format. */
  logicalStartTime: Scalars['String']['output'];
  /** Start date of the event. */
  startDate: Scalars['String']['output'];
};

export type Topic = {
  __typename?: 'Topic';
  /** topic id */
  id: Maybe<Scalars['Int']['output']>;
  /** Words that are similar to the main topic. Often synonyms or antonyms. */
  subtopics: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** topic text */
  text: Maybe<Scalars['String']['output']>;
};

export type TopicInput = {
  /** topic id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Words that are similar to the main topic. Often synonyms or antonyms. */
  subtopics?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** topic text */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type Topics = {
  __typename?: 'Topics';
  /** A list of Topic resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Topic>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type Totals = {
  __typename?: 'Totals';
  languages: Maybe<Scalars['Int']['output']>;
  versions: Maybe<Scalars['Int']['output']>;
};

export type Totals2 = {
  __typename?: 'Totals2';
  languages: Maybe<Scalars['Int']['output']>;
  versions: Maybe<Scalars['Int']['output']>;
};

export enum TransactionSource {
  WEB_ANDROID = 'WEB_ANDROID',
  WEB_IOS = 'WEB_IOS',
  WEB_WEB = 'WEB_WEB'
}

export type Translation = {
  __typename?: 'Translation';
  /** The language tag for the string used by our APIs */
  languageTag: Maybe<Scalars['String']['output']>;
  /** The language tag for the string in the translation tool's system */
  remoteLanguageTag: Maybe<Scalars['String']['output']>;
  /** Identifier of string in translation tool's system */
  remoteStringId: Maybe<Scalars['String']['output']>;
  /** The translation identifier in the translation tool's system. */
  remoteTranslationId: Maybe<Scalars['String']['output']>;
  /** The translation for the string. */
  translation: Maybe<Scalars['String']['output']>;
};

export type TranslationInput = {
  /** The language tag for the string used by our APIs */
  languageTag?: InputMaybe<Scalars['String']['input']>;
  /** The language tag for the string in the translation tool's system */
  remoteLanguageTag?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of string in translation tool's system */
  remoteStringId?: InputMaybe<Scalars['String']['input']>;
  /** The translation identifier in the translation tool's system. */
  remoteTranslationId?: InputMaybe<Scalars['String']['input']>;
  /** The translation for the string. */
  translation?: InputMaybe<Scalars['String']['input']>;
};

export type Translations = {
  __typename?: 'Translations';
  data: Maybe<Array<Maybe<Translation>>>;
};

export enum Type {
  ALL = 'ALL',
  ARTISTIC_EXPLANATION = 'ARTISTIC_EXPLANATION',
  BAFK_INSTALL = 'BAFK_INSTALL',
  BAL_INSTALL = 'BAL_INSTALL',
  BA_INSTALL = 'BA_INSTALL',
  BIBLE_PUBLISHER_GROUP = 'BIBLE_PUBLISHER_GROUP',
  CAMPUS = 'CAMPUS',
  CHAPTER_OPEN = 'CHAPTER_OPEN',
  FRIEND_ADD = 'FRIEND_ADD',
  GUIDEDPRAYER_START = 'GUIDEDPRAYER_START',
  HOW_TO = 'HOW_TO',
  INFLUENCER_SELFIE = 'INFLUENCER_SELFIE',
  INTERVIEW = 'INTERVIEW',
  MEDITATION = 'MEDITATION',
  NETWORK = 'NETWORK',
  PLAN_COMPLETE = 'PLAN_COMPLETE',
  PLAN_START = 'PLAN_START',
  PODCAST = 'PODCAST',
  PRAYER = 'PRAYER',
  PUBLIC = 'PUBLIC',
  REENACTMENT = 'REENACTMENT',
  SERMON = 'SERMON',
  TWEEN_HEAD = 'TWEEN_HEAD',
  TWEEN_SONG = 'TWEEN_SONG',
  TWEEN_VERSE_DOODLE = 'TWEEN_VERSE_DOODLE',
  VERSE_HIGHLIGHT = 'VERSE_HIGHLIGHT',
  VERSE_SHARE = 'VERSE_SHARE',
  WORSHIP = 'WORSHIP'
}

export enum Type2 {
  COMPLETIONMODULE = 'COMPLETIONMODULE',
  GREETINGMODULE = 'GREETINGMODULE',
  LIKE = 'LIKE',
  PLANMODULE = 'PLANMODULE',
  PRAYERMODULE = 'PRAYERMODULE',
  REFLECTIONMODULE = 'REFLECTIONMODULE',
  SCRIPTUREIMAGEMODULE = 'SCRIPTUREIMAGEMODULE',
  SCRIPTUREMODULE = 'SCRIPTUREMODULE',
  SERMONMODULE = 'SERMONMODULE',
  UPNEXTMODULE = 'UPNEXTMODULE',
  VIDEOMODULE = 'VIDEOMODULE'
}

export enum Type3 {
  PARTNER_PORTAL_ORGANIZATION = 'PARTNER_PORTAL_ORGANIZATION',
  VIDEO_PUBLISHER = 'VIDEO_PUBLISHER',
  YV_BIBLE_PUBLISHER = 'YV_BIBLE_PUBLISHER'
}

export type UpsertBindingResponse = {
  __typename?: 'UpsertBindingResponse';
  bindingId: Scalars['String']['output'];
};

export type UpsertRoleBindingInput = {
  principal: Scalars['String']['input'];
  resourceId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  /** The mailing address where a user would like to have their year-end statement mailed. US-only. */
  address: Maybe<Scalars['String']['output']>;
  /** The mailing address (line 2) where a user would like to have their year-end statement mailed. US-only. */
  address2: Maybe<Scalars['String']['output']>;
  /** The city where a user would like to have their year-end statement mailed. US-only. */
  city: Maybe<Scalars['String']['output']>;
  /** Country where a user would like to have their year-end statement mailed. US-only. */
  country: Maybe<Scalars['String']['output']>;
  /** Email address in Rock the financial statement should be emailed to (if statement_type is “email”) */
  email: Maybe<Scalars['String']['output']>;
  /** First name of the user in Rock */
  firstName: Maybe<Scalars['String']['output']>;
  /** Unique id for a user in Rock */
  id: Maybe<Scalars['Int']['output']>;
  /** Last name of the user in Rock */
  lastName: Maybe<Scalars['String']['output']>;
  /** Zip code where a user would like to have their year-end statement mailed. US-only. */
  postal: Maybe<Scalars['String']['output']>;
  /** State where a user would like to have their year-end statement mailed. US-only. Accepts long-form state name. Rock will likely overwrite with abbreviation. */
  stateProvince: Maybe<Scalars['String']['output']>;
  /** Preferred delivery method for year-end financial statements. 'email' or 'paper' (technically, any value not “email” results in “paper”) */
  statementType: Maybe<StatementType>;
  /** IANA timezone of the user as defined in YouVersion */
  timezone: Maybe<Scalars['String']['output']>;
  /** Unique id for a user in YouVersion */
  yvId: Maybe<Scalars['Int']['output']>;
};

export type UserCreateInput = {
  agree: Scalars['Boolean']['input'];
  /** If found, given_name and family_name will be extracted from the id_token. …and become first_name and last_name, respectively. */
  appleIdToken?: InputMaybe<Scalars['String']['input']>;
  /** Country of the user as 2-letter ISO country code */
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  /** This param was added for GDPR compliance. Send based off user opt-in on UI when user is detected to be inside the EU. Sending False will opt the user out of all email communications. */
  emailOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user’s Facebook access token. If found, given_name and family_name will be extracted from the id_token and become first_name and last_name, respectively. */
  facebookAccessToken?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  /** If found, given_name and family_name will be extracted from the id_token. …and become first_name and last_name, respectively. */
  googleIdToken?: InputMaybe<Scalars['String']['input']>;
  /** The user’s KakaoTalk access token. */
  kakaotalkAccessToken?: InputMaybe<Scalars['String']['input']>;
  /** User’s desired language_tag */
  languageTag?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  notificationSettings?: InputMaybe<NotificationSettingsInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  /** This should only be sent by platforms that are not using the HTTP referer when making API calls, for those sending HTTP referer we’ll populate this value automatically, but for some platforms like webOS and WP7, they can’t send HTTP referer so they should populate this value. */
  source?: InputMaybe<Scalars['String']['input']>;
  /** User’s current timezone */
  timezone?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  /** The user’s desired username. Alpha-numeric but cannot be only numeric. Can be up to 32 characters long. The username will be checked on the api to make sure it is available. */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Either ‘true’ or ‘false’. If you are 100% sure that the user owns the email address and we can bypass email confirmation, set this to ‘true’ and email verification will be skipped. */
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserIntent {
  TEXT = 'TEXT',
  TOPICAL = 'TOPICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum UserIntent2 {
  TEXT = 'TEXT',
  TOPICAL = 'TOPICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum UserIntent3 {
  TEXT = 'TEXT',
  TOPICAL = 'TOPICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum UserIntent4 {
  TEXT = 'TEXT',
  TOPICAL = 'TOPICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum UserIntent5 {
  REFERENCE = 'REFERENCE',
  TEXT = 'TEXT',
  TOPICAL = 'TOPICAL',
  UNKNOWN = 'UNKNOWN'
}

export enum UserStatus {
  DRAFT = 'DRAFT',
  FRIENDS = 'FRIENDS',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type UserUpdateInput = {
  /** The mailing address where a user would like to have their year-end statement mailed. US-only. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** The mailing address (line 2) where a user would like to have their year-end statement mailed. US-only. */
  address2?: InputMaybe<Scalars['String']['input']>;
  /** The city where a user would like to have their year-end statement mailed. US-only. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Country where a user would like to have their year-end statement mailed. US-only. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Email address the financial statement should be emailed to (if statement_type is “email”) */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Zip code where a user would like to have their year-end statement mailed. US-only. */
  postal?: InputMaybe<Scalars['String']['input']>;
  /** State where a user would like to have their year-end statement mailed. US-only. Accepts long-form state name. Rock will likely overwrite with abbreviation. */
  stateProvince?: InputMaybe<Scalars['String']['input']>;
  /** Preferred delivery method for year-end financial statements. 'email' or 'paper' (technically, any value not “email” results in “paper”) */
  statementType?: InputMaybe<StatementType>;
};

export type UserV4 = {
  __typename?: 'UserV4';
  adminOrganizations: Maybe<AdminOrganizationsOrganizations>;
  /** The id for the users avatar. */
  avatarImageId: Maybe<Scalars['String']['output']>;
  /** The url of a users avatar. */
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** The bio of a given user. */
  bio: Maybe<Scalars['String']['output']>;
  /** The birthday of a given user. */
  birthdate: Maybe<Scalars['String']['output']>;
  /** The current country a user is using in the app in. */
  country: Maybe<Scalars['String']['output']>;
  /** The user's email address */
  email: Maybe<Scalars['String']['output']>;
  /** The first name of a given user. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The given user's gender. */
  gender: Maybe<Scalars['Float']['output']>;
  /** Unique identifier (user_id) */
  id: Maybe<Scalars['Float']['output']>;
  /** The current language a user is using in the app. */
  languageTag: Maybe<Scalars['String']['output']>;
  /** The last name of a given user. */
  lastName: Maybe<Scalars['String']['output']>;
  /** The location of a given user. */
  location: Maybe<Scalars['String']['output']>;
  permissions: Maybe<PermissionsBatch>;
  roleBindings: Maybe<Array<Maybe<RoleBinding>>>;
  /** The current timezone of a user. */
  timezone: Maybe<Scalars['String']['output']>;
  /** The first name of a given user. */
  username: Maybe<Scalars['String']['output']>;
  /** Indicates whether or not the user has verified their email. */
  verified: Maybe<Scalars['Boolean']['output']>;
};


export type UserV4PermissionsArgs = {
  resources?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersAvatarAvatarData = {
  __typename?: 'UsersAvatarAvatarData';
  imageId: Maybe<Scalars['String']['output']>;
  params: Maybe<UsersAvatarAvatarDataParamsProps>;
  url: Maybe<Scalars['String']['output']>;
};

export type UsersAvatarAvatarDataParamsProps = {
  __typename?: 'UsersAvatarAvatarDataParamsProps';
  aWSAccessKeyId: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  policy: Maybe<Scalars['String']['output']>;
  signature: Maybe<Scalars['String']['output']>;
};

export type UsersAvatarAvatarImageIdPropInput = {
  imageId?: InputMaybe<Scalars['String']['input']>;
};

export type UsersAvatarAvatarResponse = {
  __typename?: 'UsersAvatarAvatarResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<UsersAvatarAvatarData>;
};

export type UsersAvatarAvatarUpdate = {
  __typename?: 'UsersAvatarAvatarUpdate';
  response: Maybe<UsersAvatarAvatarUpdateResponse>;
};

export type UsersAvatarAvatarUpdateResponse = {
  __typename?: 'UsersAvatarAvatarUpdateResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<UsersAvatarAvatarUpdateResponseData>;
};

export type UsersAvatarAvatarUpdateResponseData = {
  __typename?: 'UsersAvatarAvatarUpdateResponseData';
  apps: Maybe<UsersUserApps>;
  avatarImageId: Maybe<Scalars['String']['output']>;
  bio: Maybe<Scalars['String']['output']>;
  birthdate: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdDt: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  euid: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['Int']['output']>;
  hasAvatar: Maybe<Scalars['Boolean']['output']>;
  hasMaskedEmail: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  imType: Maybe<Scalars['String']['output']>;
  imUsername: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  lastLoginDt: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  optedOutDt: Maybe<Scalars['String']['output']>;
  phoneMobile: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  reportedDt: Maybe<Scalars['String']['output']>;
  suspendedDt: Maybe<Scalars['String']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  userAvatarUrl: Maybe<UsersUserAvatar>;
  username: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};

export type UsersAvatarAvatarUpload = {
  __typename?: 'UsersAvatarAvatarUpload';
  response: Maybe<UsersAvatarAvatarResponse>;
};

export type UsersDeleteUserDelete = {
  __typename?: 'UsersDeleteUserDelete';
  response: Maybe<UsersDeleteUserDeleteResponse>;
};

export type UsersDeleteUserDeleteInputInput = {
  /** MD5 Token */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UsersDeleteUserDeleteResponse = {
  __typename?: 'UsersDeleteUserDeleteResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type UsersEmailEmail = {
  __typename?: 'UsersEmailEmail';
  response: Maybe<UsersEmailUserEmailResponse>;
};

export type UsersEmailUserEmailResponse = {
  __typename?: 'UsersEmailUserEmailResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type UsersEmailUserUpdateEmailPropInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UsersIdUserId = {
  __typename?: 'UsersIdUserId';
  response: Maybe<UsersIdUserIdResponse>;
};

export type UsersIdUserIdResponse = {
  __typename?: 'UsersIdUserIdResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  /** this is the user id. */
  data: Maybe<Scalars['Int']['output']>;
};

export type UsersPasswordEmail = {
  __typename?: 'UsersPasswordEmail';
  /** User's email */
  email: Maybe<Scalars['String']['output']>;
};

export type UsersPasswordEmailInput = {
  /** User's email */
  email?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPasswordForgotPassword = {
  __typename?: 'UsersPasswordForgotPassword';
  response: Maybe<UsersPasswordForgotPasswordResponse>;
};

export type UsersPasswordForgotPasswordResponse = {
  __typename?: 'UsersPasswordForgotPasswordResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
  data: Maybe<UsersPasswordEmail>;
};

export type UsersPasswordPassword = {
  __typename?: 'UsersPasswordPassword';
  response: Maybe<UsersPasswordPasswordResponse>;
};

export type UsersPasswordPasswordInputInput = {
  /** The new password the user has decided on */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Token generated by API and embedded in URL */
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPasswordPasswordResponse = {
  __typename?: 'UsersPasswordPasswordResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type UsersUser = {
  __typename?: 'UsersUser';
  response: Maybe<UsersUserResponse>;
};

export type UsersUserApps = {
  __typename?: 'UsersUserApps';
  facebook: Maybe<Scalars['String']['output']>;
  google: Maybe<Scalars['String']['output']>;
  twitter: Maybe<Scalars['String']['output']>;
  weibo: Maybe<Scalars['String']['output']>;
};

export type UsersUserAvatar = {
  __typename?: 'UsersUserAvatar';
  px24x24: Maybe<Scalars['String']['output']>;
  px48x48: Maybe<Scalars['String']['output']>;
  px128x128: Maybe<Scalars['String']['output']>;
  px512x512: Maybe<Scalars['String']['output']>;
};

export type UsersUserData = {
  __typename?: 'UsersUserData';
  apps: Maybe<UsersUserApps>;
  bio: Maybe<Scalars['String']['output']>;
  birthdate: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdDt: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['Float']['output']>;
  id: Maybe<Scalars['Float']['output']>;
  imType: Maybe<Scalars['Float']['output']>;
  imUsername: Maybe<Scalars['String']['output']>;
  languageTag: Maybe<Scalars['String']['output']>;
  lastLoginDt: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  optedOutDt: Maybe<Scalars['String']['output']>;
  phoneMobile: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['Float']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  userAvatarUrl: Maybe<UsersUserAvatar>;
  username: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};

export type UsersUserResponse = {
  __typename?: 'UsersUserResponse';
  buildtime: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['Int']['output']>;
};

export type UsersUserUpdateRequestInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['Float']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type Verses = {
  __typename?: 'Verses';
  /** A list of Verse resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Items5>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  filters: Maybe<Filters>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type VersesListItem = {
  __typename?: 'VersesListItem';
  content: Maybe<Scalars['String']['output']>;
  reference: Maybe<Reference2>;
};

export enum VersionType {
  AUDIO = 'AUDIO',
  TEXT = 'TEXT'
}

export type Versions3ListItem = {
  __typename?: 'Versions3ListItem';
  abbreviation: Maybe<Scalars['String']['output']>;
  audio: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['Int']['output']>;
  language: Maybe<Language>;
  localAbreviation: Maybe<Scalars['String']['output']>;
  localTitle: Maybe<Scalars['String']['output']>;
  metadataBuild: Maybe<Scalars['Int']['output']>;
  offline: Maybe<Offline>;
  platforms: Maybe<Platforms2>;
  publisherId: Maybe<Scalars['Int']['output']>;
  text: Maybe<Scalars['Boolean']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type Video = {
  __typename?: 'Video';
  configuration: Maybe<VideosConfiguration>;
  createdDt: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** video id */
  id: Maybe<Scalars['Int']['output']>;
  language: Maybe<Scalars['String']['output']>;
  orientation: Maybe<Orientation>;
  publisherId: Maybe<Scalars['Int']['output']>;
  references: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  runtime: Maybe<Scalars['Int']['output']>;
  status: Maybe<Status>;
  title: Maybe<Scalars['String']['output']>;
  trackingId: Maybe<Scalars['String']['output']>;
  type: Maybe<Type>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  /** description text */
  description: Maybe<Scalars['String']['output']>;
  /** end */
  end: Maybe<Scalars['Int']['output']>;
  /** collection id */
  id: Maybe<Scalars['Int']['output']>;
  language: Maybe<Scalars['String']['output']>;
  /** start */
  start: Maybe<Scalars['Int']['output']>;
  /** title text */
  title: Maybe<Scalars['String']['output']>;
  videos: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type VideoPost = {
  __typename?: 'VideoPost';
  /** created datetime */
  createdDt: Scalars['String']['output'];
  /** A description of the video */
  description: Maybe<Scalars['String']['output']>;
  /** video id */
  id: Maybe<Scalars['Int']['output']>;
  /** The language of the video */
  language: Scalars['String']['output'];
  orientation: Orientation;
  /** id of the video publisher */
  publisherId: Maybe<Scalars['Int']['output']>;
  references: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  runtime: Maybe<Scalars['Int']['output']>;
  /** video source url */
  sourceUrl: Scalars['String']['output'];
  status: Maybe<Status>;
  /** A url pointing to a publicly hosted image to be used as the thumbnail for the video. If not passed, one will be automatically generated for you. */
  thumbnailSourceUrl: Maybe<Scalars['String']['output']>;
  /** The title of the video */
  title: Scalars['String']['output'];
  /** video tracking id */
  trackingId: Maybe<Scalars['String']['output']>;
  type: Maybe<Type>;
  /** A parameter used to ensure idempotent requests when creating a brand new video (not passing id) */
  uuid: Maybe<Scalars['String']['output']>;
};

export type VideoPostInput = {
  /** created datetime */
  createdDt: Scalars['String']['input'];
  /** A description of the video */
  description?: InputMaybe<Scalars['String']['input']>;
  /** video id */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The language of the video */
  language: Scalars['String']['input'];
  orientation: Orientation;
  /** id of the video publisher */
  publisherId?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  runtime?: InputMaybe<Scalars['Int']['input']>;
  /** video source url */
  sourceUrl: Scalars['String']['input'];
  status?: InputMaybe<Status>;
  /** A url pointing to a publicly hosted image to be used as the thumbnail for the video. If not passed, one will be automatically generated for you. */
  thumbnailSourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** The title of the video */
  title: Scalars['String']['input'];
  /** video tracking id */
  trackingId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Type>;
  /** A parameter used to ensure idempotent requests when creating a brand new video (not passing id) */
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type VideoPublishAndDeleteRequestInput = {
  language: Scalars['String']['input'];
};

export type VideoPublishResponse = {
  __typename?: 'VideoPublishResponse';
  /** description text */
  description: Maybe<Scalars['String']['output']>;
  /** video id */
  id: Maybe<Scalars['Int']['output']>;
  orientation: Maybe<Orientation>;
  publisherId: Maybe<Scalars['Int']['output']>;
  references: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  runtime: Maybe<Scalars['Int']['output']>;
  status: Maybe<Status>;
  /** title text */
  title: Maybe<Scalars['String']['output']>;
  trackingId: Maybe<Scalars['String']['output']>;
  type: Maybe<Type>;
};

export type VideoUrls = {
  __typename?: 'VideoUrls';
  hls: Maybe<Scalars['String']['output']>;
  webm: Maybe<Scalars['String']['output']>;
};

export type Videos = {
  __typename?: 'Videos';
  /** A list of Video resource objects returned from the unified results endpoint */
  data: Maybe<Array<Maybe<Video>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
};

export type VideosConfiguration = {
  __typename?: 'VideosConfiguration';
  audioUrls: Maybe<Array<Maybe<AudioUrlsListItem>>>;
  imageUrls: Maybe<ImageUrls>;
  shareUrl: Maybe<Scalars['String']['output']>;
  videoUrls: Maybe<VideoUrls>;
};

export enum VisibilityPreference {
  EVERYONE = 'EVERYONE',
  FRIENDS = 'FRIENDS',
  NONE = 'NONE'
}

export type _40Images = {
  __typename?: '_40Images';
  data: Maybe<Array<Maybe<Image>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
};

export type _40Ping = {
  __typename?: '_40Ping';
  /** List of unavailable services. */
  down: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List of available services. */
  up: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type _40StringInput = {
  remoteStringId?: InputMaybe<Scalars['Int']['input']>;
};

export type _40Topics = {
  __typename?: '_40Topics';
  /** A list of Topic resource objects */
  data: Maybe<Array<Maybe<Topic>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
};

export type _40Verses = {
  __typename?: '_40Verses';
  /** A list of verse resources */
  data: Maybe<Array<Maybe<Data22ListItem>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  filters: Maybe<Filters>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent2>;
};

export type _40VideoInput = {
  createdDt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  orientation?: InputMaybe<Orientation>;
  publisherId?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  runtime?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Status>;
  title?: InputMaybe<Scalars['String']['input']>;
  trackingId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Type>;
};

export type _40Videos = {
  __typename?: '_40Videos';
  /** A list of Video resource objects */
  data: Maybe<Array<Maybe<Video>>>;
  didYouMean: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nextPage: Maybe<Scalars['Boolean']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  /** This is the actual query the server used to get the results it returned. Normally, it would be the same as the query the user input, but if it is a spelling mistake, the API might auto-correct it and run a different query than what the user input. */
  query: Maybe<Scalars['String']['output']>;
  /** If a correction was applied, search for the original query instead. */
  searchInsteadFor: Maybe<Scalars['String']['output']>;
  /** This is the user_intent the server used to get the results it returned. Normally, it would be the same as the client supplied to get results. *But* if the client did not supply it the API tries to intelligently chose an intent. */
  userIntent: Maybe<UserIntent4>;
};

export type _41Ping = {
  __typename?: '_41Ping';
  /** List of unavailable services. */
  down: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List of available services. */
  up: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', address: string, address2: string, city: string, country: string, email: string, firstName: string, id: number, lastName: string, postal: string, stateProvince: string, statementType: Types.StatementType, timezone: string, yvId: number } };

export type UpdateUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  userUpdateInput?: Types.InputMaybe<Types.UserUpdateInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', user: { __typename?: 'User', address: string, address2: string, city: string, country: string, email: string, firstName: string, id: number, lastName: string, postal: string, stateProvince: string, statementType: Types.StatementType, timezone: string, yvId: number } };


export const GetUserDocument = `
    query getUser {
  user: getUser {
    address
    address2
    city
    country
    email
    firstName
    id
    lastName
    postal
    stateProvince
    statementType
    timezone
    yvId
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      variables?: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      variables === undefined ? ['getUser'] : ['getUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
      options
    );

useGetUserQuery.getKey = (variables?: GetUserQueryVariables) => variables === undefined ? ['getUser'] : ['getUser', variables];
;

useGetUserQuery.fetcher = (variables?: GetUserQueryVariables, options?: RequestInit['headers']) => fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables, options);
export const UpdateUserDocument = `
    mutation updateUser($userId: Int!, $userUpdateInput: UserUpdateInput) {
  user: updateUser(userId: $userId, userUpdateInput: $userUpdateInput) {
    address
    address2
    city
    country
    email
    firstName
    id
    lastName
    postal
    stateProvince
    statementType
    timezone
    yvId
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
      options
    );
useUpdateUserMutation.fetcher = (variables: UpdateUserMutationVariables, options?: RequestInit['headers']) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options);