import { GIVING_V2_ENDPOINT } from 'utils/constants';

interface Params {
  language: string;
  payment_token: string;
  token: string;
}

export async function createV2Ach({ payment_token, token, language = 'en' }: Params) {
  return await fetch(`${GIVING_V2_ENDPOINT}/payment_methods`, {
    body: JSON.stringify({
      payment_token,
    }),
    headers: {
      Accept: 'application/json',
      'Accept-Language': language,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}
