import { setDefaultOptions } from 'date-fns';

export async function setDateFnsLocale(currentLanguage: string) {
  const language = getDateFnsLocale(currentLanguage);
  try {
    const locale = await import(`date-fns/locale/${language}/index.js`);
    setDefaultOptions({ locale });
  } catch {
    console.error('Unable to load locale');
  }
}

export function getDateFnsLocale(language: string) {
  if (dateFnsLocales.includes(language)) {
    return language;
  }
  const shortLanguage = language.split('-')[0];
  if (dateFnsLocales.includes(shortLanguage)) {
    return shortLanguage;
  }
  return 'en-US';
}

const dateFnsLocales = [
  'ar-EG',
  'az',
  'bn',
  'cy',
  'el',
  'en-IE',
  'en-ZA',
  'eu',
  'fr-CA',
  'gl',
  'hr',
  'id',
  'it',
  'ka',
  'ko',
  'mk',
  'my',
  'nn',
  'pt',
  'sk',
  'sr-Latn',
  'te',
  'uz-Cyrl',
  'zh-TW',
  'af',
  'ar-MA',
  'be',
  'bs',
  'da',
  'en-AU',
  'en-IN',
  'eo',
  'fa-IR',
  'fr-CH',
  'gu',
  'ht',
  'it-CH',
  'kk',
  'lb',
  'mn',
  'nb',
  'oc',
  'pt-BR',
  'sl',
  'sv',
  'th',
  'ug',
  'vi',
  'ar',
  'ar-SA',
  'be-tarask',
  'ca',
  'de',
  'en-CA',
  'en-NZ',
  'es',
  'fi',
  'fy',
  'he',
  'hu',
  'ja',
  'km',
  'lt',
  'ms',
  'nl',
  'ro',
  'sq',
  'sw',
  'tl-PH',
  'uk',
  'zh-CN',
  'ar-DZ',
  'ar-TN',
  'bg',
  'cs',
  'de-AT',
  'en-GB',
  'en-US',
  'et',
  'fr',
  'gd',
  'hi',
  'hy',
  'is',
  'ja-Hira',
  'kn',
  'lv',
  'mt',
  'nl-BE',
  'pl',
  'ru',
  'sr',
  'ta',
  'tr',
  'uz',
  'zh-HK',
];
