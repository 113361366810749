import { TransactionSource } from 'utils/api/makePayment';

const android = 'com.sirma.mobile.bible.android';

export function sourceFromUserAgent() {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes(android)) {
      return TransactionSource.WEB_ANDROID;
    } else {
      return TransactionSource.WEB_WEB;
    }
  }
}
