import publicIp from 'public-ip';
import { captchaConfig } from 'utils/constants';

export async function verifyCaptcha(token: string, callback: (token: string | null) => void) {
  try {
    const remoteIp = await publicIp.v4();
    const reCaptchaResponse = await fetch(captchaConfig.siteVerifyUrl, {
      body: getCaptchaFormBody({
        remoteip: remoteIp,
        response: token,
        sitekey: captchaConfig.siteKey,
        type: captchaConfig.types.reCaptcha,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
    const reCaptchaData = await reCaptchaResponse.json();
    if (reCaptchaData && reCaptchaData.success) {
      callback(token);
    }
  } catch (error) {
    callback(null);
  }
}

function getCaptchaFormBody(formDetails: { [key: string]: string }) {
  const formBody: Array<string> = [];
  Object.keys(formDetails).forEach(key => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(formDetails[key]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });
  return formBody.join('&');
}
